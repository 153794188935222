import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import SpinnerShape from "../../assets/icons/SpinnerShape.svg";
import Back from "../../assets/icons/Back.svg";
import Err from "../../assets/icons/Error.svg";
import './index.css'

export class UIOKCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelBusy: false,
      okBusy: false,
    }
  }

  ok = () => {
    if (this.clicked) return Promise.resolve()
    this.clicked = true
    const block = !this.props.background;
    this.setState({
      okBusy: true
    });
    if (block) {
      window.blockInput()
    }
    const done = () => {
      this.clicked = false
      this.setState({
        okBusy: false
      });
      if (block) {
        window.unblockInput();
        clearInterval(this.interval)
      }
    }
    let p
    p = this.props.ok()
    // for testing
    //p = new Promise(resolve => setTimeout(resolve, 5000))
    if (!p || !p.then) {
      console.log("didn't return a promise: ", this.props.ok);
      p = Promise.resolve()
    }
    p.then(done).catch(err => {
      this.clicked = false
      console.error(err);
      this.setState({
        error: true
      });
      done();
    });
  }

  cancel = () => {
    if (this.cancelClicked) return Promise.resolve()
    this.cancelClicked = true
    window.blockInput()
    this.setState({
      cancelBusy: true
    });
    const done = () => {
      this.cancelClicked = false
      window.unblockInput();
      this.setState({
        cancelBusy: false
      });
    }
    this.props.cancel().then(done).catch(err => {
      this.clicked = false
      console.error(err);
      this.setState({
        error: true
      });
      done();
    });
  }

  resetError = () => {
    this.setState({error: false});
  }

  componentWillUnmount() {
    if (this.state.busy) {
      window.unblockInput();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const block = !this.props.background;
    if (!block) return
    if (this.props.lookBusy && !prevProps.lookBusy) {
      window.blockInput()
    } else if (!this.props.lookBusy && prevProps.lookBusy) {
      window.unblockInput()
    }
  }


  setRef = ref => {
    if (ref) this.ref = ref
  }
  
  render() {
    if (this.state.error || this.props.error) {
      return <div className='uiOKCancel uiOKCancelWithOof'>
        <div className="uiOKCancelOof" onClick={this.props.onErrorClick || this.resetError}>
        <div className="uiOKCancelOofIcon">
        <ReactSVG src={Err}/>
        </div>
        <div className="uiOKCancelOofLabel">
        {"Oof, Sorry that didn't work"}
      </div>
        </div>
        </div>
    }
    const keepFocus = action => e => {
      e.preventDefault()
      action()
    }
    const busy = this.state.okBusy || this.props.lookBusy;
    const cancelBusy = this.state.cancelBusy;
    const onMouseDown = this.props.keepFocus ? keepFocus(this.ok) : null
    const onClick = this.props.keepFocus ? null : this.ok 
    const onCancelMouseDown = this.props.keepFocus ? keepFocus(this.cancel) : null
    const onCancelClick = this.props.keepFocus ? null : this.cancel
    
    return <div className='uiOKCancel' ref={this.setRef}>
      {this.props.back && <div key='back' className='uiOKCancelBack' onClick={this.props.back}>
       <ReactSVG src={Back}/>
       </div>}
     {this.props.cancel &&<div key='cancel' className={'uiOKCancelCancel'} onClick={onCancelClick} onMouseDown={onCancelMouseDown}>
     <div className={'uiOKCancelCancelIcon'  + (cancelBusy ? " uiOKCancelBusy" : "")}>
     <ReactSVG src={cancelBusy ? SpinnerShape : this.props.cancelIcon}/>
     </div>
     </div>}
     {this.props.ok && <div key='ok' className='uiOKCancelOK' onClick={onClick} onMouseDown={onMouseDown}>
     <div className={'uiOKCancelOKIcon' + (busy ? " uiOKCancelBusy" : "")}>
     {this.props.okNode  && !busy ? this.props.okNode : <ReactSVG src={busy ? SpinnerShape  : this.props.okIcon}/>}
     </div>
     <div className='uiOKCancelOKLabel'>
     {this.props.label}
     </div>
     </div>}
    </div>
  }
}
