import React, { Component } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import { KeyboardButton1 } from './index.js'
import Copy from '../../assets/Icons/Copy.svg'
import './index.css'


export class Code extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    const options = {
      lineNumbers: true
    }
    return <div className='keyboardCode'>
             <code>{this.props.value}</code>
             <div className='keyboardCodeCopy'>
               <KeyboardButton1 keepFocus icon={Copy} action={this.props.copy}/>
               </div>
           </div>
             
  }
}
