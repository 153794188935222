import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import './index.css'


export class BnAnimatedLabel extends Component {

  setScrollRef = ref => {
    this.scrollRef = ref
  }

  y = 0

  setContent = ref => {
    this.contentRef = ref
    if (ref) {
      this.contentHeight = ref.scrollHeight
    }
  }

  componentDidMount() {
    setInterval(this.animate, 3000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.interval2)
  }

  animate = () => {
    const pageHeight = 20
    clearInterval(this.interval2)
    if (this.scrollRef && this.contentRef) {
      let end = this.y + pageHeight
      //console.log('animate', this.y, this.contentHeight)
      this.interval2 = setInterval(() => {
        this.y += pageHeight / 30
        if (this.y > this.contentHeight) {
          this.y = 0
          this.scrollRef.scrollTop = this.y
          clearInterval(this.interval2)
        } else {
          this.scrollRef.scrollTop = this.y
          if (this.y >= end) {
            clearInterval(this.interval2)
          }
        }
      }, 1000/ 60)
    } else {
      clearInterval(this.interval2)
    }
  }
  
  render() {
    return <div ref={this.setScrollRef} className='bnAnimatedLabel'>
      <div className='bnLabel1' ref={this.setContent}>{this.props.text}</div>
      </div>
  }
}

export const BnLabel = props => {
  return <div className='bnLabel'>{props.text}</div>
}

export const BnOrangeLabel = props => {
  const { text, value } = props
  return <div className='bnOrangeLabel'>
    <div className='bnOrangeLabelText'>{text}</div>
    <div className='bnOrangeLabelValue'>{value}</div>
    </div>
}

export const BnFieldLabel = props => {
  const { text, value } = props
  return <div className='bnFieldLabel'>
    <div className='bnFieldLabelText'>{text}</div>
    <div className='bnOrangeLabelValue'>{value}</div>
    </div>
}

export const BnLabel3 = props => {
  const { icon, label, iconNode } = props
  return <div className='bnLabel3'>
    <div className='bnLabel3Icon'>{iconNode || <ReactSVG src={icon}/>}</div>
    <div className='bnLabel3Label'>{label}</div>
    </div>
}

export const BnFieldLabel3 = props => {
  const { label, value } = props
  return <div className='bnLabel3 bnFieldLabel3'>
    <div className='bnLabel3Label bnFieldLabel3Label'>{label}</div>
    <div className='bnLabel3Label'>{value}</div>
    </div>
}

export class BnLabel1 extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    return <div className='bnLabel1'>{this.props.text}</div>
  }
}

export class BnLabel2 extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    return <div className='bnLabel2'>{this.props.text}</div>
  }
}


export class BnErrorLabel extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    return <div className='bnErrorLabel'>{this.props.text}</div>
  }
}
