import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import BxLogo from '../../assets/Marks/bxLogo.svg'
import './index.css'

export class BnSplash extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    let icon = <ReactSVG src={BxLogo}/>
    return <div className='bnSplash'>
      <div className='bnSplashTitle'>
      <div className='bnSplashTitleBE'>BE</div><div className='bnSplashTitleNXT'>NXT</div>
      </div>
      <div className='bnSplashLogo'>
      </div>
      </div>
  }
}
