import React, { Component } from 'react'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import { ReactSVG } from 'react-svg'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { BnLabel1, BnLabel2 } from '../Label'
import { BnPage, BnSubpage } from '../Page'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { parsePhoneNumber, BnInputField, BnInputFieldSeparator } from '../TextInput'
import { KeyboardButton, KeyboardButton1 } from '../Keyboard'
import { delay, capitalize } from '../../classes/Util.js'
import { Keyboard } from '../Keyboard'
import { RadioButtons, FineTuningTask, ChatGPT } from '../ChatGPT'
import { GetStarted, KeyboardLogin } from '../KeyboardLogin'
import Trash from '../../assets/Icons/Trash.svg'
import Twitter from '../../assets/Icons/Twitter.svg'
import Facebook from '../../assets/Icons/FBook.svg'
import LinkedIn from '../../assets/Icons/LnkIn.svg'
import TikTok from '../../assets/Icons/TikTok.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Save from '../../assets/Icons/SaveCommand.svg'
import Share from '../../assets/Icons/Share.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import Pen from '../../assets/Icons/Edited.svg'
import Create from '../../assets/Icons/Create.svg'
import Edit from '../../assets/Icons/SavedCommands.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import Pack from '../../assets/Icons/Pack.svg'
import Buy from '../../assets/Icons/Buy.svg'
import Profile from '../../assets/Icons/Profile.svg'
import Spin from '../../assets/Icons/Spin.svg'
import AISaid from '../../assets/Icons/AISaid.svg'

import OpenAI from '../../assets/Icons/OpenAI.svg'
import Anthropic from '../../assets/Icons/Anthropic.svg'
import Mistral from '../../assets/Icons/MistralLogo.svg'
import Google from '../../assets/Icons/Google.svg'
import Gemini from '../../assets/Icons/Models/Gemini.svg'
import Databricks from '../../assets/Icons/Databricks.svg'
import Microsoft from '../../assets/Icons/Microsoft.svg'
import Cohere from '../../assets/Icons/Cohere.svg'
import DeepSeek from '../../assets/Icons/Deepseek.svg'
import Yi from '../../assets/Icons/Yi.svg'
import Alibaba from '../../assets/Icons/Alibaba.svg'
import Nvidia from '../../assets/Icons/Nvidia.svg'
import Reka from '../../assets/Icons/Reka.svg'
import Meta from '../../assets/Icons/Meta.svg'
import MetaAI from '../../assets/Icons/Models/MetaAI.svg'
import Claude from '../../assets/Icons/Models/Claude.svg'
import { WordPackPurchase } from '../Words'
import HomeNewUser from '../../assets/Icons/Guide0012x.png'
import Cross from '../../assets/Icons/Cross_1.svg'
import Update from '../../assets/Icons/Update.svg'
import HeroImage from '../../assets/Icons/HeroImage.png'
import ClickAwayListener from 'react-click-away-listener'
import { ModelVendor, Model, formatPrice } from '../ChatGPT'
import { startOfDay, startOfWeek, startOfMonth,  endOfDay, Calendar } from './Usage.js'
import { ModelsView } from '../ChatGPT/ModelsMenu.js'
import phone from 'phone';
import './index.css'

const formatJob = (job, file) => {
  const { id, model, created_at, finished_at, fine_tuned_model, status, hyperparameters, trained_tokens } = job
  const t = `- **Model**: ${model}
- **Dataset**: ${file}`
  return t
}

const TITLE = 'IntelliKey'

export const getWord = me => {
  let word = 'word'
  let Word = 'Word'
  word = 'credit'
  Word = 'Credit'
  return { word, Word }
}

let isDemo = true
const demoFilter = model => {
    return true
}

const Vendors = [
  {
    name: "Attunewise",
    getIcon: () => AISaid,
    id: 'attunewise'
    
  },
  {
    id: 'openai',
    name: "OpenAI",
    getIcon: () => OpenAI,
  },
  {
    id: 'anthropic',
    name: "Anthropic",
    getIcon: () => Anthropic
  },
  {
    name: "Google",
    getIcon: () => Google,
    id: 'google'
    
  },
  {
    id: 'meta',
    name: "Meta",
    getIcon: () => Meta
  },
  {
    id: 'mistral',
    name: "Mistral",
    getIcon: () => Mistral
  },
  {
    id: 'nvidia',
    name: "Nvidia",
    getIcon: () => Nvidia
  },
  {
    id: 'alibaba',
    name: "Alibaba",
    getIcon: () => Alibaba
  },
  {
    id: 'reka',
    name: "Reka",
    getIcon: () => Reka
  },
  {
    id: 'yi',
    name: "Yi",
    getIcon: () => Yi
  },
  {
    id: 'deepseek',
    name: "DeepSeek",
    getIcon: () => DeepSeek
  },
  {
    id: 'microsoft',
    name: "Microsoft",
    getIcon: () => Microsoft
  },
  {
    id: 'cohere',
    name: "Cohere",
    getIcon: () => Cohere
  },
  {
    id: 'databricks',
    name: "Databricks",
    getIcon: () => Databricks
  },
]

export const resolveModelId = id => {
  if (id.startsWith('ft')) {
    const comps = id.split(':')
    const comp = comps[1]
    if (comp.indexOf('mini') >= 0) {
      id = 'attunewise'
    } else if (comp.indexOf('turbo') >= 0) {
      id = 'attunewise-gpt-3.5-turbo'
    } else {
      id = 'attunewise-large'
    }
  }
  return id
}

const isModel =  (x, y) => {
  let id = resolveModelId(y)
  return x.id === id
}

const allModels = (isModelSelected, selectModel) => [
  {
    id: 'attunewise-gpt-3.5-turbo',
    label: 'Small',
    isSelected: () => isModelSelected('attunewise-gpt-3.5-turbo'),
    select: () => { selectModel('attunewise-gpt-3.5-turbo') },
    title: "Small",
    //getModelIcon: () => OpenAI,
    getIcon: () => AISaid,
    isOpenSource: () => false,
    vendor: "Attunewise",
    requiresAdmin: false,
    getSize: ()  => 'small',
    contexts: [
      {
        input: 8,
        output: 4,
        price: {input: 1.50, output: 3.00}
      }]
  },
  {
    id: 'attunewise',
    label: 'Mini',
    isSelected: () => isModelSelected('attunewise'),
    select: () => { selectModel('attunewise') },
    title: "Mini",
    //getModelIcon: () => OpenAI,
    getIcon: () => AISaid,
    isOpenSource: () => false,
    vendor: "Attunewise",
    getSize: ()  => 'small',
    contexts: [
      {
        input: 128,
        output: 16,
        price: {input: .3, output: 1.2}
      }
      ]
  },
  {
    id: 'attunewise-large',
    label: 'Large',
    isSelected: () => isModelSelected('attunewise-large'),
    select: () => { selectModel('attunewise-large') },
    title: "Large",
    //getModelIcon: () => OpenAI,
    getIcon: () => AISaid,
    isOpenSource: () => false,
    vendor: "Attunewise",
    getSize: ()  => 'large',
    contexts: [
      {
        input: 128,
        output: 16,
        price: {input: 3.75, output: 15}
      }
      ]
  },
  {
    id: 'attunewise-gemini-1.5',
    label: 'Gemini 1.5',
    isSelected: () => isModelSelected('attunewise-gemini-1.5'),
    select: () => { selectModel('attunewise-gemini-1.5') },
    title: "Flash",
    requiresAdmin: false,
    //getModelIcon: () => Gemini,
    isOpenSource: () => false,
    getIcon: () => AISaid,
    vendor: "Attunewise",
    price: { input: 0.75, output: 0.15 },
    getSize: ()  => 'small',
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: .075, output: .15 }
      },
      {
        input: 1000,
        output: 8,
        price: { input: 0.15, output: 0.60 }
      }]
  },
  {
    id: 'attunewise-mistral-large-2',
    label: 'Large',
    isSelected: () => isModelSelected('attunewise-mistral-large-2'),
    select: () => { selectModel('attunewise-mistral-large-2') },
    title: "Mistral Large",
    getModelIcon: () => Mistral,
    isOpenSource: () => false,
    getIcon: () => AISaid,
    vendor: "Attunewise",
  },
  {
    id: 'claude-3-opus',
    label: 'Claude 3 Opus',
    isSelected: () => isModelSelected('claude-3-opus'),
    select: () => { selectModel('claude-3-opus') },
    title: "Claude 3 Opus",
    getModelIcon: () => Claude,
    isOpenSource: () => false,
    getIcon: () => Anthropic,
    getSize: () => 'large',
    vendor: "Anthropic",
    vision: true,
    contexts: [
      {
        input: 200,
        output: 4,
        price: { input: 15, output: 75 },
      }
      ]
  },
  {
    id: 'claude-3.5-sonnet',
    label: 'Claude 3.5 Sonnet',
    isSelected: () => isModelSelected('claude-3.5-sonnet'),
    select: () => { selectModel('claude-3.5-sonnet') },
    title: "Claude 3.5 Sonnet",
    getModelIcon: () => Claude,
    getIcon: () => Anthropic,
    vendor: "Anthropic",
    isOpenSource: () => false,
    getSize: () => 'large',
    vision: true,
    contexts: [
      {
        input: 200,
        output: 8,
        price: { input: 3, output: 15 },
      }
      ]
  },
  {
    id: 'claude-3-haiku',
    label: 'Claude 3 Haiku',
    isSelected: () => isModelSelected('claude-3-haiku'),
    select: () => { selectModel('claude-3-haiku') },
    title: "Claude 3 Haiku",
    getModelIcon: () => Claude,
    getIcon: () => Anthropic,
    vendor: "Anthropic",
    getSize: ()  => 'small',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 200,
        output: 4,
        price: { input: .25, output: 1.25 },
      }
      ]
  },
  {
    id: 'gpt-4',
    label: 'GPT-4',
    isSelected: () => isModelSelected('gpt-4'),
    select: () => { selectModel('gpt-4') },
    title: "GPT-4",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: () => 'large',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 10, output: 30 },
      }
      ]
  },
  {
    id: 'gpt-4-turbo',
    label: 'GPT-4 Turbo',
    isSelected: () => isModelSelected('gpt-4-turbo'),
    select: () => { selectModel('gpt-4-turbo') },
    title: "GPT-4 Turbo",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: () => 'large',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 10, output: 30 },
      }
      ]
  },
  {
    id: 'gpt-4o',
    label: 'GPT-4o',
    isSelected: () => isModelSelected('gpt-4o'),
    select: () => { selectModel('gpt-4o') },
    title: "GPT-4o",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: () => 'large',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 5, output: 10 },
      }
      ]
  },
  {
    id: 'chatgpt-4o',
    label: 'GPT-4o',
    isSelected: () => isModelSelected('chatgpt-4o'),
    select: () => { selectModel('chatgpt-4o') },
    title: "ChatGPT-4o",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: () => 'large',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 5, output: 10 },
      }
      ]
  },
  {
    id: 'gpt-4o-mini',
    label: 'GPT-4o Mini',
    isSelected: () => isModelSelected('gpt-4o-mini'),
    select: () => { selectModel('gpt-4o-mini') },
    title: "GPT-4o Mini",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: ()  => 'small',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 16,
        price: { input: .15, output: .6 },
      }]
  },
  {
    id: 'gpt-3.5-turbo',
    label: 'GPT-3.5 Turbo',
    isSelected: () => isModelSelected('gpt-3.5-turbo'),
    select: () => { selectModel('gpt-3.5-turbo') },
    title: "GPT-3.5 Turbo",
    getModelIcon: () => null,
    isOpenSource: () => false,
    getIcon: () => OpenAI,
    getSize: ()  => 'small',
    vendor: "OpenAI",
    contexts: [
      {
        input: 16,
        output: 4,
        price: { input: .5, output: 1.5},
      }]
  },
  {
    id: 'davinci-002',
    label: 'Davinci 002',
    isSelected: () => isModelSelected('davinci-002'),
    select: () => { selectModel('davinci-002') },
    title: "Davinci 002",
    getModelIcon: () => null,
    isOpenSource: () => false,
    getIcon: () => OpenAI,
    getSize: ()  => 'small',
    isBase: true,
    vendor: "OpenAI",
    contexts: [
      {
        input: 16,
        output: 4,
        price: { input: 2, output: 2},
      }]
  },
  {
    id: 'llama-3.1-405b',
    label: 'LLama-3 405B',
    isSelected: () => isModelSelected('llama-3.1-405b'),
    select: () => { selectModel('llama-3.1-405b') },
    title: "LLama 3.1 405B",
    getIcon: () => Meta,
    getModelIcon: () => MetaAI,
    getSize: () => 'large',
    isOpenSource: () => true,
    vendor: "Meta",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 3, output: 3 },
      }]
  },
  {
    id: 'llama-3.1-405b-base',
    label: 'LLama-3 405B Base',
    isSelected: () => isModelSelected('llama-3.1-405b-base'),
    select: () => { selectModel('llama-3.1-405b-base') },
    title: "LLama 3.1 405B Base",
    getIcon: () => Meta,
    isOpenSource: () => true,
    getModelIcon: () => MetaAI,
    getSize: () => 'large',
    isBase: true,
    vendor: "Meta",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 3, output: 3 },
      }
      ]
  },
  {
    id: 'llama-3.1-70b',
    label: 'LLama-3.1 70B',
    isSelected: () => isModelSelected('llama-3.1-70b'),
    select: () => { selectModel('llama-3.1-70b') },
    title: "LLama 3.1 70B",
    getIcon: () => Meta,
    getModelIcon: () => MetaAI,
    isOpenSource: () => true,
    getSize: ()  => 'medium',
    vendor: "Meta",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: .99, output: .99 },
      }
      ]
  },
  {
    id: 'llama-3.1-8b',
    label: 'LLama-3.1 8B',
    isSelected: () => isModelSelected('llama-3.1-8b'),
    select: () => { selectModel('llama-3.1-8b') },
    title: "LLama 3.1 8B",
    getIcon: () => Meta,
    isOpenSource: () => true,
    getModelIcon: () => MetaAI,
    getSize: ()  => 'small',
    vendor: "Meta",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: .22, output: .22 },
      }
      ]
  },
  {
    id: 'gemini-1.5-pro',
    label: 'Gemini 1.5 Pro',
    isSelected: () => isModelSelected('gemini-1.5-pro'),
    select: () => { selectModel('gemini-1.5-pro') },
    title: "Gemini 1.5 Pro",
    getModelIcon: () => Gemini,
    getIcon: () => Google,
    vendor: "Google",
    isOpenSource: () => false,
    getSize: () => 'large',
    price: { input: 7, output: 21 },
    vision: true,
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: 1.25, output: 3.75 }
      },
      {
        input: 2000,
        output: 8,
        price: { input: 2.5, output: 7.5 }
      }]
  },
  {
    id: 'gemini-1.5-flash',
    label: 'Gemini 1.5 Flash',
    isSelected: () => isModelSelected('gemini-1.5-flash'),
    select: () => { selectModel('gemini-1.5-flash') },
    title: "Gemini 1.5 Flash",
    getModelIcon: () => Gemini,
    getIcon: () => Google,
    vendor: "Google",
    isOpenSource: () => false,
    price: { input: 0.075, output: 0.15 },
    getSize: ()  => 'small',
    vision: true,
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: .01875, output: .0375 }
      },
      {
        input: 1000,
        output: 8,
        price: { input: 0.15, output: 0.60 }
      }]
  },
  {
    id: 'gemma-2-27b',
    label: 'Gemma 27B',
    isSelected: () => isModelSelected('gemma-2-27b'),
    select: () => { selectModel('gemma-2-27b') },
    title: "Gemma 2 27B",
    getModelIcon: () => Gemini,
    getSize: () => 'medium',
    getIcon: () => Google,
    isOpenSource: () => true,
    vendor: "Google"
  },
  {
    id: 'gemma-2-9b',
    label: 'Gemma 9B',
    isSelected: () => isModelSelected('gemma-2-9b'),
    select: () => { selectModel('gemma-2-9b') },
    title: "Gemma 2 9B",
    isOpenSource: () => true,
    getModelIcon: () => Gemini,
    getSize: () => 'small',
    getIcon: () => Google,
    vendor: "Google"
  },
  {
    id: 'paligemma',
    label: 'Pali Gemma',
    isSelected: () => isModelSelected('paligemma'),
    select: () => { selectModel('paligemma') },
    title: "Pali Gemma",
    getModelIcon: () => Gemini,
    isOpenSource: () => true,
    getSize: () => 'small',
    getIcon: () => Google,
    vendor: "Google",
    vision: true
  },
  {
    id: 'mistral-large',
    label: 'Large 2',
    isSelected: () => isModelSelected('mistral-large'),
    select: () => { selectModel('mistral-large') },
    title: "Large 2",
    getIcon: () => Mistral,
    getSize: () => 'large',
    isOpenSource: () => true,
    vendor: "Mistral",
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: 3, output: 9 },
      }]
  },
  {
    id: 'codestral',
    label: 'Medium',
    isSelected: () => isModelSelected('codestral'),
    select: () => { selectModel('codestral') },
    title: "Codestral 22B",
    getIcon: () => Mistral,
    getSize: () => 'medium',
    isOpenSource: () => true,
    vendor: "Mistral"
  },
  {
    id: 'mistral-nemo',
    label: 'Nemo 12B',
    isSelected: () => isModelSelected('mistral-nemo'),
    select: () => { selectModel('mistral-nemo') },
    title: "Nemo 12B",
    getIcon: () => Nvidia,
    getSize: () => 'small',
    isOpenSource: () => true,
    vendor: "Mistral",
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: 0.3, output: 0.3 },
      }]
  },
  {
    id: 'codestral-mamba',
    label: 'Medium',
    isSelected: () => isModelSelected('codestral-mamba'),
    select: () => { selectModel('codestral-mamba') },
    title: "Codestral Mamba 7B",
    getIcon: () => Mistral,
    getSize: () => 'small',
    isOpenSource: () => true,
    vendor: "Mistral",
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: 1, output: 3 },
      }]
  },
  {
    id: 'nemotron-4-340b',
    label: 'Nemotron 340B',
    isSelected: () => isModelSelected('nemotron-4-340b'),
    select: () => { selectModel('nemotron-4-340b') },
    title: "Nemotron 340B",
    getIcon: () => Nvidia,
    getSize: ()  => 'large',
    isOpenSource: () => true,
    vendor: "Nvidia"
  },
  {
    id: 'yi-large',
    label: 'Large',
    isSelected: () => isModelSelected('yi-large'),
    select: () => { selectModel('yi-large') },
    title: 'Large',
    getIcon: () => Yi,
    getSize: ()  => 'medium',
    isOpenSource: () => true,
    vendor: "Yi",
    contexts: [{
      input: 32,
      output: 4,
      price: { input: 3, output: 3 }
    }]
  },
  {
    id: 'qwen-72b',
    label: 'Qwen 72B',
    isSelected: () => isModelSelected('qwen-72b'),
    select: () => { selectModel('qwen-72b') },
    title: 'Qwen 72B',
    getIcon: () => Alibaba,
    getSize: ()  => 'medium',
    isOpenSource: () => true,
    vendor: "Alibaba",
    contexts: [{
      input: 32,
      output: 4,
      price: { input: .9, output: .9 }
    }]
  },
  {
    id: 'deepseek-chat',
    label: 'Chat',
    isSelected: () => isModelSelected('deepseek-chat'),
    select: () => { selectModel('deepseek-chat') },
    title: "Chat",
    getIcon: () => DeepSeek,
    getSize: ()  => 'medium',
    isOpenSource: () => true,
    vendor: "DeepSeek",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 0.14, output: 0.28 },
      }]
  },
  {
    id: 'deepseek-coder',
    label: 'Coder',
    isSelected: () => isModelSelected('deepseek-coder'),
    select: () => { selectModel('deepseek-coder') },
    title: "Coder",
    getSize: ()  => 'medium',
    getIcon: () => DeepSeek,
    isOpenSource: () => true,
    price: { input: 0.14, output: 0.28 },
    vendor: "DeepSeek",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 0.14, output: 0.28 },
      }]
  },
  {
    id: 'reka-core',
    label: 'Core',
    isSelected: () => isModelSelected('reka-core'),
    select: () => { selectModel('reka-core') },
    title: "Core",
    getIcon: () => Reka,
    getSize: ()  => 'large',
    vendor: "Reka",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 3, output: 15 },
      }
    ],
    isOpenSource: () => false,
    vision: true
  },
  {
    id: 'reka-flash',
    label: 'Flash',
    isSelected: () => isModelSelected('reka-flash'),
    select: () => { selectModel('reka-flash') },
    title: "Flash",
    getSize: ()  => 'small',
    getIcon: () => Reka,
    vendor: "Reka",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: .8, output: 2 },
      }
    ],
    isOpenSource: () => false,
    vision: true
  },
  {
    id: 'phi-3-medium',
    label: 'Phi-3 Medium',
    isSelected: () => isModelSelected('phi-3-medium'),
    select: () => { selectModel('phi-3-medium') },
    title: "Phi-3 Medium",
    getSize: ()  => 'medium',
    getIcon: () => Microsoft,
    isOpenSource: () => true,
    vendor: "Microsoft"
  },
  {
    id: 'phi-3-mini',
    label: 'Phi-3 Mini',
    isSelected: () => isModelSelected('phi-3-mini'),
    select: () => { selectModel('phi-3-mini') },
    title: "Phi-3 Mini",
    getSize: ()  => 'small',
    getIcon: () => Microsoft,
    isOpenSource: () => true,
    vision: true,
    vendor: "Microsoft"
  },
  {
    id: 'cohere-command-r+',
    label: 'Command R+',
    isSelected: () => isModelSelected('cohere-command-r+'),
    select: () => { selectModel('cohere-command-r+') },
    title: "Command R+",
    getSize: ()  => 'medium',
    getIcon: () => Cohere,
    isOpenSource: () => true,
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 3, output: 15 },
      }
    ],
    vendor: "Cohere"
  },
  {
    id: 'dbrx-instruct',
    label: 'Instruct',
    isSelected: () => isModelSelected('dbrx-instruct'),
    select: () => { selectModel('dbrx-instruct') },
    title: "DBRX Instruct",
    getSize: ()  => 'medium',
    getIcon: () => Databricks,
    isOpenSource: () => true,
    contexts: [
      {
        input: 32,
        output: 4,
        price: { input: 1.2, output: 1.2 },
      }
    ],
    vendor: "Databricks"
  }].map(m  => {
    m.isModel = y => isModel(m, y)
    return m
  })

const fineTuneModels = (get, set) => allModels(get, set).filter(m => m.id === 'attunewise'
                                                                ||
                                                                m.id === 'gpt-3.5-turbo'
                                                                ||
                                                                m.id === 'gpt-4o-mini'
                                                               )

const VendorIcons = {}
const ModelIcons = {}
Vendors.forEach((v, i) => {
  v.sortOrder = i
  VendorIcons[v.id] = v
})

allModels().forEach((m, i) => {
  m.sortOrder = i
  ModelIcons[m.id] = m
})

const ModelTabs = props => {
  const { tabs, selection, select } = props
  return <div className='savedButtonTabs'>
           {
             tabs.map(tab => {
               const selected = tab.selector === selection
               const onClick = () => {
                 select(tab.selector)
               }
               return <ModelTab icon={tab.icon} label={tab.label} onClick={onClick} selected={selected}/>
             })
           }
         </div>
}

const ModelTab = props => {
  const { selected, icon, label, onClick } = props
  let className = 'savedButtonTab'
  if (selected) {
    className += ' savedButtonTabSelected'
  }
  return <div className={className} onClick={onClick}>
           <div className='savedButtonTabIcon'>
             <ReactSVG src={icon}/>
           </div>
           <div className='savedButtonTabLabel'>
             {label}
           </div>
         </div>
}



export const HomeInput = props => {
  const { form, formErr, onChange, name, type, autocomplete, label, sublabel, placeholder } = props
  let className = 'homeButton'
  if (props.className) className += ' ' + props.className
  return <div className={className}>
           <div className='homeButtonLabels'>
             <div className='homeButtonLabel'>{label}</div>
             <div className='homeButtonSublabel'>{sublabel}</div>
           </div>
           <BnInputField  name={name} label={placeholder} formErr={formErr} form={form} type={type} onChange={onChange} autoComplete={autocomplete} busy={form.busy}/>
         </div>
}

const HomeLabel = props => {
  const { label, content } = props
  return <div className='homeButton'>
           <div className='homeButtonLabels'>
             <div className='homeButtonLabel'>{label}</div>
           </div>
           {content}
         </div>
  }


export class HomeButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }    
  }

  onClick = async () => {
    if (this.state.busy) return
    this.state.busy = true
    this.forceUpdate()
    if (this.props.action) await this.props.action()
    this.state.busy = false
    this.forceUpdate()
  }
  
  render() {
    const props = this.props
    const busy = this.props.busy || this.state.busy
    const  right = (busy) ? <ReactSVG src={Spin}/> : props.right
    let className = 'homeButton'
    if (props.className) {
      className += ' ' + props.className
    }
    return <div className={className}>
             <div className='homeButtonLabels'>
               <div className='homeButtonLabel'>{props.label}</div>
               <div className='homeButtonSublabel'>{props.sublabel}</div>
             </div>
             <div className='homeButtonButton' onClick={this.onClick}>
               <div className='homeButtonButtonIcon'>
                 <ReactSVG src={this.state.busy ? Spin : props.icon}/>
               </div>
               <div className='homeButtonButtonLabel'>
                 {props.buttonLabel}
               </div>
             </div>
           </div>
  }
}

const formatAmount = (amount, fixed = 0) => {
  if (amount > 1000000) {
    return Math.round(amount / 1000000).toFixed(1) + 'M'
  }
  if (amount > 1000) {
    return Math.round(amount/1000) + 'K'
  }
  if (amount >= 25) {
    fixed = 0
  }
  return parseFloat(amount.toFixed(fixed)).toString()
}

const Stat = props => {
  let className = 'keyboardHomeStat'
  if (props.className) {
    className += ' ' + props.className
  }
  return <div className={className}>
           <div className='keyboardHomeStatLabel'>
             {props.label}
             </div>
           <div className='keyboardHomeStatValue'>
             {formatAmount(props.value, 2)}
             </div>
           </div>
}


export class Checkbox extends Component {
  render() {
    let button
    if (this.props.selected) {
      button = <div className='keyboardCheckboxSelected' onClick={this.props.toggle}>
                 <div className='keyboardCheckboxLeft'/>
                 <div className='keyboardCheckboxOn'>{this.props.on || 'On'}</div>
               </div>
      
    } else {
      button = <div className='keyboardCheckboxUnselected' onClick={this.props.toggle}>
                 <div className='keyboardCheckboxOff'>{this.props.off || 'Off'}</div>
                 <div className='keyboardCheckboxOffButton'/>
               </div>
      
    }
    return <div className='keyboardCheckbox'>
             <div className='keyboardCheckboxLabel'>
               {this.props.label}
             </div>
             {button}
             <div className='keyboardCheckboxRight'/>
           </div>

  }
}

export class Account extends BnSubpage {

  componentDidMount() {
    /*
    this.props.me.getReferralCode().then(code => {
      this.set('referralCode', code)
      this.forceUpdate()
      })
      */
    const { countryCode, phoneNumber } = parsePhoneNumber(this.props.me.self.phoneNumber)
    this.set('countryCode', countryCode)
    this.set('phoneNumber', this.props.me.self.phoneNumber)
    if (this.props.me.isSignedInAnonymously()) {
      this.signUp()
    }
    this.set('email', this.props.me.self.email)
    this.set('name', this.props.me.self.displayName)
  }

  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  onBack() {
    this.saveClaudeApiKey()
  }

  updateEmail = async () => {
  }

  updateDisplayName = async () => {
  }

  updatePhoneNumber = async () => {
    const form = this.getForm()
    let phoneNumber = form.phoneNumber
    let countryCode = form.countryCode
    const converted = phone(phoneNumber);
    if (!converted.length) {
      formErr = {field: 'phoneNumber', message: 'Invalid phone number.'};
      this.forceUpdate()
      return
    } else {
      phoneNumber = converted[0];
    }
    await this.props.me.updatePhoneNumber(phoneNumber)
  }

  signOut = async () => {
    this.props.back()
    await delay(1.0);
    await this.props.me.signOut()
  }


  deleteAccount = async () => {
    if (!this.state.confirmDeleteAccount) {
      this.setState({
        confirmDeleteAccount: true
      })
    } else {
      await this.props.me.deleteAccount()
    }
  }

  cancelDeleteAccount = () => {
    this.setState({
      confirmDeleteAccount: false
    })
  }

  saveClaudeApiKey = () => {
    localStorage.setItem("claude-api-key", this.getForm().claudeApiKey)
  }

  copyField = async (field) => {
    const form = this.getForm()
    navigator.clipboard.writeText(form[field])
    await delay(0.5)
  }

  
  renderContent() {
    const APP = 'this app'
    const { word, Word } = getWord(this.props.me)
    let deleteAccountClass = 'keyboardHomeAccountDelete'
    let deleteAccountLabel = 'Delete Account'
    if (this.state.confirmDeleteAccount) {
      deleteAccountClass += ' keyboardHomeAccountDeleteConfirm'
      deleteAccountLabel = 'Confirm Delete Account'
    }
    let isSignedIn
    let hasPhone
    let hasEmail
    let hasDisplayName
    if (this.props.me) {
      isSignedIn = !this.props.me.isSignedInAnonymously()
      hasPhone = true
      hasEmail = true
      hasDisplayName = true
    }
    return <div className='bnSubpageContent keyboardHome'>
             <div className='keyboardHomeAccountSpacer0'/>
             <div className='keyboardHomePhone'>
               <HomeInput placeholder='Name' type='name' autocomplete='name' label='name' name='name' form={this.getForm()} formErr={this.getFormErr()} onChange={undefined /*this.onChange*/}/>
               <KeyboardButton label='Update' icon={Update} action={this.updateDisplayName}/>
             </div>
             <div className='keyboardHomePhone'>
               <HomeInput placeholder='Phone Number' type='tel' autocomplete='tel' name='phoneNumber' label='phone number' name='phoneNumber' form={this.getForm()} formErr={this.getFormErr()} onChange={undefined /*this.onChange*/}/>
               <KeyboardButton label='Update' icon={Update} action={this.updatePhoneNumber}/>
             </div>
             <div className='keyboardHomePhone'>
               <HomeInput placeholder='Email Address' type='email' autocomplete='email' label='email' name='email' form={this.getForm()} formErr={this.getFormErr()} onChange={undefined /*this.onChange*/}/>
               <KeyboardButton label='Update' icon={Update} action={this.updateEmail}/>
             </div>
             <div className='keyboardHomeAccountSpacer1'/>
             <HomeButton label='sign out of your account' buttonLabel='Sign out' action={this.signOut} icon={Profile}/>}
             <div className='keyboardHomeAccountSpacer1'/>
             <ClickAwayListener onClickAway={this.cancelDeleteAccount}>
               <div className={deleteAccountClass}>
                 <HomeButton
                   icon={Cross} label='permanently close account'
                   sublabel={`All data will be erased from our servers and you will need to create a new account to continue using ${APP}`}
                   buttonLabel={deleteAccountLabel} action={this.deleteAccount}/>
               </div>
             </ClickAwayListener>
           </div>
  }
}

const uadd2 = (u1, u2) => {
  const result = {}
  const acc = u => {
    for (const id in u) {
      result[id] = result[id] || {inputTokens: 0, outputTokens: 0}
      result[id].inputTokens += u[id].inputTokens
      result[id].outputTokens += u[id].outputTokens
    }
  }
  acc(u1)
  acc(u2)
  return result
}

const uadd = (u1, u2) => {
  return {
    inputTokens: (u1.inputTokens || 0) + (u2.inputTokens || 0),
    outputTokens: (u1.outputTokens || 0) + (u2.outputTokens || 0),
  }
}

export class Usage extends BnSubpage {

  constructor (props) {
    super(props)
    this.usages = {}
    this.state = {
      events: [],
      currentDate: this.props.initialDate || new Date(),
      view: 'month'
    }
  }

  observeCurrentUsage = () => {
    let date = this.state.currentDate
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    console.log({month, currentMonth: this.currentMonth})
    if (this.currentYear !== year ||
        this.currentMonth !== date.getMonth()) {
      this.currentYear = date.getFullYear()
      this.currentMonth = date.getMonth()
      if (this.sub) {
        this.sub.unsubscribe()
      }
      this.usages = {}
      this.sub = this.props.me.observeUsage({year, month}).subscribe(change => {
        const { type, usage } = change
        if (type === 'removed') {
          delete this.usages[usage.id]
        } else {
          this.usages[usage.id] = usage
        }
        this.updateUsageLater()
      })
      this.updateUsageLater()
    } else {
      this.updateUsage()
    }
  }

  updateUsageLater = () => {
    clearTimeout(this.updateUsageTimeout)
    this.updateUsageTimeout = setTimeout(this.updateUsage, 200)
  }

  updateUsage = () => {
    const d = startOfDay(this.state.currentDate).getTime()
    const w = startOfWeek(this.state.currentDate).getTime()
    const m = startOfMonth(this.state.currentDate).getTime()
    const filt = event => {
      const { daily } = event
      const { ts } = daily
      const c = { d: startOfDay(ts).getTime(),
                  w: startOfWeek(ts).getTime(),
                  m: startOfMonth(ts).getTime()
                }
      switch (this.state.view) {
        case 'day':
          return c.d === d
        case 'week':
          return c.w === w
        case 'month':
          return c.m === m
        default:
          debugger
      }
    }
    const usages = Object.values(this.usages)
    console.log({usages: usages.length})
    const events = usages.map((elem) => {
      const { id, ts } = elem
      const start = startOfDay(new Date(ts))
      return {
        id,
        start,
        text: '',
        daily: elem
      }
    }).filter(filt)
    console.log({events})
    this.setState({
      events
    })
  }

  getPrices = () => {
    this.modelPrices = {}
    for (const model of allModels()) {
      let { price, contexts, id } = model
      if (contexts) {
        price = contexts[0].price
      }
      if (price) {
        this.modelPrices[model.id] = price
      } else {
        //debugger
      }
    }
    console.log("MODEL_PRICES", this.modelPrices)
    this.forceUpdate()
  }

  componentDidMount() {
    this.getPrices()
    this.observeCurrentUsage()
  }

  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  onBack() {
  }

  getUsage = model => {
    return { input: 0, output: 0 }
  }

  onViewChange = view => {
    debugger
    this.setState({
      view
    }, this.observeCurrentUsage)
  }

  onDayChange = (date) => {
    debugger
    this.setState({
      currentDate: new Date(date)
    }, this.observeCurrentUsage)
  }
  
  renderContent() {
    let vendors = {}
    let vendorList = this.props.vendors
    const selectedVendors = {}
    const isModelSelected = model => false
    const models = this.props.models
    const modelById = []
    const vendorByName = {}
    for (const vendor of vendorList) {
      vendorByName[vendor.name] = vendor
    }
    let vendorItems = []
    const overall = this.state.events.map(event => event.daily)
    const perModel = {}
    const perVendor = {}
    const prices = this.modelPrices || {}
    const getPrice1 = id => {
      return prices[id] || { input: 0, output: 0 }
    }
    const total = {
      input: 0,
      output: 0
    }
    for (const model of models) {
      modelById[model.id] = model
      let arr = vendors[model.vendor]
      if (!arr) {
        vendors[model.vendor] = arr = []
      }
      arr.push(model)
    }
    for (const item of overall) {
      for (const id in item.usage) {
        const usage = item.usage[id]
        if (!usage.inputTokens) {
          debugger
        }
        if (!perModel[id]) {
          perModel[id] = { input: 0, output: 0} 
        }
        const price = getPrice1(id)

        total.input += price.input * usage.inputTokens / (1000*1000)
        total.output += price.output * usage.outputTokens / (1000*1000)

        perModel[id].input += price.input * usage.inputTokens / (1000*1000)
        perModel[id].output += price.output * usage.outputTokens / (1000*1000)
        const model = modelById[id]
        if (!model) {
          console.error("model not found", id)
          continue
        }
        const vendor = vendorByName[model.vendor]
        if (!perVendor[vendor.id]) {
          perVendor[vendor.id] = { input: 0, output: 0} 
        }
        perVendor[vendor.id].input += price.input * usage.inputTokens / (1000*1000)
        perVendor[vendor.id].output += price.output * usage.outputTokens / (1000*1000)
      }
    }
    console.log("PER VENDOR", perVendor)
    console.log("PER MODEL", perModel)
    const getPrice = model => {
      return perModel[model.id] || { input: 0, output: 0 }
    }
    const fmt = price => formatPrice(price, 2)
    for (const vendor of vendorList) {
      const models = vendors[vendor.name] || []
      let price = perVendor[vendor.id]
      vendorItems.push(<ModelVendor
                         aggregatePrice={price}
                         open={vendorList.length == 1 ? true : undefined}
                         key={vendor.name}
                         vendor={vendor}
                         me={this.props.me}
                         models={models}
                         formatPrice={fmt}
                         getPrice={getPrice}/>)
    } 
    return <div className='attunewiseUsageView'>
             <div className='attunewiseUsageCal'>
               <Calendar onPageChange={this.onPageChange} onViewChange={this.onViewChange} events={this.state.events} onDayChange={this.onDayChange} initialView={'month'} viewSelection={['day', 'week', 'month']} />
             </div>
             <div className='attunewiseUsage'>
               <div className='attunewiseTotalUsage'>
                 <div className='attunewiseTotalUsageLabel'>Total</div>
                 <div className='attunewiseTotalUsageValue'><div className='modelPrice'>${fmt(total.input)}/{fmt(total.output)}</div></div>
               </div>
               {vendorItems}
             </div>
           </div>
  }
}



class GPT extends BnSubpage {

  constructor(props) {
    super(props)
    this.state.opacity = 0
  }

  overrideCSS = () => {
    console.log('overrideCSS')
    const cssOverrides = `

#__next > div.relative.z-0.flex.h-full.w-full.overflow-hidden > div > main > div.flex.h-full.flex-col > div.flex-1.overflow-hidden > div > div.flex.h-full.flex-col.items-center.justify-center.text-token-text-primary > div.relative > div > div {


}
#__next > div.relative.z-0.flex.h-full.w-full.overflow-hidden > div > main > div.flex.h-full.flex-col > div.flex-1.overflow-hidden > div > div.flex.h-full.flex-col.items-center.justify-center.text-token-text-primary > div.flex.flex-col.items-center.gap-2 > div.flex.items-center.gap-1.text-token-text-tertiary > div > div.text-sm.text-token-text-tertiary {
  display: none !important;
}

#__next [aria-haspopup="menu"] + div {
    display: none !important;
}

#__next [aria-haspopup="dialog"] {
    display: none !important;
}

#__next > div.relative.z-0.flex.h-full.w-full.overflow-hidden > div > div.text-token-primary.sticky.top-0.z-10.flex.min-h-\[40px\].items-center.justify-center.border-b.border-token-border-medium.bg-token-main-surface-primary.pl-1.md\:hidden > div.absolute.bottom-0.right-0.top-0.flex.items-center > button {
    display: none !important;
}

nav[aria-label="Chat history"] > div:last-child {
    display: none !important;
}

nav[aria-label="Chat history"] .sticky > div {
    display: none !important;
}

.sticky + div {
    display: none !important;
}

form + div {
    visibility: hidden !important;
}

#a:not([href*='/g/g-lLczqaVIq-assistant']) {
    display: none !important;
}
`
    this.webview.insertCSS(cssOverrides)
    //this.webview.openDevTools()
    setTimeout(() => {
      this.setState({
        opacity: 1
      })
    }, 500)
  }
  setWebView = webview => {
    console.log('onReady', webview)
    this.webview = webview
  }

  renderContent() {
    const style={
      opacity: this.state.opacity,
    }    
    return <div className='gpt' style={style}>
             <div className='gptBack'>
               <KeyboardButton1 className='gptBackButton' icon={Cross} action={this.props.goBack}/>
               </div>
             {<ElectronWebView allowpopups onDidFinishLoad={this.overrideCSS} ref={this.setWebView} src={'https://chat.openai.com/g/g-lLczqaVIq-assistant'}/>}
           </div>
  }
}


export class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      nonFree: false,
      wordsUsed: 0,
      wordsPurchased: 0,
      buttons: [],
      writeButtons: [],
      savedButtonSelector: 'create',
      selectedModel: localStorage.getItem("assistantModel") || 'gpt-4',
      form: {
        claudeApiKey: localStorage.getItem('claude-api-key')
      }
    }
  }

  render() {
    const subpage = this.state.subpage ? this.state.subpage() : null
    const content = this.renderContent()
    return  <BnPage me={this.props.me} subpage={subpage} safeArea={true}>
             {content}
             </BnPage>
           
  }

  back = () => {
    debugger
    this.setState({
      subpage: null
    })
  }

  signUp = async (andThen) => {
    const back = this.back
    const next = () => {
      andThen()
    }
    debugger
    this.setState({
      subpage: () => <KeyboardLogin onCreate={this.loginInProgress} me={this.props.me} safeArea={false} back={back} next={next}/>
    })
  }

  isSignedIn = () => !this.props.me.isSignedInAnonymously()

  ensureSignedIn = () => {
    if (this.isSignedIn()) {
      return Promise.resolve(true)
    }
    return new Promise(resolve  => {
      this.signUp(() => {
        if (this.isSignedIn()) {
          resolve(false)
        } else {
          this.back()
        }
      })
    })
  }

  openAccount = async () => {
    if (this.isSignedIn()) {
      this.setState({
        subpage: () => <Account title={"Account"}
                                me={this.props.me}
                                getSetting={this.props.getSetting}
                                toggleSetting={this.props.toggleSetting}
                                
                                back={this.back}/>
      })
      return
    }
    await this.ensureSignedIn()
    this.back()
  }

  openUsage = async () => {
    this.setState({
      subpage: () => <Usage title={"Usage"}
                            models={this.getModels().map(x => {
                              x.isSelected = () => false
                              x.select = () => {}
                              return x
                            })}
                            vendors={this.state.vendors}
                            me={this.props.me}
                            back={this.back}/>
    })
  }


  buy = async () => {
    this.ensureSignedIn().then(() => {
      const { word, Word } = getWord(this.props.me)
      this.setState({
        subpage: () => <WordPackPurchase title={`${Word} Packs`}
                                         getSetting={this.props.getSetting}
                                         toggleSetting={this.props.toggleSetting}
                                         me={this.props.me} back={this.back} available={formatAmount(Math.max(this.state.wordsPurchased - this.state.wordsUsed, 0))}/>
      })
    })
  }

  shareKeyboardOutput = async output => {
  }

  openWritingAssistant = () => {
    this.setState({
      subpage: () => <Keyboard me={this.props.me}
                               onNotENoughTokens={this.onNotEnoughTokens}
                               isWritingAssistant={true}
                               cancelKeyboardOutput={this.back}
                               sendKeyboardOutput={this.shareKeyboardOutput}
                     />

    })
  }


  openBaseModels = () => {
    this.openChat({
      isBaseModel: true
    })
  }

  openModels = async () => {
    this.setState({
      subpage: () => <ModelsView title={"Models"}
                                 models={(isSelected, select) => {
                                   debugger
                                   return this.getModels().map( x => {
                                     try {
                                       x.isSelected = () => isSelected(x.id)
                                       x.select = () => select(x.id)
                                     } catch(err) {
                                       debugger
                                     }
                                     return x
                                   })
                                 }}
                                 onOptionsChanged={() => this.forceUpdate()}
                                 category={'models'}
                                 observeOptions={this.props.me.observeModelOptions}
                                 saveOptions={this.props.me.saveModelOptions}
                                 me={this.props.me}
                                 prices={this.prices}
                                 vendors={this.state.vendors}
                                 menuActive={true}
                                 back={this.back}/>
    })
  }

  openChat = async (opts) => {
    localStorage.setItem("assistantModel", this.state.selectedModel)
    const modelsById = {}
    for (const model of allModels()) {
      modelsById[model.id] = model
    }
    this.setState({
      subpage: () => <ChatGPT
                       availableCredits={Math.max(this.state.wordsPurchased - this.state.wordsUsed, 0)}
                       prices={this.prices}
                       vendors={this.state.vendors}
                       me={this.props.me}
                       back={this.back}
                       defaultModel={chatGPT => 'Attunewise Mini'}
                       deleteTask ={
                         async (chatGPT, task) => {
                           await this.props.me.deleteTask(task)
                         }
                       }
                       getTitle={(chatGPT) => {
                         if (chatGPT.state.selectedTask) {
                           return chatGPT.state.judgeChat ? 'Judge': 'Discussion'
                         }
                         return 'Playground'
                       }}
                       getMessageTopic = {(chatGPT, message, prev) => {
                         if (message.role !== 'user') {
                           let model = resolveModelId(message.model)
                           const m = modelsById[model]
                           if (m) {
                             return m.vendor + " " + m.title
                           }
                           return model
                         }
                         return ''
                       }}
                       getButtonLabel ={(chatGPT, message) => {
                         if (chatGPT.state.judgeChat) {
                           return "Judge"
                         }
                         else if (!chatGPT.state.sending && message.inReplyTo) {
                           return 'Repeat'
                         }
                         return 'Send'
                       }}
                       models={
                         (chatGPT, isSelected, select) => this.getModels().map(x => {
                           x.isSelected = () => isSelected(x.id)
                           x.select = () => select(x.id)
                           return x
                         })
                       }

                              isSearchFieldVisible={(chatGPT, messages) => {
                                return chatGPT.state.slide === 0
                              }}
                              observeTaskMessages={this.props.me.observeTaskMessages}
                              observeTasks={this.props.me.observeTasks}
                              observeRecentTasks={this.props.me.observeRecentTasks}
                              deleteChatMessage={this.props.me.deleteChatMessage}
                              getHistory={(chatGPT, task, earliest, limit) => this.props.me.getHistory(task, earliest, limit)}
                              searchChatMessages={this.props.me.searchChatMessages}
                              searchTasks={this.props.me.searchTasks}
                              streamChat={(chatGPT, x, opts)=>this.props.me.streamChat(x, opts)}
                              createNewTask={this.props.me.createNewTask}
                              uploadFile={this.props.me.uploadFile}
                              onCloseTask={(chatGPT, task) => this.props.me.updateTaskSummary(task)}
                     />
    })
  }

  openDatasets = async (opts) => {
    let sub
    let fineTunes = {}
    let impl
    const onCreate = (chatGPT) => {
      impl = chatGPT
      sub = this.props.me.observeFineTuneModels().subscribe(change => {
        const { type, model } = change
        if (type === 'removed') {
          delete fineTunes[model.id]
        } else {
          fineTunes[model.id] = model
        }
        this.forceUpdate()
      })
    }
    const onDelete = (chatGPT) => {
      if (sub) {
        sub.unsubscribe()
      }
    }
    const getModels = (chatGPT, isSelected, select) => {
      const all = allModels(isSelected, select)
      return all
    }
    this.setState({
      subpage: () => <ChatGPT
                       onCreate={onCreate}
                       onDelete={onDelete}
                       me={this.props.me} back={this.back}
                       deleteTask = {
                         (chatGPT, task) => {
                         }
                       }
                       getTitle={(chatGPT) => {
                         if (!chatGPT.state.selectedMessage) {
                           if (chatGPT.state.selectedTask) {
                             return chatGPT.state.selectedTask.description
                           } else {
                             return "Data sets"
                           }
                         } else {
                           return '# ' +chatGPT.state.selectedMessage.lineNumber
                         }
                       }}
                       models={getModels}
                       isSearchFieldVisible={(chatGPT, messages) => {
                         return !chatGPT.state.selectedMessage
                       }}
                       defaultModel={chatGPT => chatGPT.state.selectedMessage ? 'Train': ''}
                       getMessageTopic = {(chatGPT, message, prev) => {
                         if (message.role === 'assistant') {
                           if (chatGPT.state.selectedMessage) {
                             return message.model
                           }
                           return ''
                         } else if (message.role === 'user') {
                           if (!prev || message.lineNumber !== prev.lineNumber) {
                             return '# ' + message.lineNumber
                           }
                         } else {
                           debugger
                         }
                         return ''
                       }}
                       resolveModel={(chatGPT, message) => {
                         if (chatGPT.state.selectedMessage) {
                           if (message.role == 'assistant') {
                             return message.model
                           }
                         }
                         return ''
                       }}
                       observeTaskMessages={this.props.me.observeData}
                       observeTasks={() => this.props.me.observeDatasets().pipe(map(change => {
                         const { type, dataset } = change
                         debugger
                         const task = {
                           id: dataset.id,
                           description: dataset.label,
                           lastUpdated: dataset.lastUpdated.toDate().getTime(),
                           messages: 1,
                           dataset
                         }
                         return {type, task }
                       }))}
                       getButtonLabel ={(chatGPT, message) => {
                         return "Send"
                       }}
                        onClickMessage={(message, chatGPT) => {
                         console.log('selected message', message)
                         chatGPT.setState({
                           selectedMessage: message
                         })
                         chatGPT.invalidateCache()
                       }}
                       messageFilter={(message, chatGPT) => {
                         const selected = chatGPT.state.selectedMessage
                         if (selected) {
                           /*
                             if (selected.role === 'user') {
                             return message.id === selected.id || message.inReplyTo === selected.id
                             } else {
                             return message.id === selected.id || selected.inReplyTo === message.id
                             }
                           */
                           return selected.datum === message.datum
                         }
                         return true
                       }}
                       onBack={(chatGPT, defaultAction) => {
                         if (chatGPT.state.selectedMessage) {
                           chatGPT.invalidateCache(chatGPT.state.selectedMessage)
                           chatGPT.setState({selectedMessage: null })
                         } else {
                           defaultAction()
                         }
                       }}
                       enableInput={(chatGPT) => {
                         return chatGPT.state.selectedMessage
                       }}
                       deleteChatMessage={this.props.me.deleteDatasetMessage}
                       getHistory={(chatGPT, task, earliest, limit) => {
                         if (chatGPT.state.selectedMessage) {
                           return []
                         }
                         return this.props.me.getDatasetHistory(task, earliest, limit)
                       }}
                       searchChatMessages={this.props.me.searchData}
                       searchTasks={this.props.me.searchDatasets}
                       streamChat={(chatGPT, x, opts) => {
                         x.messages = chatGPT.getMessages()
                         return this.props.me.streamChat(x, opts)
                       }}
                       createNewTask={this.props.me.createNewDataset}
                       uploadFile={this.props.me.uploadDataset}
                       onNewFile={file=> {}}
                       newFileTypes={'text/plain; charset=utf8'}
                       onCloseTask={(chatGPT, task) => {}}
                     />
    })
  }

  openFineTuning = async (opts) => {
    const streamFineTuningJobChat = async () => {
    }
    let sub
    let fineTunes = {}
    let impl
    const onCreate = (chatGPT) => {
      impl = chatGPT
      sub = this.props.me.observeFineTuneModels().subscribe(change => {
        const { type, model } = change
        if (type === 'removed') {
          delete fineTunes[model.id]
        } else {
          fineTunes[model.id] = model
        }
        this.forceUpdate()
      })
    }
    const onDelete = (chatGPT) => {
      if (sub) {
        sub.unsubscribe()
      }
    }
    this.setState({
      subpage: () => <ChatGPT me={this.props.me} back={this.back}
                              title={chatGPT => chatGPT.state.selectedMessage ? 'Playground': 'Fine-tuning'}
                              getTitle={(chatGPT) => {
                                if (!chatGPT.state.selectedMessage) {
                                  if (chatGPT.state.selectedTask) {
                                    return chatGPT.state.selectedTask.description
                                  } else {
                                    return "Fine-tuning"
                                  }
                                } else {
                                  return '# ' +chatGPT.state.selectedMessage.lineNumber
                                }
                              }}
                              models={(chatGPT, isSelected, select) => fineTuneModels(isSelected, select)}
                              defaultModel={chatGPT => ''}
                              isSearchFieldVisible={(chatGPT, messages) => {
                                return !chatGPT.state.selectedMessage
                              }}
                              observeTaskMessages={this.props.me.observeFineTuningJobMessages}
                              observeTasks={() => this.props.me.observeFineTuningJobs().pipe(map(change => {
                                const { type, job } = change
                                let { description, lastUpdated } = job
                                if (job.job) {
                                  description =
                                    `**Input Model**:    ${job.model}  \n**Data Set**: ${description}  \n**Output Model**:   ${job.job.fine_tuned_model}`
                                }
                                const task = {
                                  id: job.id,
                                  description,
                                  lastUpdated,
                                  messages: 1,
                                  job
                                }
                                return {type, task }
                              }))}
                              onBack={(chatGPT, defaultAction) => {
                                if (chatGPT.state.selectedMessage) {
                                  chatGPT.invalidateCache(chatGPT.state.selectedMessage)
                                  chatGPT.setState({selectedMessage: null })
                                } else {
                                  defaultAction()
                                }
                              }}
                              messageFilter={(message, chatGPT) => {
                                const selected = chatGPT.state.selectedMessage
                                if (selected) {
                                  /*
                                    if (selected.role === 'user') {
                                    return message.id === selected.id || message.inReplyTo === selected.id
                                    } else {
                                    return message.id === selected.id || selected.inReplyTo === message.id
                                    }
                                  */
                                  return selected.datum === message.datum
                                }
                                return true
                              }}
                              enableInput={(chatGPT) => {
                                return chatGPT.state.selectedMessage
                              }}
                              deleteChatMessage={null}
                              getHistory={(chatGPT, task, earliest, limit) => this.props.me.getFineTuningJobsHistory(task, earliest, limit)}
                              searchChatMessages={this.props.me.searchFineTuningJobMessages}
                              searchTasks={this.props.me.searchFineTuningJobs}
                              streamChat={null}
                              createNewTask={this.props.me.createNewFineTuningJob}
                              onOpenTask={(chatGPT, task) => {
                                this.props.me.getStepMetrics(task).then(({stepMetrics}) => {
                                })
                              }}
                              renderTaskNew={(chatGPT, opts)=> {
                                debugger
                                const { task, onClick } = opts
                                return <FineTuningTask select={onClick} task={task} models={chatGPT.models} datasets={[]}/>
                              }}
                              getButtonLabel ={(chatGPT, message) => {
                                return "Send"
                              }}
                              onCloseTask={(chatGPT, task) => {}}
                              deleteTask ={
                                (chatGPT, task) => {
                                }
                              }
                              getMessageTopic = {(chatGPT, message, prev) => {
                                if (message.role === 'assistant') {
                                  if (chatGPT.state.selectedMessage) {
                                    return message.model
                                  }
                                  return ''
                                } else if (message.role === 'user') {
                                  if (!prev || message.lineNumber !== prev.lineNumber) {
                                    return '# ' + message.lineNumber
                                  }
                                } else {
                                  debugger
                                }
                                return ''
                              }}
                              newTopic={(chatGPT)=>{
                                chatGPT.setState({
                                  newFineTune: true
                                })
                                return {}
                              }}
                     />
    })
  }

  

  renderContent() {
    const selectModel = model => {
      this.setState({
        selectedModel: model
      })
      this.props.me.saveChatSettings({
        model
      })
    }
    const models = [
      {
        label: 'GPT-4',
        vendor: 'openai',
        selected: this.state.selectedModel === 'gpt-4',
        select: () => { selectModel('gpt-4', 'openai') },
        title: "GPT-4 Turbo",
        icon: OpenAI
      },
      {
        label: 'Claude-3',
        vendor: 'anthropic',
        selected: this.state.selectedModel === 'claude-3',
        select: () => { selectModel('claude-3', 'anthropic') },
        title: "Claude-3",
        icon: Anthropic
      }
    ]
    const modelButtons = <RadioButtons buttons={models}/>
    const { word, Word } = getWord(this.props.me)
    let openLinkedIn, openTwitter, openFacebook, openTikTok
    const s = (window.innerWidth - 20) / 240
    let style = {
      transform: `scale(${s},${s})`,
    }
    const { wordsUsed, wordsPurchased } = this.state 
    let available = Math.max(wordsPurchased - wordsUsed, 0)
    console.log({available, wordsUsed})
    if (isNaN(available)) {
      debugger
    }
    let className = 'keyboardWindow keyboardHome'
    let isLow = available <= 10
    let isLowMessage
    const form = this.state.form
    if (available === 0) {
      isLowMessage = `You\'re out of ${word}s! You can purchase additional ${word}s for your account below.`
    } else if (isLow) {
      isLowMessage = `${Word}s are running low! You can purchase additional ${word}s for your account below.`
    }
    if (isLowMessage) {
      className += ' keyboardHomeWordPacksLowStatus'
    }


    let openAIStatusClass = ''
    let openAIStatusDescription = 'Systems Operational'
    let provider = 'All'
    if (this.state.openAIStatus) {
      let { indicator, description } = this.state.openAIStatus
      openAIStatusClass = ' openAIStatus' + capitalize(indicator) 
      openAIStatusDescription = description.replace(/(Partially|All) /g, '');
      if (openAIStatusDescription !== 'Systems Operational') {
        provider = 'OpenAI'
      }
    }

    let blurbStyle = {
      fontSize: (Math.min(window.innerWidth, 600)*0.5 / 240) * 16
    }


    
    const playground = async () => {
      await this.openChat()
    }

    const datasets= async () => {
      await this.openDatasets()
    }

    const finetuning = async () => {
      await this.openFineTuning()
    }

    const account = async () => {
      this.openAccount()
    }

    const usage = async () => {
      this.openUsage()
    }

    let askLabel = 'reliable answers'
    let askButtonLabel = 'Ask'
    let title = TITLE
    title = "Attunewise.ai"
    let willRedirect = false

    const used = this.state.wordsUsed
    const purchased = this.state.wordsPurchased

    let Settings = 'Settings'
    if (this.props.me.isSignedInAnonymously()) {
      Settings = "Sign-in"
    }
    
    return <div className={className}>
             <div className='keyboardHomeHeader'>
               <div className='keyboardHeaderTitle'>{title}</div>
             </div>
             <div className='keyboardHomeTop'>
               <div className='keyboardHomeShape'>
<svg id="HeroMask" data-name="Hero" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 558 203">
	<defs>
		<clipPath id="shape">
			<polygon points=".359 43.07 65.272 155.504 37.969 202.795 222.403 202.795 130.186 43.07 .359 43.07" />
			<polygon points="394.23 154.515 471.333 21.328 281.753 21.328 293.949 .205 115.766 .205 204.858 154.515 394.23 154.515" />			
		</clipPath>
	</defs>

  <image href={HeroImage} clipPath="url(#shape)" />
			
	<g className="heroMaskLeft">
	  <polygon points=".359 43.07 65.272 155.504 37.969 202.795 222.403 202.795 130.186 43.07 .359 43.07" style={{fill:"#086461;"}} opacity="0.9" />
	</g>
			
	<g className="heroMaskMiddle">
	  <polygon points="394.23 154.515 471.333 21.328 281.753 21.328 293.949 .205 115.766 .205 204.858 154.515 394.23 154.515" style={{fill:"#086461;"}} opacity="0.9" />
	</g>
			
  <g className={"heroMaskRight" + openAIStatusClass}>
	  <polygon points="481.15 21.408 404.301 154.515 558 154.515 481.15 21.408" style={{fill:"#0A807E;"}} />
          <g transform='translate(481.15, 148.515)'>
                      <text className={'openAIStatusDescriptionOpenAI'}>{provider}</text>
                       <text className={'openAIStatusDescription'}>{openAIStatusDescription}</text>
                     </g>
	</g>
</svg>
                 <div className='keyboardHomeStats'>
                   <Stat className='statAvailable' label={`${word}s available`} value={available}/>
                   <Stat className='statUsed' label='used' value={used}/>
                   <Stat className='statPurchased' label={this.state.nonFree ? 'purchased' : 'free'} value={purchased}/>

                 </div>
               </div>
             </div>
             <div className='intellikeyLinks' style={null}>
               {openFacebook && <div className='facebookLink' onClick={openFacebook}><ReactSVG src={Facebook}/></div>}
               {openTwitter && <div className='facebookLink' onClick={openTwitter}><ReactSVG src={Twitter}/></div>}
               {openTikTok && <div className='facebookLink' onClick={openTikTok}><ReactSVG src={TikTok}/></div>}
             </div>
             <div className='keyboardHomeBottom'>
               <div className='attunewiseHomeButtons'>
                 {isLowMessage && <div key='lowBro' className='keyboardHomeWordPacksLow'>
                                    <div className='keyboardHomeWordPacksLowIcon'>
                                    <ReactSVG src={AISaid}/>
                                    </div>
                                    <div className='keyboardHomeWordPacksLowMessage'>
                                      {isLowMessage}
                                    </div>
                                  </div>}
                 <HomeButton label="Explore Models" buttonLabel='Models' action={this.openModels}/>
                 <HomeButton label="Compare Models" buttonLabel='Playground' action={playground}/>
                 {false && <HomeButton label="Optimize Models" buttonLabel='Fine-tuning' action={finetuning}/>}
                 {false && <HomeButton label="Manage Data" buttonLabel='Data sets' action={datasets}/>}
                 <HomeButton label="TRACK USAGE" buttonLabel='Usage' action={usage}/>
                 {false && <HomeButton label="Access API" buttonLabel='API Keys'/>}
                 <HomeButton label="Account" buttonLabel={Settings} action={account}/>
                 <HomeButton className={'wordPackBuyButton' + (
                            isLowMessage ? ' wordPackBuyButtonLow' : '')}  icon={Buy} label={`add ${word}s to your account`} buttonLabel={`${Word} Packs`} action={this.buy}/>
               </div>
             </div>
           </div>
  }


  selectPage = page => {
    if (page === 'ask') {
      this.openChat()
    } else {
      this.back()
    }
  }

  updateModelsLater = () => {
    clearTimeout(this.updateModelsTimeout)
    this.updateModelsTimeout = setTimeout(this.updateModels)
  }

  getModels = () => {
    const models = Object.values(this.models).map(model => {
      model = JSON.parse(JSON.stringify(model))
      const v = VendorIcons[model.vendorId]
      const m = ModelIcons[model.id]
      model.getIcon = v.getIcon
      model.getModelIcon = m.getModelIcon
      model.sortOrder = m.sortOrder
      model.getSize = () => model.size
      model.isModel = y => isModel(m, y)
      return model
    })
    models.sort((x, y) => x.sortOrder - y.sortOrder)
    console.log({models})
    return models
  }

  updateModels = () => {
    const models = this.getModels()
    this.setState({
      models
    })
  }

  updateVendorsLater = () => {
    clearTimeout(this.updateVendorsTimeout)
    this.updateVendorsTimeout = setTimeout(this.updateVendors)
  }

  updateVendors = () => {
    const vendors = Object.values(this.vendors).map(vendor => {
      const v = VendorIcons[vendor.id]
      vendor.getIcon = v.getIcon
      vendor.sortOrder = v.sortOrder
      return vendor
    })
    vendors.sort((x, y) => x.sortOrder - y.sortOrder)
    this.setState({
      vendors
    })
  }

  
  updatePricesLater = () => {
    clearTimeout(this.updatePricesTimeout)
    this.updatePricesTimeout = setTimeout(this.updatePrices)
  }

  updatePrices = () => {
    const prices = Object.values(this.prices)
    this.setState({
      prices
    })
  }

  updatePurchasesLater = () => {
    clearTimeout(this.updatePurchasesTimeout)
    this.updatePurchasesTimeout = setTimeout(this.updatePurchases)
  }

  purchases = {}

  updatePurchases = () => {
    const purchases = Object.values(this.purchases)
    let purchased = 0
    for (const purchase of purchases) {
      if (purchase.price > 0) {
        this.state.nonFree = true
      }
      purchased += purchase.creditCount
    }
    this.setState({
      wordsPurchased: purchased
    })
  }
  

  vendors = {}
  models = {}
  prices = {}
  purchases = {}
  
  componentDidMount() {
    this.selfSub = this.props.me.observeSelf().subscribe(self => {
      if (self) {
        this.initListeners()
      } else {
        this.deinitListeners()
      }
    })
    this.props.onCreate(this)
  }

  initListeners = () => {
    this.deinitListeners()
    this.statusSub = this.props.me.observeOpenAIStatus().subscribe(openAIStatus => {
      this.setState({
        openAIStatus
      })
    })
    this.creditsSub = this.props.me.observeCredits().subscribe(data => {
      let { used, purchased } = data
      if (!used) used = 0
      if (!purchased) purchased = 0
      this.setState({
        wordsUsed: used * 100
      })
    })
    this.purchasesSub = this.props.me.observePurchases().subscribe(change => {
      const { type, purchase } = change
      if (type === 'removed') {
        delete this.purchases[purchase.id]
      } else {
        this.purchases[purchase.id] = purchase
      }
      this.updatePurchasesLater()
    })
    this.vendorsSub = this.props.me.observeVendors().subscribe(change => {
      const { type, vendor } = change
      if (type === 'removed') {
        delete this.vendors[vendor.id]
      } else {
        this.vendors[vendor.id] = vendor
      }
      this.updateVendorsLater()
    })
    this.pricesSub = this.props.me.observePrices().subscribe(change => {
      const { type, price } = change
      if (type === 'removed') {
        delete this.prices[price.id]
      } else {
        this.prices[price.id] = price
      }
      this.updatePricesLater()
    })
    this.modelsSub = this.props.me.observeModels().subscribe(change => {
      const { type, model } = change
      model.title = model.name // bw compatibilty for now
      if (type === 'removed') {
        delete this.models[model.id]
      } else {
        this.models[model.id] = model
      }
      this.updateModelsLater()
    })
    this.redirSubj = this.props.me.observeRedirect().subscribe(redirect => {
      this.buy()
      if (redirect.error) {
        debugger
      }
    })
  }

  componentWillUnmount() {
    this.deinitListeners()
    this.selfSub.unsubscribe()
    this.selfSub = null
  }

  deinitListeners() {
    this.purchases = {}
    this.state.wordsPurchased = 0
    this.state.wordsUsed = 0
    if (this.statusSub) this.statusSub.unsubscribe()
    if (this.purchasesSub) this.purchasesSub.unsubscribe()
    if (this.vendorsSub) this.vendorsSub.unsubscribe()
    if (this.modelsSub) this.modelsSub.unsubscribe()
    if (this.pricesSub) this.pricesSub.unsubscribe()
    if (this.creditsSub) this.creditsSub.unsubscribe()
    if (this.redirSub) this.redirSub.unsubscribe()
    this.statusSubj = null
    this.purchasesSub = null
    this.vendorsSub = null
    this.modelsSub = null
    this.pricesSub = null
    this.creditsSub = null
    this.state.wordsPurchased = 0
    this.state.wordsUsed = 0
  }

  onNotEnoughTokens = () => {
  }
               
  buttons = {}

  updateButtonsLater = () => {
    clearTimeout(this.buttonUpdater)
    this.buttonUpdater = setTimeout(this.updateButtonsNow, 200)
  }

  updateButtonsNow = () => {
    this.setState({
      buttons: Object.values(this.buttons)
    })
  }

  writeButtons = {}
  
  updateWritingButtonsLater = () => {
    clearTimeout(this.writingButtonUpdater)
    this.writingbuttonUpdater = setTimeout(this.updateWritingButtonsNow, 200)
  }

  updateWritingButtonsNow = () => {
    this.setState({
      writeButtons: Object.values(this.writeButtons)
    })
  }
}
