import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import mobiscroll from "@mobiscroll/react"
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css"
import moment from 'moment';
import 'moment-duration-format'
import './Usage.css'

const ViewSelector = props => {
  const { selection, select, views } = props
  const choices = [
    {
      type: 'recent',
      label: 'Recent'
    },
    {
      type: 'day',
      label: 'Day'
    },
    {
      type: 'week',
      label: 'Week'
    },
    {
      type: 'month',
      label: 'Month'
    },
  ].filter(x => !views || views.find(y => y == x.type))
  console.log('viewSelector', views)
  let style
  if (views && views.length === 1) {
    style = {
      display: 'none'
    }
  }
  return <div className='usageCalendarViewSelector' style={style}>
           {
             choices.map(choice => {
               let className = 'usageCalendarViewChoice'
               const selected = choice.type === selection
               if (selected) {
                 className += ' usageCalendarViewChoiceSelected'
               }
               const choose = () => {
                 select(choice.type)
               }
               return <div className={className} onClick={choose}>
                 {choice.label}
               </div>
             })
           }
         </div>
}

const ONE_DAY = 24 * 60 * 60 * 1000

export const capitalize = n => n.substring(0, 1).toUpperCase() + n.substring(1)
export const getTime = (date, time) => {
  const dateTime = new Date(date);
  dateTime.setHours(time.getHours());
  dateTime.setMinutes(time.getMinutes());
  return dateTime.getTime();
}

function startOfCycle(date) {
  const week = startOfWeek(date)
  const month = startOfMonth(date)
  return Math.min(week, month)
}

export function startOfDay(date)  {
  return moment(date).local().startOf("day").toDate()
}
export function startOfWeek(date)  {
  return moment(date).local().startOf("isoweek").toDate()
}
export function startOfMonth(date)  {
  return moment(date).local().startOf("month").toDate()
}
function startOfYear(date)  {
  return moment(date).local().startOf("year").toDate()
}
function endOfWeek(date)  {
  return moment(date).local().endOf("isoweek").toDate()
}
function endOfMonth(date)  {
  return moment(date).local().endOf("month").toDate()
}
export function endOfDay(date)  {
  return moment(date).local().endOf("day").toDate()
}
function endOfYear(date)  {
  return moment(date).local().endOf("year").toDate()
}


export class Calendar extends Component {

  constructor(props) {
    super(props)
    console.log("Calendar", props)
    const view = this.props.initialView || (this.props.viewSelection && this.props.viewSelection[0]) || 'month'
    this.state = {
      currentDate: this.props.initialDate || new Date(),
      view,
      calView: this.assignView(view)
    }
  }

  assignView = value => {
    var view
    var calView
    switch (value) {
      case 'recent':
        break
      case 'month':
        view = {
          calendar: { type: 'month' },
          eventList: { type: 'month', scrollable: true }
        };
        break;
      case 'week':
        view = {
          calendar: { type: 'week' },
          eventList: { type: 'week', scrollable: true }
        };
        break;
      case 'day':
        view = {
          eventList: { type: 'day', scrollable: true }
        };
        break
      case 'year':
        view = {
          calendar: { type: 'year' },
          eventList: { type: 'year', scrollable: true }
        };
        break;
    }
    return view
  }

  selectView = value => {
    const view = this.assignView(value)
    const updates = {
      view: value
    }
    if (view) {
      updates.calView = view
    }
    this.setState(updates)
    this.props.onViewChange(value)
  }

  setRef = ref => {
    this.cal = ref
  }

  onDayChange = (event, inst, noClick) => {
    console.log("onDayChange", event)
    const currentDate = new Date(event.date.getTime())
    this.props.onDayChange(currentDate)
    this.setState({
      currentDate 
    })
  }

  onPageChange = (event, inst) => {
    console.log("onPageChange", event, inst.getDate())
    setTimeout(() => this.onDayChange({date: event.firstDay}, inst, true))
  }

  onTap = e => {
  }
  
  render() {
    const events = this.props.events || []
    let style
    if (this.state.view === 'recent') {
      style = { display: 'none'} 
    }
    return <div className='usageCalendar'>
             <ViewSelector selection={this.state.view} select={this.selectView} views={this.props.viewSelection} />
             <div className='usageCalendarCalendar' style={style}>
             <mobiscroll.Eventcalendar
               firstDay={1}
               firstSelectDay={1}
               ref={this.setRef}
               display="inline"
               theme={"windows"}
               themeVariant={"light"}
               view={this.state.calView}
               data={events}
               onDayChange={this.onDayChange}
               onPageChange={this.onPageChange}
               onTap={this.onTap}
             />
             </div>
           </div>
  }
}

