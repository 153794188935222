import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindCallback, of, concat, from, Subject, merge as mergeN } from 'rxjs'
import { ReactSVG } from 'react-svg'
import { makeTextPlain, walkDOM, Keyboard, parseCode, renderCode, KeyboardAutocomplete, Document, InputControl, KeyboardButton, KeyboardButton1, KeyboardTitle } from '../Keyboard'
import { BnPage, BnSubpage } from '../Page'
import { UIButton } from '../chat/components/Button'
import { UIOKCancel } from '../chat/components/OKCancel'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { delay, countTokens } from '../../classes/Util.js'
import AICheck from '../../assets/Icons/AICheck.svg'
import Spin from '../../assets/Icons/Spin.svg'
import Cross from '../../assets/Icons/Cross_1.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Stop from '../../assets/Icons/Stop.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import MenuDown from '../../assets/Icons/MenuDown.svg'
import Category from '../../assets/Icons/UserSaid.svg'
import Image from '../../assets/Icons/Image.svg'
import Undo from '../../assets/Icons/Redo.svg'
import Redo from '../../assets/Icons/Undo.svg'
import Mic from '../../assets/Icons/Mic_1.svg'
import Send from '../../assets/Icons/Send.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Share from '../../assets/Icons/Share.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import AISaid from '../../assets/Icons/AISaid.svg'
import OpenAI from '../../assets/Icons/OpenAI.svg'
import Anthropic from '../../assets/Icons/Anthropic.svg'
import Mistral from '../../assets/Icons/MistralLogo.svg'
import Google from '../../assets/Icons/Google.svg'
import Gemini from '../../assets/Icons/Models/Gemini.svg'
import Databricks from '../../assets/Icons/Databricks.svg'
import Microsoft from '../../assets/Icons/Microsoft.svg'
import Cohere from '../../assets/Icons/Cohere.svg'
import DeepSeek from '../../assets/Icons/Deepseek.svg'
import Yi from '../../assets/Icons/Yi.svg'
import Alibaba from '../../assets/Icons/Alibaba.svg'
import Nvidia from '../../assets/Icons/Nvidia.svg'
import Reka from '../../assets/Icons/Reka.svg'
import Meta from '../../assets/Icons/Meta.svg'
import MetaAI from '../../assets/Icons/Models/MetaAI.svg'
import Claude from '../../assets/Icons/Models/Claude.svg'
import Speaker from '../../assets/Icons/Speaker.svg'
import CheckMark from '../../assets/Icons/Tick.svg'
import EditIcon from '../../assets/Icons/UserSaid.svg'
import Plus from '../../assets/basketball/Plus.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import Alert from '../../assets/Icons/Alert.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import Marker from '../../assets/Icons/Marker.svg'
import Question from '../../assets/Icons/Question.svg'
import moment from 'moment'
import { langs } from '../../classes/Lang.js'
import {SpectrumAnalyzer} from '../SpectrumAnalyzer'
import ClickAwayListener from 'react-click-away-listener'
import Markdown0 from 'markdown-to-jsx'
import Markdown1 from "react-markdown"
import { Markdown, containsLatex, containsMarkdown } from './Markdown.js'
import { getPortal } from '../Client'
import { useSwipeable } from 'react-swipeable'
import { parseTable, renderTable, renderTableMarkdown } from '../Keyboard/Table.js'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {materialLight as CodeStyle} from 'react-syntax-highlighter/dist/esm/styles/prism'
import { markdownToTxt } from './MarkdownToTxt.js'
import { jsonrepair } from 'jsonrepair'
import Popup from 'reactjs-popup';
import TurndownService from 'turndown'
import Settings from '../../assets/Icons/Settings.svg'
import { Slider } from './Slider.js'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, Mousewheel } from 'swiper/modules';
import { Dots } from '../Dots'
import { Calendar } from '../Home/Usage.js'
import { ModelsMenu, ModelsView } from './ModelsMenu.js'
import { resolveModelId } from '../Home'
import { startOfDay, startOfWeek, endOfWeek, startOfMonth, endOfDay} from '../../classes/Util.js'
import 'swiper/css'; // basic Swiper styles
import 'swiper/css/navigation'; // if you need navigation buttons
import './index.css'
import './text.css'


class JudgementComp extends Component {
  constructor (props) {
    super(props)
  }

  componentDidUpdate() {
    this.updateSwiper()
  }

  componentDidMount() {
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  swiper = null
  onSwiper = x => {
    this.swiper = x
  }

  updateSwiper = () => {
    if (this.swiper) this.swiper.updateAutoHeight();
  }

  selectJudge = model => {
    console.log("selectJudge", model)
    const judgements = this.getJudgements()
    for (let i = 0; i< judgements.length; i++) {
      if (judgements[i].judge === model.id) {
        this.swiper.slideTo(i)
        console.log("selectJudge", i, model.name)
        this.forceUpdate()        
        return
      }
    }
    
  }

  onSlideChange = (e) => {
    console.log("judge onSlideChange", e)
    this.props.onSlideChange()
  }

  getPos = () => this.swiper ? this.swiper.activeIndex : 0
  setPos = pos => this.swiper && this.swiper.slideTo(pos)


  getJudgements = () => {
    let { judge, ratings, judgements } = this.props.message.judgement
    if (!judgements) {
      judgements = [{
        judge,
        ratings
      }]
    }
    const sortedJudgements = [].concat(judgements)
    sortedJudgements.sort((x, y) => {
      const a = this.props.resolveModel(x.judge)
      const b = this.props.resolveModel(y.judge)
      return a.name.localeCompare(b.name)
    })
    return sortedJudgements
  }
  
  render() {
    let judgeViews = []
    const judgements = this.getJudgements()
    const { model } = this.props
    for (const j of judgements) {
      const { judge, ratings } = j
      const judgement = ratings.find(x => x.model === model)
      if (!judgement) {
        console.error("rating not found", judge, model)
      } else {
        const { rating, review, reward, claims, susClaims } = judgement
        let score
        if (reward) {
          score = reward.score
        }
        const judgeModel = this.props.resolveModel(judge)
        const icon = (judgeModel.getModelIcon && judgeModel.getModelIcon()) || judgeModel.getIcon()
        const judgeView = <div className='modelJudgementInline'>
                            <div className='modelJudgementHeader'>
                              <div className='modelJudgementHeaderLeft'>
                                <SimpleIcon src={icon}/>
                                <div className='modelTitle'>{judgeModel.name}</div>
                              </div>
                              <div className='modelJudgementHeaderRight'>
                                {`Rating: ${rating}/10`}
                              </div>
                            </div>
                            <div className='modelJudgementText'>
                              {review}
                              {susClaims && susClaims.length > 0 && <div className='claims'>
                                                                      Corrections:<br/>
                                                                      {
                                                                        susClaims.map((x, i) => {
                                                                          const { claim, sus } = x
                                                                          return <div className='claim'>
                                                                                 <div className='claimClaim'>{i+1}. {claim}<br/>{sus}</div></div>
                                                                        })
                                                                      }
                                                                    </div>
                              }
                            </div>
                          </div>
        judgeViews.push(judgeView)
      }
    }
    const handleTouchStart = e => {
      if (this.props.onTouchStart) this.props.onTouchStart(e)
    }
    const handleTouchEnd = e => {
      if (this.props.onTouchEnd) this.props.onTouchEnd(e)
    }
    return <div className='judgementSwiper'>
             <Swiper
               onSwiper={this.onSwiper}
               nested={true}
               modules={[Mousewheel]}
               allowTouchMove={!isDesktop()}
               onSlideChange={this.onSlideChange}
               onTouchStart={handleTouchStart}
               onTouchEnd={handleTouchEnd}
               mousewheel={isDesktop() ? { forceToAxis: true, releaseOnEdges: true  } : undefined}
             >
               {
                 judgeViews.map(v => {
                   return <SwiperSlide>{v}</SwiperSlide>
                 })
               }
             </Swiper>
             <Dots
               margin={8}
               length={judgeViews.length}
               position={this.getPos()} positionChangeListener={this.setPos}
               clickable={true}
             />
           </div>
  }
}


class ModelSelection extends Component {
  render() {
    return <div className='modelSelection'>
             <Swiper
               nested={true}
               modules={[Mousewheel]}
               allowTouchMove={!isDesktop()}
               mousewheel={isDesktop() ? { forceToAxis: true, releaseOnEdges: true  } : undefined}
               spaceBetween={1}
               slidesPerView={'auto'}>
             {
               this.props.models.map(x => {
                 
                 const { model, selected } = x
                 const icon = (model.getModelIcon && model.getModelIcon()) || model.getIcon()
                 const action = async () => {
                   await this.props.action(model)
                 }
                 let className = 'modelSelectionModel'
                 if (selected) {
                   className += ' modelSelectionModelSelected'
                 }
                 return <SwiperSlide>
                   <div className={className}>
                     <SimpleButton key={model.id} icon={icon} label={model.title} action={action}/>
                   </div>
                 </SwiperSlide>
               })
             }
             </Swiper>
           </div>
  }
}

class Popup1 extends Component {

  setRef = ref => {
    this.ref = ref
  }
  
  renderPopup = () => {
    return ReactDOM.createPortal(this.props.popup(), getPortal())
  }
  
  render() {
    return <div className='popup1' ref={this.setRef}>
             {this.props.children}
             {this.renderPopup()}
           </div>
  }
  
}


export const formatPrice = (price, N = 2) => {
  // Convert the price to a string and split at the decimal point
  let priceStr = price.toString();
  let [wholePart, decimalPart] = priceStr.split('.');
  
  if (!decimalPart) {
    return wholePart 
  }

  // Count leading zeros in the decimal part
  let leadingZeros = decimalPart.match(/^0*/)[0].length;
  
  // Calculate the total number of decimal places to keep
  let totalDecimals = leadingZeros + N;
  
  // Format the price with the calculated number of decimals
  let formattedPrice = price.toFixed(totalDecimals);
  
  // Remove trailing zeros
  formattedPrice = parseFloat(formattedPrice).toString();
  
  return formattedPrice;
}


const turndownService = new TurndownService()

class MessageBody extends Component {

  constructor(props) {
    super(props)
    this.state = {
      transition: false
    }
    this.read = {}
  }

  onSwiper = swiper => {
    this.swiper = swiper
  }

  selectReply = i => {
    if (this.swiper.activeIndex !== i) {
      this.swiper.slideTo(i)
    } else {
      this.onSlideChange()
    }
  }


  selectModel = model => {
    if (!this.swiper) {
      return
    }
    const replies = this.getReplies()
    let index = 0
    for (const reply of replies) {
      if (model.isModel(reply.model)) {
        this.swiper.slideTo(index)
        break
      }
      index++
    }
  }

  updateSwiper = () => {
    const { swiper } = this
    if (swiper) swiper.updateAutoHeight();
  }

  componentDidUpdate(prevProps) {
    this.updateSwiper()
  }

  componentDidMount() {
    ////console.log("MOUNTED Message Body", this.props.msg.message.id, this.props.key1 )
    this.updateSwiper()
    this.props.onCreate(this)
  }

  componentWillUnmount() {
  }

  onSlideChangeTransitionStart = e => {
    ////console.log("slide transition start")
    this.state.transition = true
    this.props.onSlideTransitionStart()
  }

  onSlideChangeTransitionEnd = e => {
    this.state.transition = false
    //console.log("****slide transition end scroll into view")
    this.props.onSlideTransitionEnd()
  }

  onSlideChange = e => {
    this.state.transition = false
    const activeIndex = this.swiper.activeIndex
    this.setState({
      activeIndex
    })
    if (this.props.onSelectReply) this.props.onSelectReply(activeIndex, this.read[activeIndex])
    this.read[activeIndex] = true
  }

  judges = {}
  setJudge = (model, x) => {
    if (x) {
      this.judges[model] = x
    } else {
      delete this.judges[model]
    }
  }

  selectJudgeIndex = judge => {
    for (const model in this.judges) {
      this.judges[model].selectJudge(judge)
    }
  }

  setRef = ref => {
    this.ref = ref
  }

  onJudgementStart = e => {
    this.swiper.disable()
  }

  onJudgementEnd = e => {
    this.swiper.enable()
  }

  onJudgementSlideChange = e => {
    this.forceUpdate()
  }

  getReplies = () => {
    const { message } = this.props
    let replies = []
    if (message.content.trim()) {
      replies.push(message)
    }
    replies = replies.concat(message.models || [])
    const { inReplyTo } = this.props
    const modelIds = inReplyTo.models
    if (modelIds) {
      replies = modelIds.map(id => {
        let reply = replies.find(x => x.model ===id)
        if (!reply) {
          reply = {
            model: id,
            content: ''
          }
        }
        return reply
      })
    }
    replies.sort((x, y) => {
      const a = this.props.resolveModel(x.model)
      const b = this.props.resolveModel(y.model)
      if (!a) return b
      if (!b) return a
      return a.title.localeCompare(b.title)
    })
    return replies
  }

  render() {
    const replies = this.getReplies()
    //console.log("REPLIES", replies)
    const selection = this.props.selection
    let className = 'keyboardEditDocument'
    const bodies = replies.map((message, i) => {
      let {model, content}  = message
      if (!content) {
        //content = "Model did not respond."
        content = ''
      }
      let judgement
      console.log('judging', this.props.judging, "judgement", this.props.message.judgement)
      if (this.props.judging && this.props.message.judgement) {
        judgement = <JudgementComp key={this.props.message.id}
                                   onSlideChange={this.onJudgementSlideChange}                                   
                                   onTouchStart={this.onJudgementStart}
                                   onTouchEnd={this.onJudgementEnd}
                                   onCreate={(judge) => this.setJudge(model, judge)}
                                   onSelectModel={this.selectModel}
                                   model={model}
                                   resolveModel={this.props.resolveModel}
                                   message={this.props.message}
                    />
      }
      const body = this.props.renderBody(model, content, judgement)
      return body
    })
    if (bodies.length === 1) {
      return <div className='singleMsgBody'>{bodies[0]}</div>
    }
    const render1Body = (body, i) => {
      const reply = replies[i]
      return <SwiperSlide key={'swiper-'+reply.model}>
               {body}
             </SwiperSlide>
    }
    /*
               modules={[Navigation, Pagination, Mousewheel]}
               navigation
               pagination
               mousewheel
    */

    
    return <div key={'body-'+this.props.key1} className='messageBodySwiperContainer' ref={this.setRef}>
             <Swiper
               nested={true}
               modules={[Mousewheel]}
               allowTouchMove={!isDesktop()}
               mousewheel={isDesktop() ? { forceToAxis: true, thresholdDelta: 6, releaseOnEdges: true  } : undefined}
               onSwiper={this.onSwiper}
               onSlideChange={this.onSlideChange}
               onSlideChangeTransitionStart={this.onSlideChangeTransitionStart}
               onSlideChangeTransitionEnd={this.onSlideChangeTransitionEnd}
               autoHeight={this.props.autoHeight}>
               {
                 bodies.map((body, i) => render1Body(body, i))
               }
             </Swiper>
             <div className='messageBodyDotsContainer'>
               {true &&<Dots margin={8} length={bodies.length} position={this.state.activeIndex} positionChangeListener={position => {
                               this.swiper.slideTo(position)
                             }} clickable={true}/>}
             </div>
           </div>
  }
}

class AISaidComp extends Component {
  constructor(props) {
    super(props)
    this.state = {activeIndex: 0}
  }

  onSelectReply = (index)  => {
    this.state.activeIndex = index
    if (this.props.onSelectReply) this.props.onSelectReply(index)
    this.forceUpdate()
  }


  componentDidMount() {
    this.props.onCreate(this)
  }

  componentWillUnmount() {
    this.props.onDelete(this)
  }

  setBody = body => {
    this.messageBody = body
  }

  selectModel = index => {
    this.messageBody.selectModel(index)
  }

  selectJudgeIndex = index => {
    this.messageBody.selectJudgeIndex(index)
  }

  setEditable = ref => {
    this.editable = ref
  }
    
  render() {
    const message = this.props.message
    let replies = []
    if (message.content.trim()) {
      replies.push(message)
    }
    replies = replies.concat(message.models || [])
    let selection = this.state.activeIndex
    const components = this.props.getComponents(message)
    const opts = this.props.opts
    const { isLast } = opts
    let retry = this.props.retry
    const pause = this.props.pause
    let del
    const visibleReply = replies[selection]
    ////console.log("visibleReply", visibleReply && visibleReply.uid, this.props.me.self.uid)
    let action3
    let icon3
    let clazz3
    let { role, content, code, serverError, isStreaming } = message
    let text = content
    const renderBody = (model, text, judgement) => {
      const title = this.props.getMessageTopic(message)
      let icon2 = Copy
      let className = 'keyboardEditDocument'
      const icon1 = this.props.getModelIcon(model)
      let leftIconClassName = 'chatGPTLeftIcon copyAISaid'
      const topic = this.props.getMessageTopic({model})
      let copy
      if (this.props.copy) {
        copy = async () => {
          return await this.props.copy(text)
        }
      }
      return <div className='horizontalTextLayout'>
               <div className='leftColumn1'>
                 <div className='keyboardEditInstructionLeftIcon'>
                   <ReactSVG src={icon1}/>
                 </div>
               </div>
               <div className='middleColumn1'>
                 <div className='chatGptChatMessageHeaderTopic2'>{topic}</div>
                 {judgement}
                 <Markdown components={components}>{text}</Markdown>
              </div>
               <div className='rightColumn1 copyAISaid'>
                 {copy && <KeyboardButton1 key={'copy'} icon={Copy} action={copy}/>}
               </div>
             </div>
    }
    
    const renderBody1 = () => {
      let autoHeight = replies.length > 1 && replies[selection] && replies[selection].id !== this.streamingId
      return <MessageBody
               onCreate={this.setBody}
               selectModel={this.props.selectModel}
               onSlideTransitionStart={this.props.onSlideTransitionStart}
               onSlideTransitionEnd={this.props.onSlideTransitionEnd}
               getModelIcon={this.props.getModelIcon}
               resolveModel={this.props.resolveModel}
               getMessageTopic={this.props.getMessageTopic}
               selection={selection}
               onSelectReply={this.onSelectReply}
               message={message}
               judging={this.props.judging}
               inReplyTo={this.props.inReplyTo}
               autoHeight={true}
               renderBody={renderBody}/>
    }
    let leftIconClassName = 'chatGPTLeftIcon copyAISaid'
    let edit
    if (this.props.editingTask && message.replies.length === 0) {
      edit = async () => {
        let editing = this.state.editing
        if (editing) {
          //debugger
          const replacement = this.editable.textContent
          if (replacement !== message.content) {
            message.content = replacement
            this.props.saveMessageContent(message)
          } else {
            await delay(0.5)
          }
        } else {
          await delay(0.2)
        }
        editing = editing ? null: message
        this.setState({
          editing
        }, () => {
          if (editing) {
            this.editable.focus()
          }
        })
      }
    }
    let contentEditable
    if (this.state.editing) {
      contentEditable = this.state.editing.id === replies[0].id
    }
    let className = 'horizontalMessageLayout'
    let label = 'Judge'
    let { judgement } = message
    if (false && judgement) {
      let { ratings } = judgement
      ratings.sort((x, y) => y.rating - x.rating)
      judgement = ratings.map((x, i) => {
        let { model, rating, review } = x
        const name = this.props.getMessageTopic({model})
        const icon = this.props.getModelIcon(model)
        const onClick = () => this.selectModel(model)
        return <div className='modelJudgement' onClick={onClick}>
                 <div className='modelJudgementHeader'>
                   <div className='modelJudgementHeaderLeft'>
                     <SimpleIcon src={icon}/>
                     <div className='modelTitle'>{name}</div>
                   </div>
                   <div className='modelJusgementHeaderRight'>
                     Rating: {rating}/10
                   </div>
                 </div>
                 <div className='modelJudgementText'>
                   {review}
                 </div>
               </div> 
      })
    }
    let style = { display: 'none' }
    if (judgement ||( message.models || []).length + (message.content ? 1 : 0) > 1) {
      style = null
    }
    return <div key={'reply-'+message.id} className={className}>
             {
               false && judgement && judgement.type === 'factual' && <div className='aiComment aiJudgement'>
                                                                               <Markdown components={components}  >{judgement.correctAnswer}</Markdown>
                            </div>
             }
             {renderBody1()}
           </div>
  }
  
}

export const SimpleIcon = props => {
  const { src } = props
  return <div className= 'simpleIcon'><ReactSVG src={src}/></div>
}

export class SimpleButton extends Component {
  setRef = ref => {
    this.ref = ref
  }
  render() {
    const props = this.props
    const { icon, label  } = props
    const action = async () => {
      this.ref.scrollIntoView()
      await props.action()
    }
    return <div ref={this.setRef} className='simpleButton' onClick={action}>
             <SimpleIcon src={icon}/>
             <div className='simpleButtonLabel'>
               {label}
             </div>
           </div>

  }
}

export const Checkbox = props => {
  const { icon, selected, toggle, label } = props
  let className = 'simpleCheckbox'
  if (icon) {
    className += ' simpleCheckboxWithIcon'
  }
  if (selected) {
    className += ' simpleCheckboxSelected'
  }
  if (props.className) {
    className += ' ' + props.className
  }
  return <div className={className} onClick={toggle}>
           {icon && <div className='simpleCheckboxIcon'><ReactSVG src={icon}/></div>}
           {label}
           </div>
}


class MarkdownBody extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.updateBody()
  }

  updateBody = () => {
    if (true) return
    const ref = this.ref
    // hack for markdown rendering multiple text elements in sequence without line breaks
    if (ref) {
      const element = ref
      const isNested = n => {
        let result = false
        walkDOM(n, x => {
          if (n !== x && x.nodeName === 'LI') {
            result = true
          }
        })
        return result
      }
      if (true) {
        const lis = element.querySelectorAll('li')
        for (const li of lis) {
          const nested = isNested(li)
          //console.log("li height", li.offsetHeight, 'nested', nested, li.textContent)
          if (li.offsetHeight > 20 && !nested) {
            if (li.firstChild.nodeName !== 'DIV' && li.firstChild.nodeType !== Node.TEXT_NODE || !li.firstChild.textContent.trim()) {
              const wrapper = document.createElement('div');
              wrapper.className = 'liContainer ' + 'liContainer' + li.parentNode.nodeName
              // Move all children of the li into the new div
              while (li.firstChild) {
                wrapper.appendChild(li.firstChild);
              }
              // Append the new div to the li
              li.appendChild(wrapper);
            }
          }
        }
      }
      element.childNodes.forEach((node, i) => {
        if (i > 0 && node.previousSibling &&
            node.previousSibling.nodeType === Node.TEXT_NODE &&
            node.nodeType === Node.TEXT_NODE) {
            if (node.textContent === '\n') {
              const br1 = document.createElement('br')
              const br2 = document.createElement('br')
              const inserted = node.parentNode.insertBefore(br1, node)
              inserted.parentNode.insertBefore(br2, inserted)
              node.textContent = ""
            }
        }
      })
    }
  }

  setBodyRef = ref => {
    this.ref = ref
  }

  render() {
    return <div className='keyboardEditDocumentTextInline' ref={this.setBodyRef}>
             {this.props.children}
           </div>
  }
}



function escapeUnbalancedDollars(text) {
  let dollarsIndices = [];
  for (let i = 0; i < text.length; i++) {
    if (text[i] === '$') dollarsIndices.push(i);
  }

  // Convert the string to an array to allow modifications
  let escapedTextArray = [...text];
  let skipNext = false;
  
  for (let i = 0; i < dollarsIndices.length - 1; i++) {
    if (skipNext) {
      skipNext = false;
      continue;
    }

    let currentIdx = dollarsIndices[i];
    let nextIdx = dollarsIndices[i + 1];

    // Simple rule: if there's any character other than space between the `$` pair, consider it math
    if (nextIdx - currentIdx > 1 && !text.substring(currentIdx + 1, nextIdx).trim().length === 0) {
      // This pair is considered a valid math expression, so skip the next `$`
      skipNext = true;
    } else {
      // Escape the current `$` since it doesn't form a valid math expression
      escapedTextArray[currentIdx] = '\\$';
    }
  }

  // If the last `$` wasn't skipped (and exists), it's unpaired and should be escaped
  if (!skipNext && dollarsIndices.length > 0) {
    escapedTextArray[dollarsIndices[dollarsIndices.length - 1]] = '\\$';
  }

  return escapedTextArray.join('');
}




const replaceImgWithMarkdown = (htmlString) => {
  if (htmlString.indexOf('<img') >= 0) {
    ////////debugger
    const regex = /<img .*\/?>/g
    return htmlString.replace(regex, (match) => {
      ////console.log("match", match)
      return turndownService.turndown(match)
    });
  }
  return htmlString
}


function fromNow(date) {
  const fromNow = moment(date).fromNow().replace(/ ago/, '')
  const fixed = fromNow
        .replace(/.*a few seconds.*/, 'now')
        .replace(/.*a minute.*/, '1m')
        .replace(/.*an hour.*/, '1h')
        .replace(/.*a day.*/, '1d')
        .replace(/.*a month.*/, '1mo')
        .replace(/.*a year.*/, '1y')
        .replace(/ minutes?/, 'm')
        .replace(/ hours?/, 'h')
        .replace(/ days?/, 'd')
        .replace(/ months?/, 'mo')
        .replace(/ years?/, 'y')
  //////console.log('fromNow', fromNow, '=>', fixed)
  return fixed
}

//

const JSON_parse = input => {
  try {
    return JSON.parse(jsonrepair(input))
  } catch (err) {
    return eval(input)
  }
}

const nbsp = new RegExp(String.fromCharCode(160), "gi");

const USE_GPT4 = new URLSearchParams(window.location.search).get('gpt-4')


export class FineTuningTask extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  renderField = (name, content) => {
    return <div className='dialogField'>
             <div className='dialogFieldName'>{name}</div>
             <div className='dialogFieldValue'>{content}</div>
           </div>
  }
  render() {
    const selectDataset = (selection) => {
    }
    const models = <ModelsMenu models={this.props.models} visible={true}/>
    const datasetMenu =  true ? null: <Threads
                           me={this.props.me}
                           slide={0}
                           checkScrollBack={this.props.getDatasetsHistory}
                           selectThread={selectDataset}
                           threads={this.props.datasets}
                         />

    return <div className='newFineTune' onClick={this.props.onClick}>
             {this.renderField("Model", models)}
             {false && this.renderField("Data Set", datasetMenu)}
           </div>
  }
}

export class Model extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }
  }
  render() {
    const { model, single } = this.props
    let { id, title, label, select, isSelected, getIcon, vendor, getModelIcon, vision } = model
    label = title
    const { busy } = this.state
    let iconStyle
    let icon = getModelIcon ? getModelIcon() : null
    if (single) {
      icon = icon || model.getIcon()
    } else {
      iconStyle = getModelIcon && getModelIcon() ? null : { visibility: 'hidden', maxWidth: 15 }
    }
    let className= 'model'
    if (isSelected()) className += '  modelRadioButtonSelected'
    if (!single) {
      className += ' modelSubmenu'
    }
    const format = this.props.formatPrice || formatPrice
    let price = ''
    const { input, output } = this.props.getPrice(model)
    price = "$" + format(input) + "/" + format(output)
    return <div className={className} onClick={select}>
             <div className='modelLeft'>
               <div className='modelIcon' style={iconStyle}>
                 <ReactSVG src={icon}/>
               </div>
               {single && <div className='modelLabel modelVendorLabel'>{vendor}</div>}
               <div className='modelLabel'>
                 {label}
               </div>
             </div>
             <div className='modelPrice'>
               {price}
             </div>
             <div className='modelVision'>
               {vision && <ReactSVG src={Image}/>}
             </div>
             <div className='modelRight'>
               {isSelected() && <div className='modelRadioButtonRight'><ReactSVG src={CheckMark}/></div>}
             </div>
           </div>
  }
}

export class ModelVendor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: this.props.open
    }
  }
  renderModels() {
    return this.props.models.map(model => {
      return <Model key={model.id} me={this.props.me} model={model} formatPrice={this.props.formatPrice} getPrice={this.props.getPrice}/>
    })
  }
  toggleMenu = () => {
    this.setState({
      open: !this.state.open
    })
  }
  render() {
    const open = this.state.open || this.props.open
    const { models } = this.props
    const format = this.props.formatPrice || formatPrice
    let selectionCount = models.reduce((a, x) => a + (!x.isSelected || x.isSelected() ? 1 : 0), 0)
    let isSelected = selectionCount > 0
    if (models.length === 1) {
      return <Model key={models[0].id} me={this.props.me} model={models[0]} single={true} getPrice={this.props.getPrice} formatPrice={this.props.formatPrice}/>
    }
    let style
    if (models.length === 0) {
      style = {display: 'none'}
    }
    let price
    if (this.props.aggregatePrice) {
      const { input, output } = this.props.aggregatePrice
      price = "$" + format(input) + "/" + format(output)
    }
    return <div className={'modelCategory' + (open ? ' modelCategoryOpen' : '')} style={style}>
             <div className={'modelCategoryMenu' + (isSelected ? ' modelRadioButtonSelected' : '')} onClick={this.toggleMenu}>
               <div className='modelCategoryLeft'>
                 <div className='modelIcon'>
                   <ReactSVG src={this.props.vendor.getIcon()}/>
                 </div>
                 <div className='modelLabel modelVendorLabel'>
                   {this.props.vendor.name}
                 </div>
               </div>
               <div className='modelCategoryRight'>
                 {price && <div className='modelPrice'>{price}</div>}
                 {selectionCount > 0 && <div className='modelVendorSelectionCountContainer'><div className='modelVendorSelectionCount'>{selectionCount}</div><div className='modelVendorCheck'><ReactSVG src={CheckMark}/></div></div>}
                 <div className='modelIcon'>
                   <ReactSVG src={open ? Left : Right}/>
                 </div>
               </div>
             </div>
             {open && <div className='modelCategoryButtons'>
                         {this.renderModels()}
                       </div>}
           </div>
  }
}



export class RadioButtons extends Component {
  render() {
    const isSmall = this.props.small
    const buttons = this.props.buttons.map(button => {
      const { selected } = button
      if (selected) {
        return <div className='keyboardRadioButtonSelected' onClick={button.select}>
                 <div className='keyboardRadioButtonIcon'><ReactSVG src={button.icon}/></div>
                 <div className='keyboardRadioButtonOn'>{button.label}</div>
               </div>
        
      } else {
        return <div className='keyboardRadioButtonUnselected' onClick={button.select}>
                 <div className='keyboardRadioButtonIcon'><ReactSVG src={button.icon}/></div>
                 <div className='keyboardRadioButtonOff'>{button.label}</div>
               </div>
      }
    });
    return <div className={'keyboardRadioButton' +  (isSmall ? ' keyboardRadioButtonSmall' : '')}>
             {this.props.label &&<div className='keyboardRadioButtonLabel keyboardRadioButton'>
               {this.props.label}
                                 </div>}
             {buttons}
             <div className='keyboardRadioButtonRight'/>
           </div>

  }
}


class CheckboxPopup extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  renderMenu = () => {
    return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
  }

  doRenderMenu = () => {
    const closeMenu = () => {
      this.state.menuActive = false
      his.forceUpdate()
    }
    return <ClickAwayListener onClickAway={closeMenu}>    
             <RadioButtons buttons={this.props.buttons}/>
           </ClickAwayListener>
  }

  render() {
    const onClick = async () => {
      setTimeout(() =>  {
        this.setState({
          menuActive: !this.state.menuActive
        })
      }, 50)
    }
    return <div className='chatGptFunctions'>
             <div className='keyboardAddButton'>
               <KeyboardButton1 keepFocus icon={this.state.menuActive ? MenuDown : MenuUp} action={onClick}/>
             </div>
           </div>
  }
  
}


const encodeURIExt = uri => {
  if (!uri.endsWith("%3f") && !uri.endsWith("%3F")) {
    uri = encodeURI(uri)
  }
  return uri
}

const QUESTIONS = ['Does Australia really have pink lakes?',
                   'When and on what topic  was the NYT\'s first report on "artificial intelligence"?',
                   'Can spiders fly?']
const links = [`Does Australia really have [pink lakes?](ai://?q=${encodeURIComponent(QUESTIONS[0])})`,
               `When and on what topic was the NYT's [first report on "artificial intellgence"](ai://?q=${encodeURIComponent(QUESTIONS[1])})?`,
               `Can [spiders fly?](ai://?q=${encodeURIComponent(QUESTIONS[2])})`]

const generateBlurb = (pre) => {
  pre = pre || `Hello, ask me any questions you'd like. `
  return `${pre}Here are some examples to get you started:\n
${links.map((q, i) => `\n- ${q}`)}\n`.replace(/,\n/g, '\n')
}

const CodeBlock = ({className, node, children}) => {
  let lang
  const text = (typeof children == 'string' && children)  || ''
  const multiline = text.indexOf('\n') > 0
  //console.log("CodeBlock", children)
  let clazzName = 'aiCode'
  if (!multiline) {
    clazzName = 'aiCode aiCodeInline'
  }
  const copy = async () => {
    try {
      navigator.clipboard.writeText(text)
    } catch (ignored) {
      console.log(text)
    }
    await delay(0.5);
  }
  if (className && className.startsWith('lang-')) {
    lang = className.replace('lang-', '');
    return (
      <SyntaxHighlighter language={lang} style={CodeStyle} showLineNumbers={true}>
        {children}
      </SyntaxHighlighter>
    )
  } else {
    if (children && children.indexOf('%') >= 0) {
      try {
        children = decodeURIComponent(children)
      } catch (err) {
        console.error(err)
      }
    }
    return <div className={clazzName}>{children}{multiline && <div className='codeCopy'><KeyboardButton1 label='Copy' icon={Copy} action={copy}/></div>}</div>
  }
}

// markdown-to-jsx uses <pre><code/></pre> for code blocks.
const PreBlock = ({node, children, ...rest}) => {
  if ('type' in children && children ['type'] === 'code') {
    return CodeBlock(children['props']);
  }
  return <div className='aiPre' {...rest}>{children}</div>;
};

const consoleLog = (...args) => {
}

const debugLog = (...args) => {
  //args.unshift("debug")
  //////console.log.apply(null, args)
}


class CheckMarkComp extends Component {
  render( ){
    return <div className='aiCheck'><ReactSVG src={AICheck}/></div>
  }
}

const decodeURIComponentExt = c => {
  try{
    let decoded = decodeURIComponent(c)
    return decoded.replace(/[+]/g, ' ')
  } catch (exc) {
    ////////////debugger
    return c
  }
}


class Messages extends Component {
  constructor (props) {
    super(props)
  }

  componentWillUnmount() {
    document.removeEventListener("selectionchange", this.onSelectionChange)
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }

  autoScroll = true

  userScrolledUp = false
  
  componentDidMount() {
    const ref = this.scrollWindow
    this.props.onCreate(this)
    this.resizeObserver = new ResizeObserver(entries => {
      debugLog("resize", entries)
      let skip = false
      if (this.props.slide !== 0 && this.props.slide != 1) {
        skip = true
      }
      if (this.inScroll) skip = true
      if (ref.scrollHeight == 0) skip = true
      if (skip) {
        debugLog("resize skip")
      }
      this.inResize = true
      if (ref.scrollTop !== this.lastScrollTop) {
        debugLog("onresize: scrollTop", this.scrollWindow.scrollTop, "last", this.lastScrollTop)
      }
      let heightDelta = this.lastHeight - ref.scrollHeight
      if (ref.scrollHeight !== this.lastHeight) {
        debugLog("onresize: scrollHeight", this.scrollWindow.scrollHeight, "last", this.lastHeight)
        this.lastHeight = ref.scrollHeight
      }
      if (ref.clientHeight !== this.lastOffsetHeight) {
        debugLog("onresize:scroll clientHeight", ref.clientHeight, "last", this.lastOffsetHeight)
        this.lastOffsetHeight = ref.clientHeight
      }
      const newScrollTop = this.computeScrollTop()
      debugLog("new scroll top: ", newScrollTop)
      const newScrollBottom = ref.scrollHeight - ref.clientHeight - newScrollTop
      debugLog("current scroll bottom: ", this.scrollBottom, "=>", newScrollBottom)
      if (this.autoScroll || this.props.autoscroll) {
        this.scrollBottom = newScrollBottom
        //this.updateScrollTop(newScrollTop)
        this.fixupScrollTop()
      } else {
        //this.scrollBottom -= heightDelta
        //this.fixupScrollTop()
        //ref.scrollTop = this.lastScrollTop
      }
      debugLog("resize scrollTop==>", newScrollTop)
      this.inResize = false
    })
    this.scrollBottom = 0
    this.lastHeight = ref.scrollHeight
    this.lastOffsetHeight = ref.clientHeight
    ref.scrollTop = this.computeScrollTop()
    this.lastScrollTop = ref.scrollTop
    debugLog("chat mounted scroll last: ", this.lastHeight)
    const onscroll = e => {
      const scrollContainer = ref
      const isAtBottom = scrollContainer.scrollHeight - scrollContainer.clientHeight <= scrollContainer.scrollTop + 1;
      // If user scrolls up, disable autoScroll
      if (!isAtBottom && scrollContainer.scrollTop !== 0) {
        this.autoScroll = false;
        this.userScrolledUp = true;
      }

      // If user scrolls to the bottom, resume autoScroll
      if (isAtBottom && this.userScrolledUp) {
        this.autoScroll = true;
        this.userScrolledUp = false;
      }
      if (this.props.slide !== 0 && this.props.slide !== 1) {
        return
      }
      let sizeDelta = 0
      if (this.inResize) {
        return
      }
      if (this.lastOffsetHeight !== ref.clientHeight) {
        debugLog("scroll client height change: ", ref.clientHeight, "last", this.lastOffsetHeight)
        sizeDelta += ref.clientHeight - this.lastOffsetHeight
        this.lastOffsetHeight = ref.clientHeight
      }
      if (this.lastHeight !== ref.scrollHeight) {
        debugLog("scroll height change: ", ref.scrollHeight, "last", this.lastHeight)
        sizeDelta += ref.scrollHeight - this.lastHeight
        this.lastHeight = ref.scrollHeight
      }
      if (sizeDelta === 0) {
        this.scrollBottom = this.computeScrollBottom()
        this.lastScrollTop = ref.scrollTop
      } else {
        const newValue = this.computeScrollTop()
        debugLog("onscroll scrollTop==>", newValue)
        return this.updateScrollTop(newValue)
      }
      debugLog("scrolltop=>", ref.scrollTop)
      debugLog("scrollHeight=>", ref.scrollHeight)
      debugLog("scrollClient=>", ref.clientHeight)
      debugLog("scrollBottom=>", this.scrollBottom)
      this.checkScrollBack()
    }
    ref.onscroll = e => {
      this.inScroll = true
      onscroll(e)
      this.inScroll = false
    }
    this.resizeObserver.observe(this.scrollWindow)
    this.resizeObserver.observe(this.scrollContent)
    this.windowListener = window.addEventListener("resize", this.onResized)
    this.scrollToBottom()
  }

  updateScrollTop = newScrollTop => {
    //console.log("UPDATE_SCROLL_TOP_"+this.props.autoscroll, newScrollTop)
    if (this.autoScroll || this.props.autoscroll) {
      const ref = this.scrollWindow
      let tmp = ref.onscroll
      ref.onscroll = null
      ref.scrollTop = newScrollTop
      ref.onscroll = tmp
    }
  }

  pushScrollBottom = () => {
    this.savedScrollBottom = this.scrollBottom
    //console.log("pushScrollBottom: ", this.savedScrollBottom)
  }

  popScrollBottom() {
    //console.log("popScrollBottom: ", this.scrollBottom, " => ", this.savedScrollBottom)
    this.scrollBottom = this.savedScrollBottom || 0
    this.fixupScrollTop()
  }

  fixupScrollTopLater = () => {
    clearTimeout(this.fixupTimeout)
    this.fixupTimeout = setTimeout(this.fixupScrollTop, 16)
  }

  setScrollWindow = ref => {
    if (ref && ref != this.scrollWindow) {
      this.scrollWindow = ref
    }
  }

  checkScrollBack = async () => {
    const ref = this.scrollWindow
    const element = ref
    const isAtBottom = Math.floor(element.scrollHeight - element.scrollTop) === element.clientHeight;
    if (isAtBottom) {
      if (this.props.checkScrollBack) {
        this.props.checkScrollBack(false)
      }
    } else if (ref.scrollTop === 0) {
      if (this.props.checkScrollBack) {
        this.props.checkScrollBack(true)
      }
    }
  }
  
  setScrollContent = ref => {
    if (ref && ref != this.scrollContent) {
      this.scrollContent = ref
    }
  }

  computeScrollTop = () => {
    const ref = this.scrollWindow
    const result = ref.scrollHeight - this.scrollBottom - ref.clientHeight
    debugLog(`computeScrollTop ${ref.scrollHeight} - ${this.scrollBottom} - ${ref.clientHeight} = ${result}`)
    return result
  }

  computeScrollBottom = () => {
    const ref = this.scrollWindow
    const result = ref.scrollHeight - ref.clientHeight - ref.scrollTop
    debugLog(`computeScrollBottom ${ref.scrollHeight} - ${ref.clientHeight} - ${ref.scrollTop} = ${result}`)
    return result
  }
  
  fixupScrollTop = () => {
    if (this.autoScroll) {
      this.updateScrollTop(this.computeScrollTop())
    }
  }

  scrollToBottom=()=> {
    this.scrollBottom = 0
    this.fixupScrollTop()
  }

  hitBottom = () => {
    const scrollContainer = this.scrollWindow
    const isAtBottom = scrollContainer.scrollHeight - scrollContainer.clientHeight <= scrollContainer.scrollTop + 1;
    return isAtBottom
  }

  onWheel = event => {
    const ref = this.scrollWindow
    const hitBottom = this.hitBottom()
    ////console.log("WHEEL", event.deltaY, hitBottom)
    if (event.deltaY < 0) {
      this.autoScroll = false
    } else if (hitBottom) {
      this.autoScroll = true
    }
  }

  onTouchStart = event => {
    this.lastTouchY = event.touches[0].clientY;
  }

  onTouchMove = event => {
    const ref = this.scrollWindow
    // Assuming vertical touch move, detect direction
    const touchY = event.touches[0].clientY;
    const deltaY = this.lastTouchY - touchY;
    const hitBottom = this.hitBottom()
    ////console.log("MOVE", deltaY, hitBottom)    
    if (deltaY < 0) {
      this.autoScroll = false
    } else if (hitBottom) {
      this.autoScroll = true
    }
    this.lastTouchY = touchY;
  }

  render() {
    const messages = this.props.messages
    let containerClass = 'uiChatMessagesContainer'
    let marginClass = 'uiChatMarginBottom'
    if (this.props.searchFieldVisible) {
       marginClass += ' uiChatMarginBottomSearchField'
    }
    if (this.props.selectedThread) {
      marginClass += ' uiChatMarginBottomThread'
    }
    if (this.props.enableInput && !this.props.enableInput(this)) {
       marginClass += ' uiChatMarginBottomNoChat'
    }
    let topic
    if (this.props.selectedThread) {
      topic = this.props.selectedThread.topic
    }
    const onSwipeRight = (e) => {
      if (this.props.selectedThread) {
        this.props.selectThread(null)
      }
    }
    /*
      onWheel={this.onWheel}
      onTouchMove={this.onTouchMove}
      onTouchStart={this.onTouchStart}>
    */
    return <div key={this.props.key}  className={marginClass} >
             <div className={'uiChatMessages'} ref={this.setScrollWindow}>
               <div key='chatMessagesContainer' className={containerClass} ref={this.setScrollContent}>
                 {messages}
               </div>
             </div>
           </div>
   }

}

   
class Thread extends Component {
  render() {
    const thread = this.props.thread
    const onClick = () => {
      this.props.selectThread()
    }
    let className = 'keyboardMenuItem keyboardMenuItemCategory'
    let trash = this.props.trash
    let date
    date = fromNow(thread.lastUpdated)
    let blurb = ''
    let deleteButton
    let deleteIcon = thread.busy ? Spin : Trash
    let dateComp
    if (this.props.cancelDelete) {
      deleteButton = <ClickAwayListener onClickAway={this.props.cancelDelete}>
                       <div className='threadDeleteButtonConfirm' onClick={trash}>Confirm Delete<div className='keyboardMenuItemIcon'><ReactSVG src={deleteIcon}/></div></div>
                     </ClickAwayListener>
      
    } else {
      dateComp = <div className='taskDate'>{date}</div>
      deleteButton = trash && <div className='threadDeleteButton'><KeyboardButton1 keepFocus action={trash} icon={Trash}/></div>
    }
    let content
    const markdown = thread.lastUpdated && thread.messages ? thread.description : 'Empty Discussion'
    const components = null
    let { usage } = thread
    let dollars
    if (usage && usage.total) {
      dollars = '$'+formatPrice(usage.total)
    }
    content = <Markdown components={components}>{markdown}</Markdown>
    return <div key={thread.id} className='taskTitle'>
             <div  className='taskTitleLeft'>
	       <div className='keyboardMenuItemIcon'><ReactSVG src={Hashtag}/></div>
             </div>
	     <div className='keyboardMenuItemLabel taskDescriptionLabel' onClick={onClick}>{content}</div>
             <div className='keyboardMenuItemRight'>
               <div className='modelPrice'>{dollars}</div>
               {dateComp}
               {deleteButton}
             </div>
           </div>
  }
}

class Threads extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuActive: true,
      visibleThreadCount: 15
    }
  }


  setScrollRef = ref => {
    if (ref) {
      const scrollContainer = ref
      const hitBottom = () => {
        const isAtBottom = scrollContainer.scrollHeight - scrollContainer.clientHeight <= scrollContainer.scrollTop + 1;
        ////console.log({isAtBottom})
        return isAtBottom
      }
      ref.onscroll = () => {
        if (hitBottom()) {
          this.checkScroll()
        }
      }
    }
  }
  componentDidMount() {
  }

  checkScroll = () => {
    const visibleThreadCount = Math.min(this.state.visibleThreadCount + 10, this.props.threads.length)
    this.setState({
      visibleThreadCount
    })
  }
    
  renderMenu = () => {
    //return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
    return this.doRenderMenu()
  }
  
  doRenderMenu = () => {
    const closeMenu = () => {
      //this.state.menuActive = false
      //this.forceUpdate()
    }
    let menuStyle
    let active = this.state.menuActive || this.props.selectedThread
    active = !this.props.selectedThread
    menuStyle = null
    let className = 'keyboardMenuItem keyboardMenuItemCategory'
    const x = 0
    const threads = this.props.threads.slice(0, this.state.visibleThreadCount)
    return <ClickAwayListener onClickAway={closeMenu} style={menuStyle}>    
             <div className='chatGptThreadsMenu' ref={this.setScrollRef}>
               <div className='chatGptThreadMenuThreads'>
                 {
                   threads.map(thread => {
                     const selectThread = () => {
                       this.props.selectThread(thread)
                     }
                     const deleteTask = async () => {
                       await this.props.deleteTask(thread)
                     }
                     let cancelDelete
                     if (this.state.confirmDelete === thread.id) {
                       cancelDelete = async () => {
                         this.state.confirmDelete = null
                         this.forceUpdate()
                       }
                     }
                     const trash = async () => {
                       if (this.state.confirmDelete !== thread.id) {
                         this.state.confirmDelete = thread.id
                         ////console.log("confirmDelete", thread)
                       } else {
                         thread.busy = true
                         this.forceUpdate()
                         await this.props.deleteTask(thread)
                         this.state.confirmDelete = null
                         delete thread.busy
                       }
                       this.forceUpdate()
                     }
                     return <Thread key={thread.id} thread={thread} selectThread={selectThread} trash={trash} cancelDelete={cancelDelete}/>
                   })
                 }
               </div>
             </div>
           </ClickAwayListener>
  }
  

  render() {
    return this.renderMenu()
  }
}

class Questions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selection: 'test',
    }
  }
    
  renderMenu = () => {
    return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
  }

  setScrollRef = (ref) => {
    this.scrollRef = ref
    if (ref) {
      const scrollHeight = ref.scrollHeight
      const height = ref.clientHeight
      const maxScrollTop = scrollHeight - height
      ref.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
    }
  }

  doRenderMenu = () => {
    const closeMenu = (e) => {
      e.preventDefault()
      this.state.menuActive = false
      this.forceUpdate()
    }
    const selectQuestion = question => {
      this.state.menuActive = false
      this.props.selectQuestion(question)
    }
    const trainingSet =
          [{
            select: () => this.setState({selection: 'train'}),
            label: "Train",
            icon: CheckMark,
            selected: this.state.selection == 'train'
          },
           {
             select: () => this.setState({selection: 'test'}),
             label: "Test",
             icon: Question,
             selected: this.state.selection == 'test'
           }]
    return <ClickAwayListener onClickAway={closeMenu}>    
    <div ref={this.setScrollRef} className='chatGptQuestionsMenu'>
        {
            <div className='chatGptQuestionMenuQuestions'>
              {
                this.getQuestions().map(x => {
                  const { question, hallucinated, isTrain} = x
                  let selected = true
                  selected = question.selected
                  const onClick = (e) => {
                    e.preventDefault()
                    selectQuestion(question)
                  }
                  let className = 'keyboardMenuItem keyboardMenuItemCategory'
                  if (hallucinated) {
                    className += ' questionPrecision'
                  } else {
                    className += ' questionRecall'
                  }
                  let content
                  const markdown = question
                  let hasMath = containsLatex
                  const components = null
                  ////console.log("contains latex", markdown)
                  const icon = isTrain ? CheckMark : Question
                  try {
                    content = <Markdown components={components}>{markdown}</Markdown>
                  } catch (err) {
                    content = markdown
                  }
                  return <div key={question.id} className={className} onMouseDown={onClick}>
		           <div className='keyboardMenuItemIcon'><ReactSVG src={icon}/></div>
		           <div className='keyboardMenuItemLabel'>{content}</div>
                         </div>
                  
                })
              }
            </div>
        }
      <div className='trainToggle'><RadioButtons buttons={trainingSet}/></div>}
    </div>
    </ClickAwayListener>
  }

  renderAutocompletes() {
    let questions = this.filterQuestions()
    debugLog("render autocompletes", questions)
    let className = 'keyboardMenuAutocompletes chatGPTQuestionsAutocomplete'
    const style = {
      position: 'absolute',
      bottom: 41,
      top: 'auto'
    }
    return <div className={className} style={style}>
    {
      questions.map(question => {
        const onClick = () => {
          this.props.ask(question)
        }
        const components = undefined
        const content = <Markdown components={components}>{question}</Markdown>
        const className= 'keyboardMenuItem keyboardMenuItemAutocomplete'
        return <div className={className} onMouseDown={onClick}>
                 <div className='keyboardMenuItemIcon'><ReactSVG src={UserSaid}/></div>
                 <div className='keyboardMenuItemLabel'>{content}</div>
               </div>
      })
    }
    </div>
  }

  getQuestions = () => {
    return this.props.questions.filter(x => {
      //////console.log("question", x)
      const { isTrain } = x
      if (this.state.selection === 'test') {
        if (isTrain) {
          return false
        }
      } else {
        if (!isTrain) {
          return false
        }        
      }
      return true
    })
  }

  filterQuestions = () => {
    const debugLog = (...args) => {
      //args.unshift("debug")
      ////console.log.apply(null, args)
    }
    let questions = this.getQuestions()
    let searchTerm = this.props.searchTerm
    debugLog("searchTerm", searchTerm)
    if (!searchTerm) {
      return []
    }
    searchTerm = searchTerm.toLowerCase()
    const searchTerms = searchTerm.split(/\s+/)
    debugLog('searchTerms', searchTerms.length)
    if (searchTerms.length > 2) {
      return []
    }
    const matches = {}
    let prefixMatch = searchTerms.length > 1
    let matchedFullTerm = false
    let filtered = questions.filter(element => {
      const { question, hallucinated, isTrain }  = element
      if (!question) return false
      let matched = 0
      if (searchTerms.length > 0) {
        const label = question.toLowerCase()
        if (label.startsWith(searchTerm)) {
          matched += 10
          prefixMatch = true
          matchedFullTerm = true
        }
        const terms2 = label.split(/\s+/)
        ////debugLog('terms1', terms1)
        //debugLog('terms2', terms2)
        const allTerms = [terms2]//[terms1, terms2]
        allTerms.forEach((terms, i) => {
          //debugLog('terms', terms, i)
          terms.forEach((term, j) => {
            //debugLog('term', term)
            if (term) {
              searchTerms.forEach((searchTerm, k) => {
                if (searchTerm) {
                  if (term.startsWith(searchTerm)) {
                    matched++
                  }
                  if (term === searchTerm &&
                      j === k && k === 0) {
                    matched += 10
                    matchedFullTerm = true
                    prefixMatch = true
                  }
                }
              })
            }
          })
        })
      } else {
        matched = 0
      }
      debugLog("matched", matched, question)
      if (matched === 0) return false
      const match = matches[question] || 0
      matches[question] = match + matched
      return true
    })
    debugLog('filtered', filtered)
    ////console.log("matchedFullTerm", matchedFullTerm)
    if (matchedFullTerm) {
      filtered = filtered.filter(x => {
        ////console.log("filter", x, 'matches', matches[x.question])
        return matches[x.question] >= 10
      })
    }
    debugLog('filtered', filtered)
    debugLog('matches', matches)
    filtered.sort((x, y) => {
      const w1 = matches[x.question] || 0;
      const w2 = matches[y.question] || 0;
      let cmp1 = w2-w1;
      if (cmp1 !== 0) {
        return cmp1;
      }
      return x.question.localeCompare(y.question)
    })
    return filtered.map(x => x.question)
  }

  
  render() {
    const onClick = async () => {
      setTimeout(() =>  {
        this.setState({
          menuActive: !this.state.menuActive
        })
      }, 50)
    }
    let menuStyle = (!this.state.menuActive) ? { display: 'none' } : null
    return <div className='chatGptQuestions'>
             <div className='keyboardAddButton'>
               <KeyboardButton1 keepFocus icon={this.state.menuActive ? MenuDown : MenuUp} action={onClick}/>
             </div>
             {this.renderAutocompletes()}
             {!menuStyle && this.renderMenu()}
           </div>
  }
}


class Tools extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
    
  renderMenu = () => {
    return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
  }

  setMenuRef = ref => {
    this.ref = ref
    if (ref) {
      ref.scrollTop = ref.scrollHeight
    }
  }

  doRenderMenu = () => {
    const closeMenu = (e) => {
      e.preventDefault()
      this.state.menuActive = false
      this.forceUpdate()
    }
    const selectTool = tool => {
      this.state.menuActive = false
      this.props.selectTool(tool)
    }
    const tools = this.props.tools
    tools.sort((x, y) => {
      let selected1 = this.props.selectedTools[x.function.name]
      let selected2 = this.props.selectedTools[y.function.name]
      if (!selected1 && selected2) return -1
      else if (selected1) return 1
      const name1 = x.function.name
      const name2 = y.function.name
      return name1.localeCompare(name2)
    })
    let toolIcon = Category
    return <ClickAwayListener onClickAway={closeMenu}>    
      <div className='chatGptQuestionsMenu' ref={this.setMenuRef}>
        {
            <div className='chatGptQuestionMenuQuestions'>
              {
                tools.map(tool => {
                  let selected = this.props.selectedTools[tool.function.name]
                  const onClick = (e) => {
                    e.preventDefault()
                    selectTool(tool)
                  }
                  let className = 'keyboardMenuItem keyboardMenuItemCategory' + (selected ? ' toolSelected' : ' toolUnselected')
                  return <div key={tool.function.name} className={className} onMouseDown={onClick}>
		           <div className='keyboardMenuItemIcon'><ReactSVG src={toolIcon}/></div>
		           <div className='keyboardMenuItemLabel'>{tool.function.description}</div>
                         </div>
                  
                })
              }
            </div>
        }
      </div>
    </ClickAwayListener>
  }

  render() {
    const onClick = async () => {
      setTimeout(() =>  {
        this.setState({
          menuActive: !this.state.menuActive
        })
      }, 50)
    }
    let menuStyle = (!this.state.menuActive) ? { display: 'none' } : null
    return <div className='chatGptQuestions'>
             <div className='keyboardAddButton'>
               <KeyboardButton1 keepFocus icon={this.state.menuActive ? MenuDown : MenuUp} action={onClick}/>
             </div>
             {!menuStyle && this.renderMenu()}
           </div>
  }
}



export class ChatGPT extends Component {

  constructor(props) {
    super(props)
    let assistantModel = localStorage.getItem("assistantModel")
    let tempStr = localStorage.getItem('temp')
    let temp = 1.0
    if (tempStr) {
      temp = parseFloat(tempStr)
      if (isNaN(temp)) {
        temp = 1.0
      }
    }
    let selectedModel  = assistantModel
    this.state = {
      active: true,
      uploads: [],
      slide: 0,
      edits: new Document(),
      completions: [],
      lang: {
        name: "English",
        dialect: "United States",
        iso: "en-US",
        hasVoice: true
      },
      threadBusy: false,
      swipeIndex: 0,
      assistantModel,
      selectedThreads: [],
      searchResults: [],
      calendarView: 'week',
      selectedDay: new Date()
    }
  }

  componentDidMount() {
    this.startTime = Date.now()
    setTimeout(this.init, 400)
    setTimeout(() => {
      this.setState({
        canShowBlurb: true
      })
    }, 200)
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }
  
  hallucinationQuestions = {}

  hasTasks() {
    for (const id in this.tasks) {
      return true
    }
    return false
  }

  resolveModel = id => {
    id = resolveModelId(id)
    this.getModels()
    return this.modelsById[id]
  }

  onDayChange = day => {
    console.log("day change", day)    
    this.setState({
      selectedDay: day
    }, () => this.updateTaskObserver())
  }


  initTasks = () => {
    //////debugger
    if (this.tasksSub) {
      return
    }
    this.tasks = {}
    this.updateTaskObserver()
  }

  updateTaskObserver = () => {
    let observeTasks
    let current = startOfWeek(this.state.selectedDay).getTime()
    if (this.state.calendarView === 'recent') {
      this.currentWeek = undefined
      if (this.currentView === 'recent') {
        if (this.tasksSub) {
          //console.log("not resubscribing", this.currentView, '==', 'recent')
          return
        }
        console.log("subscribing", 'recent')
      }
      observeTasks = this.props.observeRecentTasks
    } else {
      if (this.currentWeek === current) {
        if (this.tasksSub) {
          //console.log("not resubscribing", 'currentWeek', this.currentWeek, '==', current)
          return
        }
      }
      //console.log("subscribing", current)
      this.currentWeek = current
      //console.log('set current week', this.currentWeek)
      observeTasks = () => this.props.observeTasks(current)
    }
    this.currentView = this.state.calendarView
    if (this.tasksSub) {
      //console.log("unsubscribing tasksSub")
      this.tasksSub.unsubscribe()
    }
    //console.log("subscribing", observeTasks)
    this.tasksSub = observeTasks().subscribe(change => {
      const { task } = change
      //console.log("task", task)
      if (change.type === 'removed') {
        if (this.state.selectedTask &&
            this.state.selectedTask.id === task.id) {
          this.selectThread(null)
        }
        delete this.tasks[task.id]
      } else {
        this.tasks[task.id] = task
        //////////debugger
        const selectedTaskId = localStorage.getItem('selectedTaskId')
        if (selectedTaskId == task.id) {
          //this.selectThread(task)
        }
      }
      this.forceUpdateLater()
    })
  }

  getTools = () => {
    return Object.values(this.tools)
  }

  tools = {}

  currentTaskHasMessages = () => {
    const taskId = this.state.selectedTask.id
    for (const id in this.received) {
      if (this.received[id].task === taskId) {
        return true
      }
    }
    return false
  }

  deinitSelectedTask = () => {
    //////debugger
    this.state.sendError = null
    this.editor.clear();
    this.received = {}
    this.cache = {}
    if (this.messagesSub) {
      this.messagesSub.unsubscribe()
      this.messageSub = null
    }
    if (this.threadsSub) {
      this.threadsSub.unsubscribe()
      this.threadsSub = null
    }
    this.messages1.autoScroll = true
    this.received = {}
    this.chatThreads = {}
    this.forceUpdate()
  }

  initSelectedTask = () => {
    if (this.state.selectedTask) {
      this.messagesSub = this.props.observeTaskMessages({ task: this.state.selectedTask, limit: 100 } ).subscribe(change => {
        consoleLog(change)
        const { message } = change
        if (change.type !== 'removed') {
          this.received[message.id] = message
          this.parseMessage(message)
        } else {
          delete this.received[message.id]
        }
        this.forceUpdateLater()
      })
    }
  }

  init = () => {
    const model = this.state.selectedModel
    consoleLog("init", model)
    if (this.threadsSub) this.threadsSub.unsubscribe()
    if (this.messagesSub) this.messagesSub.unsubscribe()
    if (this.taskSub) this.taskSub.unsubscribe()
    this.received = {}
    this.chatThreads = {}
    let threadOpts
    this.initTasks()
  }

  chatThreads = {}
  received = {}
  tasks = {}

  restartDelayApologyTimer() {
    clearTimeout(this.delayApologyTimeout)
    this.delayApologyTimeout = setTimeout(() => {
      this.forceUpdate()
    }, 15000)
  }

  componentWillUnmount() {
    if (this.threadsSub) this.threadsSub.unsubscribe()
    if (this.messagesSub) this.messagesSub.unsubscribe()
    clearInterval(this.interval)
    if (this.optionsSub) this.optionsSub.unsubscribe()
    if (this.props.onDelete) {
      this.props.onDelete(this)
    }
  }


  getMessages = () => {
    let messages
    messages = Object.values(this.received)
    const reply = {}
    for (const id in this.received) {
      const msg = this.received[id]
      if (msg.inReplyTo) {
        reply[msg.inReplyTo] = msg
      }
    }
    const getTs = msg => {
      if (true || !msg.inReplyTo) {
        return msg.ts
      }
      const inReplyTo = this.received[msg.inReplyTo]
      return inReplyTo ? inReplyTo.ts : msg.ts
    }
    messages.sort((x, y) => {
      const t1 = getTs(x)
      const t2 = getTs(y)
      const cmp = t1 - t2
      if (cmp === 0) {
        return x.from === this.props.me.self.uid ? -1 : 1
      }
      return cmp
    })
    messages = messages.filter(x => x.content || x.streaming)
    if (this.props.messageFilter) {
      messages = messages.filter(message => this.props.messageFilter(message, this))
    }
    return messages
  }

  getThreadMessages = () => {
    if (!this.state.selectedThread) {
      return null
    }
    const messages = this.state.searchResults
    if (!messages) {
      return null
    }
    messages.sort((x, y) => {
      return x.ts - y.ts
    })
    return messages
  }

  getInReplyTo = message => {
    const { inReplyTo } = message
    return this.received[inReplyTo]
  }

  setMessages1 = ref => {
    //debugger
    this.messages1 = ref
  }

  setMessages2 = ref => {
    this.messages2 = ref
  }

  ask = async (topic, q, autoSend) => {
    if (this.received['pending']) {
      return
    }
    if (this.animating) {
      return
    }
    if (topic) {
      let currentTopic = this.getCurrentTopic()
      if (topic != currentTopic) {
        autoSend = false
        const thread = this.chatThreads[topic]
        this.selectThread(thread)
      }
    } else {
      autoSend = false
    }
    const result = this.editor.setText(q)
    if (isDesktop()) {
      this.setState({
        tooltip: ''
      })
    }
    if (autoSend) {
      this.sendChat()
    } 
  }

  getComponents = (message) => {
    const components = {
      p0: opts => {
        const { node, ...props } = opts
        return <div className={'para'} {...props}/>
      },
      pre: PreBlock,
      code: CodeBlock,
      check: CheckMarkComp,
      ol: opts => {
        const { node, ...props } = opts
        let className
        if (props.children.filter(x => x.props && x.props.node.tagName === 'li').length === 1) {
          className = 'olSingle'
        }
        return <ol className={className} {...props}/>
      },
      ul: opts => {
        const { node, ...props } = opts
        let className
        if (props.children.filter(x => x.props && x.props.node.tagName === 'li').length === 1) {
          className = 'olSingle'
        }
        return <ul className={className} {...props}/>
      },
      li: opts => {
        let { node, children } = opts
        if (Array.isArray(children)) {
          children = children.filter((x, i) => i > 0 || x[i] !== '\n')
        }
        if (children && (children[0] === '\n' || children.length > 1)) {
          return <li className='liFun'><div className='liContent'>{children}</div></li>
        } else {
          const { node, ...props } = opts
          return <li {...props}/>
        }
      },
      img: ({src, alt}) => {
        return <a className='aiLink' href={src}><img className='aiLinkImg' src={src}/></a>
      },
      a: props => {
        const { node, children } = props
        const { href } = node.properties
        if (!href || !href.startsWith) {
          return null
        }
        const title = ''
        const prefix = "ai://?q="
        let onClick
        let tooltip
        if (href.startsWith(prefix)) {
          let q = (href + ' ' + (title || '')).substring(prefix.length)
          onClick = (e) => {
            const q1 = decodeURIComponentExt(q)
            debugLog('q', q1)
            this.ask(message.topic, q1)
          }
          tooltip = decodeURIComponentExt(q)
        } else {
          tooltip = href
          onClick = e => {
            this.props.me.openWindow(href)
          }
        }
        const nop = () => {
        }
        let enter
        let leave
        return <div onPointerEnter={enter} onPointerLeave={leave}
                    className='aiLink' onMouseDown={onClick}>{children}</div>
      }
    }
    return components
  }

  renderAISaid = (inReplyTo, message, onClick, opts) => {
    if (false && (!message.models || message.models.length === 0)) {
      let content = message.content
      ////console.log("isLast", message)
      const output = []
      if (content.trim() 
          //&& this.state.selectedModels['attunewise']
         ) {
        output.push(this.renderAISaidVert(message, null))
      }
      if (message.role === 'assistant' && message.models) {
        for (const x of message.models) {
          let {model, content} = x
          const m = this.models.find(x => x.id === model)
          if (!m
              //|| !this.state.selectedModels[model]
             ) continue
          output.push(<div className='chatGptChatMessageHeaderTopic'>{m.vendor} {m.title}</div>)
          output.push(this.renderAISaidVert({
            role: 'assistant',
            content,
            id: message.id
          }, null, {
            model,
          }))
        }
        return output;
      }
      return output
    } else {
      return this.renderAISaidHoriz(inReplyTo, message, null, opts)
    }
  }

  onSlideTransitionStart = message => {
    this.messages1.autoScroll = false
  }
  
  onSlideTransitionEnd = message => {
    this.forceUpdate()
    setTimeout(() => {
      this.messages1.autoScroll = true
      return
      const el = document.getElementById('user-'+message.inReplyTo)
      if (el) {
        el.scrollIntoView({block: 'nearest', behavior: 'smooth'})
      } else {
        debugger
      }
    }, 60)
  }

  aiSaidComps = []

  selectModelIndex = index => {
    this.noSlideTransition = true
    this.aiSaidComps.forEach(x => x.selectModel(index))
    setTimeout(() => {
      this.noSlideTransition = false
    }, 500)
  }

  selectJudgeIndex = index => {
    this.noSlideTransition = true
    this.aiSaidComps.forEach(x => x.selectJudgeIndex(index))
    this.forceUpdate()
    setTimeout(() => {
      this.noSlideTransition = false
    }, 500)
  }

  onCreateAISaid = comp => {
    this.aiSaidComps.push(comp)
  }

  onDeleteAISaid = comp => {
    this.aiSaidComps = this.aiSaidComps.filter(x => x !== comp)
  }
  
  renderAISaidHoriz = (inReplyTo, message, onClick, opts) => {
    const pause = async () => {
      return await this.pauseChat()
      this.forceUpdate()
    }
    let del
    let copy = this.copyToClipboard
    opts = opts || {}
    const getModelIcon = model => {
      if (model.startsWith('ft')) {
        model = 'attunewise'
      }
      const m = this.modelsById[model]
      if (!m) {
        debugger
        return null
      }
      return m.getModelIcon && m.getModelIcon() || m.getIcon()
    }
    const getMessageTopic = model => {
      return this.props.getMessageTopic(this, model)
    }
    return <AISaidComp
             inReplyTo={inReplyTo}
             onCreate={this.onCreateAISaid}
             onDelete={this.onDeleteAISaid}
             selectModel={this.selectModelIndex}
             onSlideTransitionStart={()=>this.onSlideTransitionStart(message)}
             onSlideTransitionEnd={()=>this.onSlideTransitionEnd(message)}
             getModelIcon={getModelIcon}
             resolveModel={this.resolveModel}
             getMessageTopic={getMessageTopic}
             opts={opts}
             key={message.id}
             getComponents={this.getComponents}
             message={message}
             received={this.received}
             opts={opts}
             saveMessageContent={this.saveMessageContent}
             me={this.props.me}
             pause={pause}
             del={del}
             copy={copy}
             judging={opts.isJudge && this.state.judgeChat}
             getMessageTopic={message => this.props.getMessageTopic(this, message)}
           />
  }

  judge = async req => {
    this.setState({
      judging: true
    })
    let reply = this.resolveReply(req)
    const judges = this.judgeView.getSelectedModelIds()
    await this.props.me.judge(judges, req, reply)
    delete this.cache[req.id]
    delete this.cache[reply.id]
    this.state.judging = false
    this.forceUpdateLater()
  }

  renderAISaidVert = (message, onClick, opts) => {
    const components = this.getComponents(message)
    opts = opts || {}
    const { model } = opts
    if (message.id === 'blurb') {
      isBlurb = true
      noSpin = true
    }
    const pause = async () => {
      return await this.pauseChat()
      this.forceUpdate()
    }
    let action3
    let icon3
    let clazz3
    if (false && this.xhr && this.streamSent === message.sent) {
      action3 = pause
      icon3 = Stop
      clazz3 = 'userSaidPause'
    }
    const retry = async () => {
      const orig = this.received[message.inReplyTo]
      if (orig) {
        const text = orig.text
        delete this.received[orig.id]
        delete this.received[message.id]
        this.forceUpdate()
        this.props.deleteChatMessage(orig.id)
        await this.ask(orig.topic, text, true)
      }
    }
    let { role, content, topic, code, isStreaming } = message
    let text = content || ''
    const copy = async () => {
      //alert(opts.answerType)
      //console.log(message.content)
      const text = message.context
      //console.log(text)
      try {
        navigator.clipboard.writeText(text)
      } catch (err) {
        console.error(err)
        console.log(text)
      }
      await delay(0.5)
    }
    let icon1 = AISaid
    if (opts.model) {
      const found = this.models.find(x => x.id == opts.model)
      icon1 = (found.getModelIcon && found.getModelIcon()) || found.getIcon()
    }
    //console.log('icon', opts.model, icon1)
    let icon2 = Copy
    let action = copy
    let className = 'keyboardEditDocument'
    let serverError = topic === 'system-error'
    let showRetry
    if (serverError) {
      icon1 = Alert
      className = 'keyboardEditDocument keyboardEditDocumentError'
      icon2 = null
      action = null
      onClick = null
      showSpeaker = false
      showRetry = true
    }
    let wasMarkdown
    const renderBody = () => {
      if (!containsMarkdown(text) && !containsLatex(text)) {
        return text
      }
      wasMarkdown = true
      ////console.log("contains latex", text)
      //text = escapeUnbalancedDollars(text)
      let markdown = text ? replaceImgWithMarkdown(text) : ''
      const linkReg = /\[([^\]]+)\]\(([^)]+)\)/g
      const before = markdown
      //////console.log("escaping newlines", before, markdown)
      return <Markdown components={components}>{markdown}</Markdown>
      markdown = markdown.replace(/<<[^>]+>>/g, '') // hack: <<...>> caused Markdown to hang because it has a bad regex
      return <Markdown children={markdown} options={{
                         overrides: components
                       }}/>
      
    }
    const renderBody1 = () => {
      const body = renderBody()
      if (true) {
        return body
      }
      return <MarkdownBody>
               {body}
             </MarkdownBody>
    }
    let leftIconClassName = 'chatGPTLeftIcon copyAISaid'
    if (showRetry) {
      leftIconClassName += ' retryAISaid'
    }
    let explainer
    const title = this.props.getMessageTopic(this, message)
    return <div key={message.id+'-'+model} className={className} onClick={onClick}>
             <div className='keyboardEditInstructionLeftIcon'>
               <ReactSVG src={icon1}/>
             </div>
             <div className={'keyboardEditDocumentText'}>
               {renderBody1()}
             </div>
             <div className={leftIconClassName}>
               {!showRetry && <KeyboardButton1 keepFocus icon={icon2} action={action}/>}
               {showRetry && <KeyboardButton keepFocus label="Retry" icon={Send} action={retry}/>}
               {action3 && <div key='pause' className={'keyboardEditInstructionLeftIcon ' + clazz3}>
                                         <KeyboardButton1 keepFocus icon={icon3} action={action3}/>
                           </div>}
             </div>
           </div>
  }


  cache = {}

  invalidateCache = msg => {
    if (!msg) {
      this.cache = {}
      return
    }
    delete this.cache[msg.id]
    if (msg.inReplyTo) {
      delete this.cache[msg.inReplyTo]
    } else {
      for (const id in this.received) {
        const x = this.received[id]
        if (x.inReplyTo == msg.id) {
          delete this.cache[x.id]
        }
      }
    }
  }

  resolveReply = message => {
    for (const id in this.received) {
      const x = this.received[id]
      if (x.inReplyTo === message.id) {
        return x
      }
    }
  }

  resolveTopic = message => {
    const task = this.tasks[message.task]
    return null
  }
  
  renderChatMessage = (message, prev, next, mergedBody, opts) => {
    const { isJudge } = opts
    const cached = this.cache[message.id]
    console.log("renderChatMessage", message, cached, opts)
    //if (!isJudge && cached) return cached
    const msg = message
    let topic
    if (message.role === 'user') {
      topic = this.props.getMessageTopic(this, msg, prev)
    }
    const time = Number(msg.ts);
    let sameDay = "h:mm a";
    let sameElse =  "MM/DD/YYYY \\a\\t ";
    const sameMinute = (t1, t2) => {
      const d1 = new Date(t1);
      const d2 = new Date(t2);
      return (d1.getYear() === d1.getYear() && d1.getMonth() == d2.getMonth() && d1.getDate() === d2.getDate() && d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes());
    };
    if ((prev && sameMinute(time, prev)) || (next && sameMinute(time, next))) {
      sameDay = "h:mm:ss a";
    }
    const timestamp =  moment(time).calendar(null, {
      sameDay: sameDay,
      //lastDay: "[Yesterday] "+sameDay,
      //lastWeek: "[Last] dddd "+sameDay,
      sameElse: sameElse+ sameDay,
    })
    let className = 'chatGptChatMessageHeader'
    if (message.id !== 'blurb') {
      if (message.from === this.props.me.self.uid) {
        className += ' chatMessageFromUser'
      } else {
        className += ' chatMessageFromGpt'
      }
    }
    const fromMe = message.role === 'user'
    const model = message.model || this.props.defaultModel(this)
    const noTime = true
    let noTopic
    let className0 = 'chatGptChatMessage'
    if (message.topic === 'system-error') {
      className0 += ' chatGptChatMessageError'
    }
    const onClick = e => {
      this.onClickMessage(message)
    }
    const msgBody = this.renderChatMessageBody(message, prev, next, mergedBody, opts)
    if (msgBody.length === 0) {
      return msgBody
    }
    const result = <div key={message.id} className={className0} onClick={onClick}>
                     {topic && !(noTime && noTopic) && <div key='header' className={className}>
                                                         <div className='chatGptChatMessageHeaderTopic'>{noTopic ? '' : topic}</div>
                                                         {!noTime && <div key='timestamp' className='chatGptChatMessageTimestamp'>{timestamp}</div>}
                                                       </div>}
                     <div key='body' className='chatGptChatMessageBody'>
                       {msgBody}
                     </div>
                   </div>
    if (!this.state.isStreaming) {
      this.cache[message.id] = result
    } 
    return result
  }

  isRecent = message => {
    const now = Date.now()
    return now - message.ts < 60000;
  }

  isToolCallComplete = (tool_call_id) => {
    const reply = Object.values(this.received).find(x => x.tool_call_id === tool_call_id)
    return reply
  }

  wasAnswered = (message) => {
    const reply = Object.values(this.received).find(x => x.inReplyTo === message.id)
    if (reply && reply.id == message.id + ".reply" && reply.text) {
      if (message.stream) {
        message.stream = null
        delete this.cache[message.id]
      }
    }
    ////console.log("was answered", message.id, reply)
    return reply
  }

  onClickMessage = message => {
    const f = this.props.onClickMessage
    if (f) {
      f(message, this)
    }
  }

  purgeCache = message => {
    ////console.log('purge', message)
    const id = message.id
    delete this.cache[id]
    delete this.received[id]
    const replies = Object.values(this.received).filter(x => x.inReplyTo === id)
    ////console.log("purging replies", replies)
    replies.forEach(reply => {
      delete this.cache[reply.id]
      delete this.received[reply.id]
    })
    const dangling = Object.values(this.received).filter(x => x.from !== this.props.me.self.uid && !x.inReplyTo)
    ////console.log('dangling', dangling)
  }


  isFromMe = message => message.role === 'user'
  
  renderChatMessageBody = (message, prev, next, mergedBody, opts) => {
    const { isJudge } = opts
    const components = this.getComponents(message)
    console.log("renderChatMessageBody", message, opts)
    if (message.role === 'user') {
      const del = async () => {
        await this.props.me.deleteChatMessage(message.id)
        this.purgeCache(message)
        this.forceUpdate()
      }
      const now = Date.now()
      if (message.reaction && !message.reaction.split) {
        ////////////debugger
      }
      let action1
      let icon1
      let clazz1 = ''
      if (message.id !== 'pending') {
        action1 = del
        icon1 = Trash
        clazz1 = 'userSaidDel'
      }
      let markdown = replaceImgWithMarkdown(message.content|| '')
      ////console.log("markdown", markdown)
      let content = markdown
      if (message.storage) {
        const len = message.contentLength
        content += '\n... ' + (len - content.length).toLocaleString() + " more characters ..."
      } else {
        content = <Markdown components={components}>{markdown}</Markdown>
      }
      let judgeIcon = this.props.judging ? Spin : Question
      let judge
      const hasReplies = message => {
        for (const id in this.received) {
          if (this.received[id].inReplyTo === message.id) return true
        }
      }
      if (false && hasReplies(message)) {
        judge = async () => {
          if (!this.state.judging) {
            await this.judge(message)
          }
        }
      }
      const onSwipe = (e) => {
        debugger
        this.setState({
          swipeMessage: message
        })
      }
      let user = <div
                   key='usermsg'
                   className='keyboardEditInstruction aiUser'
                   onTouchStart={onSwipe}
                   onMouseDown={onSwipe}
                   onWheel={onSwipe}
                 >
               <div className='keyboardEditIconAndInstruction'>
                 <div className='keyboardEditInstructionLeftIcon'>
                 <ReactSVG src={UserSaid}/>
                 </div>
                 <div className='keyboardEditInstructionText' key={'user-'+message.id}>
                   {content}
                 </div>
                 <div className='rightColumn1 copyAISaid'>
                   {action1 && <div key='del' className={'keyboardEditInstructionLeftIcon ' + clazz1}>
                                 <KeyboardButton1 keepFocus icon={icon1} action={action1}/>
                               </div>
                   }
                   {judge && <KeyboardButton1 key={'judge'} icon={judgeIcon} action={judge}/>}
                 </div>
               </div>
                 </div>
      return user
    }
    const output = []
    let { content, role, task, ts, isStreaming } = message
    let text = content
    let outputTs = ts
    if (isStreaming && !text ) {
      text = 'Working.'
    }
    let images
    let noSpin = text
    const isLast = !next
    const inReplyTo = this.received[message.inReplyTo]
    if (isJudge && message.judgement) {
      let { judgements } = message.judgement
      if (judgements) {
        const sortedJudgements = [].concat(judgements)
        sortedJudgements.sort((x, y) => {
          const a = this.resolveModel(x.judge)
          const b = this.resolveModel(y.judge)
          return a.name.localeCompare(b.name)
        })
        judgements = sortedJudgements
        const Avg = {
        }
        for (const j of judgements) {
          const { judge, ratings } = j
          for (const r of ratings) {
            const { rating, model } = r
            if (!Avg[model]) {
              Avg[model] = 0
            }
            Avg[model] += (rating / judgements.length)
          }
        }
        console.log({Avg})
        let winner
        let winners = []
        let rating = 0
        for (const model in Avg) {
          if (winner == undefined || Avg[model] >= rating) {
            winner = model
            if (Avg[model] === rating) {
              winners.push(model)
            } else {
              winners = [model]
            }
            rating = Avg[model]
          }
        }
        const selectModel = async (modelId) => {
          const resolved = this.resolveModel(modelId)
          this.selectModelIndex(resolved)
        }
        const selectJudge = async (judge) => {
          const resolved = this.resolveModel(judge)
          this.selectJudgeIndex(resolved)
        }
        const toButton = (modelId, small, action) => {
          const m = this.resolveModel(modelId)
          const icon = (m.getModelIcon && m.getModelIcon()) || m.getIcon()
          if (!action) {
            action = async () => {
              selectModel(modelId)
            }
          }
          let button = <SimpleButton icon={icon} label={m.name} action={action}/>
          if (!small) {
            return button
          }
          return <div className='modelSelection1'>{button}</div>
        }
        const ws = winners.map(x => toButton(x, true))
        for (const w of winners) {
          delete Avg[w]
        }
        const losers = Object.keys(Avg)
        losers.sort((x, y) => {
          return Avg[y] - Avg[x]
        })
        const ls = losers.map(loser => {
          return <div className='judgeLoser'>{toButton(loser, true)}{` ${Math.round(Avg[loser] * 10)/10}/10`}</div>
        })
        let avgRating = 'rating'
        if (judgements.length > 1) {
          if (ws.length > 1) {
            avgRating = "average ratings of"
          } else {
            avgRating = "an average rating of"
          }
        }
        let answerIs = "answer is from"
        if (ws.length > 1) {
          answerIs = "answers are from"
        }
        const textContent = [`The best ${answerIs} `,ws, ` with ${avgRating} ${Math.round(rating * 10)/10}/10.`]
        const text = <div className='judgeWinnerMsgText'>{textContent}</div>
        const div = <div className='judgeWinnerMsg'>
                      <div className='judgesLine'>
                        {sortedJudgements.map(x => toButton(x.judge, false,  async () => selectJudge(x.judge)))}
                      </div>
                      {text}
                      <div className='judgeLosers'>
                        {ls}
                      </div>
                    </div>
        output.push(div)
      }
    }
    const aiSaid = this.renderAISaid(inReplyTo, message, null, { isLast, isJudge })
    output.push(aiSaid)
    return output
  }

  progressMessage = {}

    
  isTextMessage = (message, withReactions) => {
    return true
  }
  
  renderMessages = (rawMessages, isJudge) => {
    consoleLog("messages", rawMessages, { isJudge })
    let messages
    const mergedBody = {}
    messages = rawMessages
    const opts = { isJudge }
    return messages.map((msg, i) => {
      const prev = i > 0 ? messages[i-1] : null
      const next = i + 1 < messages.length ? messages[i+1] : null
      return this.renderChatMessage(msg, prev, next, mergedBody, opts)
    })
  }

  setSliderContainer = ref => {
    this.sliderContainer = ref
  }

  render() {
    const subpage = this.state.subpage ? this.state.subpage() : null
    const popup = this.state.popup ? this.state.popup() : null
    const content = this.renderContent()
    return  <BnPage me={this.props.me} subpage={subpage} popup={popup} safeArea={true}>
              {content}
            </BnPage>
    
  }

  shareThread = async (thread, messages) => {
    await this.props.me.shareThread(thread, messages)
  }

  setFileChooser = ref => {
    this.fileChooser = ref
  }

  renderDiscussions = () => {
    const selectedThread = this.state.selectedTask
    let threads = this.getThreads()
    //console.log({threads})
    let style
    let style3 = { visibility: 'hidden' }
    const onDayChange = day => {
      this.onDayChange(day)
    }
    let needsCal = true
    let day
    const onViewChange = view => {
      //console.log("view change", view)
      if (this.currentView !== view) {
        this.currentWeek = undefined
        this.setState({
          calendarView: view
        })
      }
    }
    const events = threads.map(thread => {
      const ts = thread.lastUpdated
      if (day === undefined) {
        day = startOfDay(ts)
      } else if (startOfDay(ts) !== day){
        needsCal = true
      }
      return {
        id: thread.id,
        start: startOfDay(new Date(ts)),
        end: endOfDay(new Date(ts)),
        text: thread.description,
        daily: thread
      }
    })
    if (this.state.searchTerm) {
      needsCal = false
    }
    //console.log("THREADS", this.state.calendarView, threads)
    let headerStyle = needsCal ? null: { display: 'none'}
    let cost = 0
    for (const thread of threads) {
      const { usage } = thread
      if (usage && usage.total) {
        cost += usage.total
      }
    }
    //console.log("COST", cost)
    let costView 
    if (cost > 0) {
      costView = '$' + formatPrice(cost)
    }
    return <div className='attunewiseLayer attunewiseLayerDiscussion'>
             <div className='keyboardHeader' style={style}>
               <div><KeyboardButton1 icon={Left} action={this.goBack}/></div>
               <KeyboardTitle title={this.props.getTitle(this)}/>
               <div style={style3} className='keyboardHeaderButton keyboardHeaderButtonCancel' onMouseDown={this.cancel}>
                 <ReactSVG src={Cross}/>}
               </div>
             </div>
             {this.renderDiscussionSearchField()}
             <div className='attunewiseDiscussionCal' style={headerStyle}>
               <Calendar initialView='recent' events={events} onDayChange={onDayChange} onViewChange={onViewChange} viewSelection={['recent', 'week', 'day']}/>
             </div>
             <div className='discussionHeader' style={headerStyle}>
               {threads.length} Discussions
               <div className='modelPrice'>{costView}</div>
             </div>
             <Threads
               me={this.props.me}
               slide={1}
               checkScrollBack={this.getThreadsHistory}
               selectThread={this.selectThread}
               selectedThread={selectedThread}
               threads={threads}
               deleteTask={this.deleteTask}/>
           </div>
  }

  renderContent() {
    let className = 'attunewiseContent'
    if (this.state.selectedTask) {
      className += ' attunewiseTaskActive'
    }
    return <div className={className}>
             <div className='attunewiseLayer'>
               {this.renderDiscussions()}
             </div>
             <div className='attunewiseLayer attunewiseLayerChat'>
               {this.renderChat()}
             </div>
           </div>
  }

  onJudgeSwiper = swiper => {
    this.judgeSwiper = swiper
  }

  onJudgeSwipeTransitionEnd = event => {
    if (this.judgeSwiper.activeIndex == 0) {
      this.setState({
        swipeMessage: null
      })
    }
  }

   onSelectJudge = event => {
     this.cache = {}
     this.setState({
       judgeChat: this.judgeSwiper.activeIndex === 1
     }, () => {
       console.log("judgeChat", this.state.judgeChat)
     })
     
  }

  setJudgeView = (view)  => {
    if (view !== this.judgeView) {
      this.judgeView = view
      this.forceUpdate()
    }
  }

  setModelsView = (view)  => {
    if (view !== this.modelsView) {
      this.modelsView = view
      this.forceUpdate()
    }
  }

  renderChat = () => {
    let blurb = ''
    let followUpQuestions  
    let title
    let rawMessages = this.getMessages()
    //console.log("rawMessages", rawMessages)
    let buttonIcon = Send
    let buttonAction = async () => {
      return await this.sendChat()
    }
    let copyAction = async () => {
      const messages = this.getMessages()
      let text = ''
      for (const message of messages) {
        const { role, model, models, content } = message
        if (role === 'user') {
          text += role + ":\n"
          text += content
          text += '\n\n'
        }
        else {
          [{model, content}].concat(models || []).forEach((x, i) => {
            let { model, content } = x
            model = this.resolveModel(model)
            text += '#' + (i+1) + ' ' +model.vendor + " " + model.name + ":\n"
            text += content
            text += '\n\n'
          })
          text += '\n\n'
        }
      }
      //console.log(text)
      try {
        await this.copyToClipboard(text)
      } catch (err) {
      }
    }
    const shareAction = async() => {
    }
    let busy = false
    let questions = []
    let placeholder = this.state.judgeChat ? 'Talk to judge' : 'Ask me anything'
    let currentTask
    if (this.state.slide > 0.5) {
      currentTask = this.state.selectedTask && this.state.selectedTask.id
    } 
    let newTopicButton
    busy = this.state.threadBusy
    let buttonLabel = 'Send'
    if (!currentTask) {
      const newTopic = async () => {
        //debugger
        if (this.props.newTopic) {
          return this.props.newTopic(this)
        }
        if (!this.props.onNewFile) {
          const task = await this.props.createNewTask()
            return this.selectThread(task)
        }
      }
      newTopicButton = <KeyboardButton className='newTopicButton' label={'Ask'} keepFocus action={newTopic} icon={Hashtag}/>
    }
    const openImage = async (event) => {
      this.fileChooser.click()
    }
    const handleImage = async (event) => {
      this.handleDataTransfer(event, event.target)
    }
    let bottomRow = [<div className='imageChooser' onClick={openImage}>
                      <input
                        ref={this.setFileChooser}
                        type="file"
                        style={{ display: 'none' }} // Hides the file input
                        onChange={handleImage}
                      />                      
                      <ReactSVG src={Image}/>
                     </div>]

    let messages = rawMessages
    if (this.editor && !this.editor.getText() && rawMessages.length > 0) {
      const message = messages[messages.length-1]
      if (!this.xhr || message.inReplyTo) {
        const resend = async () => {
          const { inReplyTo } = message
          let request
          if (inReplyTo) {
            request = this.received[inReplyTo]
          } else {
            request = message
          }
          await this.sendChat(request)
          this.forceUpdate()
        }
        if (message.inReplyTo) {
          buttonLabel = this.props.getButtonLabel(this, message)
        }
        buttonAction = resend
      } else {
        buttonAction = async () => {
        }
      }
    }
    if (this.state.sending) {
      buttonIcon = Spin
      buttonAction = async () => {}
    } else if (this.state.isStreaming) {
      buttonAction = this.pauseChat
      buttonLabel = "Stop"
      buttonIcon = Stop
    }
    let showSearchField = this.props.isSearchFieldVisible(this, messages)
    const x = (1.0-this.state.slide) * -(Math.min(window.innerWidth, 600) - 10)
    const sliderStyle = {
      //transform: `translate(${x}px, 0)`
    }
    let index = this.state.swipeIndex
    let sliderClassName = 'chatMessagesSlider'
    if (this.state.selectedThread) {
      //sliderClassName += ' chatMessagesSliderEnabled'
    }
    let showKeyboard = this.state.showKeyboard
    let menu
    let questionsTopic
    if (this.state.hallucinationQuestions) {
      questionsTopic = null
      questions = this.state.hallucinationQuestions
      questions.sort((x, y) => {
        return x.question.localeCompare(y.question)
      })
    }
    if (questions.length > 0) {
      const ask = q => this.ask(questionsTopic, q, true)          
      menu = <Questions me={this.props.me} ask={ask} searchTerm={this.state.questionSearchTerm} questions={questions} selectQuestion={ask} editorHeight={this.state.editorHeight}/>
    }
    const sfcStyle = {
      height: this.state.selectedThread ? 40 : 80
    }
    let style = !isDesktop() &&this.state.orient === 'landscape' ? { display: 'none' } : null
    let style2
    if (!this.props.back) {
      style2 = { visibility: 'hidden'}
    }
    let style3
    title = <div className='chatGPTModelHeader'>
              <div className='keyboardRadioButtonIcon'><ReactSVG src={OpenAI}/></div>
              {'OpenAI GPT-3.5 Turbo'}
            </div>
    let inputControlStyle
    inputControlStyle = {
      //opacity: this.hasTasks() ? this.state.slide : 1
    }
    let mainClassName ='chatGPT'
    if (this.props.enableInput && !this.props.enableInput(this)) {
      inputControlStyle = { display: 'none' }
      mainClassName += ' chatGPTNoInput'
    }
    const goBack = async () => {
      this.selectThread(null)
    }
    const setTemp = ({value}) => {
      //console.log("setTemp", value)
      this.setState({temperature:value}, this.saveOptionsLater)
    }
    const getTemp = () => this.state.temperature

    const toggleSize = (size) => {
      if (this.state.sizes[size]) {
        delete this.state.sizes[size]
      } else {
        this.state.sizes[size] = true
      }
      this.forceUpdate(this.saveOptionsLater)
    }
    const toggleSmall = () => toggleSize('small')
    const toggleMedium = () => toggleSize('medium')
    const toggleLarge = () => toggleSize('large')
    let filler = '' 
    const openSettings = async () => {
      this.setState({showSettings: !this.state.showSettings})
    }
    const selectedModelIds = this.getSelectedModelIds()
    const selectedModelCount = selectedModelIds.length
    const selectedIconByVendor = {}
    const vendorActive = {}
    const vendorSelected = {}
    for (const id of selectedModelIds) {
      const model = this.resolveModel(id)
      const icon = (model.getModelIcon && model.getModelIcon()) || model.getIcon()
      if (icon) {
        vendorSelected[model.vendor] = true
        selectedIconByVendor[model.vendor] = icon
        const selectModel = async () => this.selectModel(model)
        //selectedIcons.push({ model, icon: <KeyboardButton1 icon={icon} action={selectModel}/>})
      }
    }
    let input = 0
    let output = 0
    const all = {}
    const getAllModels = () => {
      const allModels = this.getModels()
      //console.log({allModels})
      for (const model of allModels) {
        if(model.id) {
          all[model.id] = model
        } else {
          debugger
        }
      }
      const result = []
      const seen = {}
      const add = model => {
        if (model) {
          if (!seen[model]) {
            result.push(all[model])
            seen[model] = true
          }
        } else {
        }
      }
      for (const message of rawMessages) {
        if (message.role === 'assistant') {
          if (message.content) {
            add('attunewise')
          }
          if (message.models) {
            for (const model of message.models) {
              add(model.model)
            }
            if (message.judgement) {
              const { judge, judgements } = message.judgement
              if (judge) {
                add(judge)
              } else if (judgements) {
                for (const j of judgements) {
                  const { judge, ratings } = j
                  add(judge)
                }
              }
            }
          }
        }
      }
      return result
    }
    const models = getAllModels()
    const prices = {}
    for (const id in this.props.prices) {
      const price = this.props.prices[id]
      if (price.contexts) {
        //console.log("PRICE", price)
        prices[id] = price.contexts[0].price
     }
    }
    console.log({allModels: models})
    const modelsById = {}
    const vendorByName = {}
    for (const vendor of this.props.vendors) {
      vendorByName[vendor.name] = vendor
    }
    for (const model of models) {
      if (!model) {
        debugger
        continue
      }
      modelsById[model.id]  = model
      if (model.contexts) {
        const price = model.contexts[0].price
        prices[model.id] = price
        input += price.input
        output += price.output
      } else {
        //console.log("no contexts", model)
      }
    }
    //console.log({prices})
    const calcPrices = (modelSrc, judgeSrc, rawMessages) => {
      const selectedIcons = []
      const selectedJudgeIcons = []
      const usage = {}
      const inTokens = {attunewise: 0}
      const outTokens = {attunewise: 0}
      let i = 0
      let i$ = 0
      let o$ = 0
      let seenModel = {}
      let seenJudge = {}
      for (const model of modelSrc.getSelectedModelsListFiltered()) {
        let m = seenModel[model.id]
        if (!m) {
          seenModel[model.id] = m = { model, selected: true }
          selectedIcons.push(m)
        } else {
          m.selected = true
        }
      }
      if (judgeSrc) for (const model of judgeSrc.getSelectedModelsListFiltered()) {
        let m = seenJudge[model.id]
        if (!m) {
          seenJudge[model.id] = m = { model, selected: true }
          selectedJudgeIcons.push(m)
        } else {
          m.selected = true
        }
      }
      for (const message of rawMessages) {
        let { role, content, usage, models, judgement } = message
        if (judgement) {
          const { judge, usage } = judgement
          if (usage && usage.inputTokens) {
            if (!inTokens[judge]) {
              inTokens[judge] = 0
            }
            if (!outTokens[judge]) {
              outTokens[judge] = 0
            }
            inTokens[judge] += usage.inputTokens
            outTokens[judge] += usage.outputTokens
          }
          if (!seenJudge[judge]) {
            const m = modelsById[judge]
            if(m) {
              const icon = (m.getModelIcon && m.getModelIcon()) || m.getIcon()
              let m1 = { model: m }
              seenJudge[m.id] = m1= 
              selectedJudgeIcons.push(m1)
            } else {
              console.error("model not found", judge)
            }
          }
        }
        if (role === 'user') {
          if (usage && usage.inputTokens) {
            i = usage.inputTokens
          } else {
            i = 0//countTokens(content)
          }
        } else {
          if (content.trim()) {
            const m = modelsById['attunewise']
            vendorActive[m.vendor] = true
            const icon = (m.getModelIcon && m.getModelIcon()) || m.getIcon()
            selectedIconByVendor[m.vendor] = true
            let m1 = seenModel[m.id]
            if (!m1) {
              seenModel[m.id] = m1 = { model: m }
              selectedIcons.push(m1)
            }
            let o
            if (usage) {
              o = usage.outputTokens
            } else {
              o = 0//countTokens(content)
            }
            outTokens['attunewise'] += o
          }
          if (models) {
            for (let {content, model, usage} of models) {
              const modelId = resolveModelId(model)
              const m = modelsById[modelId]
              if (!m) {
                console.error("model not found", model)
                continue
              }
              vendorActive[m.vendor] = true
              const icon = (m.getModelIcon && m.getModelIcon()) || m.getIcon()
              selectedIconByVendor[m.vendor] = true
              let m1 = seenModel[m.id]
              if (!m1) {
                seenModel[m.id] = m1 = { model: m }
                selectedIcons.push(m1)
              }
              let o
              let i1 
              if (usage && usage.inputTokens) {
                o = usage.outputTokens
                i1 = usage.inputTokens
              } else {
                o = 0//countTokens(content)
                i1 = i
              }
              if (!inTokens[model]) {
                inTokens[model] = 0
              }
              inTokens[model] += i1
              if (!outTokens[model]) {
                outTokens[model] = 0
              }
              outTokens[model] += o
            }
          }
        }
      }
      selectedIcons.sort((a, b) => {
        const x = a.model
        const y = b.model
        return x.title.localeCompare(y.title)
      })
      selectedJudgeIcons.sort((a, b) => {
        const x = a.model
        const y = b.model
        return x.title.localeCompare(y.title)
      })
      for (let id in inTokens) {
        id = resolveModelId(id)
        if (!prices[id]) {
          //console.log("NO PRICE", id, prices)
          continue
        }
        i$ += (prices[id].input / 1000000) * inTokens[id]
      }
      for (let id in outTokens) {
        id = resolveModelId(id)
        if (!prices[id]) {
          continue
        }
        o$ += (prices[id].output / 1000000) * outTokens[id]
      }
      const format = this.props.formatPrice || formatPrice
      const price = "$" + format(i$, 3) + "/" + format(o$, 3)
      return { inTokens, outTokens, usage, selectedIcons, price, selectedJudgeIcons}
    }
    let deleteButton
    const thread = this.state.selectedTask
    if (thread) {
      const trash = async () => {
        if (this.state.confirmDelete !== thread.id) {
          this.state.confirmDelete = thread.id
          ////console.log("confirmDelete", thread)
        } else {
          thread.busy = true
          this.forceUpdate()
          await this.deleteTask(thread)
          this.state.confirmDelete = null
          delete thread.busy
          this.selectThread(null)
        }
        this.forceUpdate()
      }
      let deleteIcon = thread.busy ? Spin : Trash
      if (this.state.confirmDelete === thread.id) {
        const cancelDelete = async () => {
          this.state.confirmDelete = null
          this.forceUpdate()
        }
        deleteButton = <ClickAwayListener onClickAway={cancelDelete}>
                         <div className='threadDeleteButtonConfirm' onClick={trash}>Confirm Delete<div className='keyboardMenuItemIcon'><ReactSVG src={deleteIcon}/></div></div>
                       </ClickAwayListener>
        
      } else {
        deleteButton = trash && <div className='threadDeleteButton'><KeyboardButton1 keepFocus action={trash} icon={Trash}/></div>
      }
    }
    const closeMenu = () => {
      setTimeout(() => {
        this.setState({
          showModelMenu: false
        })
      }, 50)
    }
    const toggleMenu = async () => {
      this.setState({
        showModelMenu: !this.state.showModelMenu
      })
    }
    let judgeConversation = []
    const menuActive1 = thread  && selectedModelCount === 0
    if (true || this.state.judgeChat) {
      for (const message of rawMessages) {
        judgeConversation.push(message)
        if (this.state.swiperMessage) {
          if (message.inReplyTo === this.state.swipeMessage.id) {
            const { judgement } = message
            if (judgement && judgement.messages) {
              for (const message of judgement.messages) {
                judgeConversation.push(message)
              }
            }
            break
          }
        }
      }
    }
    let selectedJudgeCount = 0
    let judgePrice
    let menuActive2 = this.state.judgeChat && selectedJudgeCount === 0
    let messageSelectionClassName = 'messageSelection'
    let judgeMessageSelectionClassName = 'messageSelection'
    let modelSelection
    let judgeSelection
    let modelInfo = { price: 0 }
    if (this.modelsView && this.judgeView) {
      modelInfo = calcPrices(this.modelsView, this.judgeView, rawMessages)
      modelSelection = <ModelSelection models={modelInfo.selectedIcons} action={this.selectModelIndex}/>
      messageSelectionClassName += ' messageSelectionModels'
      judgeSelection = <ModelSelection models={modelInfo.selectedJudgeIcons} action={this.selectJudgeIndex}/>
      judgeMessageSelectionClassName += ' messageSelectionModels'
    } else {
      console.log("missing models view")
    }
    let sliderContainerStyle = {
      height: `calc(100% - ${this.state.editorHeight}px - 5px)`
    }
    return <div className={mainClassName}>
             <div className='keyboardHeader' style={style}>
               <div style={style2}><KeyboardButton1 icon={Left} action={goBack}/></div>
               <KeyboardTitle title={this.props.getTitle(this)}/>
               {deleteButton}
             </div>
             <div className='chatMessagesSliderContainer' ref={this.setSliderContainer} style={sliderContainerStyle}>
               <Swiper
                 modules={[Mousewheel]}
                 allowTouchMove={!isDesktop()}
                 mousewheel={isDesktop() ? { forceToAxis: true, thresholdDelta: 6  } : undefined}
                 onSwiper={this.onJudgeSwiper}
                 onSlideChangeTransitionEnd={this.onJudgeSwipeTransitionEnd}
                 onSlideChange={this.onSelectJudge}>
                 <SwiperSlide >
                   <div className={sliderClassName} style={sliderStyle}>
                     <div className='forModelsMenu'>
                       <ModelsView
                         onOptionsChanged={() => this.forceUpdate()}
                         onCreate={this.setModelsView}
                         me={this.props.me}
                         category={'models'}
                         prices={this.props.price}
                         models={(isSelected, select) => this.props.models(this, isSelected, select)}
                         vendors={this.props.vendors}
                         observeOptions={this.props.me.observeModelOptions}
                         saveOptions={this.props.me.saveModelOptions}/>
                       <div className='chatHeaderFiller'>{filler}</div>
                       <div className='modelPrice'>{modelInfo.price}</div>
                       {this.state.showSettings && <div className='tempSlider'><div className='tempLabel'>Temp:</div><Slider onChange={setTemp} value={getTemp()}/></div>}
                       <KeyboardButton1 className='gear' icon={Settings} action={openSettings}/>
                       <div className='chatHeaderSelectedModels'>{selectedModelCount}</div>
                     </div>
                     <div className={judgeMessageSelectionClassName}>
                       {modelSelection}
                       <Messages slide={this.state.slide}
                                 searchFieldVisible={showSearchField}
                                 selectedThread={null}
                                 selectThread={this.selectThread}
                                 onCreate={this.setMessages1}
                                 key='main'
                                 autoscroll={true}
                                 messages={this.renderMessages(messages, false)}
                                 checkScrollBack={
                                   () => {
                                     // this.props.checkScrollBack()
                                   }
                                 }/>
                     </div>
                   </div>
                 </SwiperSlide>
                 {rawMessages.length > 0 && <SwiperSlide >
                                              <div className={sliderClassName} style={sliderStyle}>
                                                <div className='forModelsMenu'>
                                                  <ModelsView
                                                    onOptionsChanged={() => this.forceUpdate()}
                                                    onCreate={this.setJudgeView}
                                                    me={this.props.me}
                                                    category={'judges'}
                                                    prices={this.props.price}
                                                    models={(isSelected, select) => this.props.models(this, isSelected, select)}
                                                    vendors={this.props.vendors}
                                                    observeOptions={this.props.me.observeModelOptions}
                                                    saveOptions={this.props.me.saveModelOptions}
                                                  />
                                                  <div className='chatHeaderFiller'>{filler}</div>
                                                  <div className='modelPrice'>{judgePrice}</div>
                                                </div>
                                                <div className={messageSelectionClassName}>
                                                  {judgeSelection}
                                                  <Messages slide={1}
                                                            searchFieldVisible={false}
                                                            selectedThread={null}
                                                            onCreate={this.setMessages2}
                                                            key='main'
                                                            autoscroll={true}
                                                            messages={this.renderMessages(judgeConversation, true)}
                                                            checkScrollBack={
                                                              () => {
                                                                // this.props.checkScrollBack()
                                                              }
                                                            }/>
                                                </div>
                                              </div>
                                            </SwiperSlide>}
               </Swiper>
                      
               {this.state.sendError &&
                <div className='keyboardEditIconAndInstruction sendError'>
                  <div className='keyboardEditInstructionLeftIcon'>
                    <ReactSVG src={Alert}/>
                    </div>
                  <div className='keyboardEditInstructionText'>
                    {this.state.sendError}
                  </div>
                </div>}
             </div>
             <div className={'chatGPTInput' + (menu ? ' chatGPTInputWithMenu' : '')} style={inputControlStyle}>
               <InputControl onCreate={this.setInputRef}
                             busy={busy}
                             onDrop={this.onDrop}
                             onPaste={this.onPaste}
                             onKeyDown={this.onKeyDown}
                             placeholder={placeholder} me={this.props.me}
                             onSetEditor={this.setEditor}
                             onClear={this.clearEditor}
                             speechInputNoFocus={true}
                             speechInputActive={this.state.textFieldSpeechInputActive}
                             speechInputAction={this.toggleTextFieldSpeechInput}
                             selectSpeechInputLang={this.selectTextInputLang}
                             selectedLang={this.state.lang}
                             autodetect={this.autodetectLang} label={buttonLabel}
                             icon={buttonIcon} action={buttonAction}
                             copy={copyAction} share={shareAction}
                             cancel={undefined} undo={!this.isKeyboardShowing() &&
                                                      this.canUndo() && this.undoEdit}
                             redo={!this.isKeyboardShowing() && this.canRedo() &&
                                   this.redoEdit}
                             onBlur={() => {
                               this.setTextInputFocus(false) }}
                             onFocus={() => {
                               this.setTextInputFocus(true) }}
                             onInput={this.onInput}
                             applyCompletion={this.applyCompletion}
                             completions={this.state.completions}
                             bottomRow={bottomRow}
                             
                             menu={menu}
                          />
                             
               {isDesktop() && !true && <div className='chatGptTooltip'>{decodeURIComponentExt(this.state.tooltip || '')}</div>}
             </div>
           </div>
  }

  setInputRef = ref => {
    if (ref != this.inputRef) {
      this.inputRef = ref
      if (ref) ref.observeEditorHeight().subscribe(height => {
        this.setState({
          editorHeight: height
        })
      })
    }
  }

  checkScrollBack2 = async () => {
    if (false) {
      return await this.checkScrollBack()
    }
  }
  checkScrollBack = async () => {
    if (this.scrollBusy) {
      return
    }
    this.scrollBusy = true

    let earliestTs = Date.now()
    let earliest = { ts: earliestTs }
    for (const k in this.received) {
      const msg = this.received[k]
      const { ts } = msg
      if (ts < earliestTs) {
        earliestTs = ts
        earliest = msg
      }
    }
    //debugger
    const prev = await this.props.getHistory(this, this.state.selectedTask, earliest, this.props.pageSize || 10)
    if (prev.length > 0) {
      this.messages1.pushScrollBottom()
      for (const msg of prev) {
        this.parseMessage(msg)
        this.received[msg.id] = msg
      }
      this.forceUpdate(() => {
        //debugger
        this.messages1.popScrollBottom()
        this.scrollBusy = false
      })
    } else {
      this.scrollBusy = false
    }
  }

  setSearchEditor = editor => {
    if (this.searchEditorSub) {
      this.searchEditorSub.unsubscribe()
      this.searchEditorSub = null
    }
    this.searchEditor = editor
    if (editor) {
      this.searchEditorSub = editor.observeIsEmpty().subscribe(isEmpty => {
        this.setState({
          searchCanApply: !isEmpty
        })
      })
    }
    this.forceUpdate()
  }

  setEditor = ref => {
    if (this.editorSub) {
      this.editorSub.unsubscribe()
    }
    if (ref) {
      this.state.editorCanApply = true
      this.editor = ref
      this.editorSub = this.editor.observeIsEmpty().subscribe(isEmpty => {
        this.setState({
          editorCanApply: !isEmpty 
        })
      })
    }
    this.forceUpdate()
  }
  
  clearEditor = () => {
    this.state.completions = []
    this.renderCurrentDocument()
  }

  isKeyboardShowing = () => !isDesktop() && this.state.textInputFocus

  undoEdit = async () => {
    //////////////debugger
    if (this.canUndoCurrentDocument()) {
      const doc = this.getCurrentDocument()
      doc.undo()
    } else {
      let i = this.getCurrentInstruction()
      this.state.variableToComplete = null
      if (i) {
        if (i.output) {
          this.state.edits.undo()
          i.undoOutput = i.output
          i.output = null
        } else if (i.inputText) {
          i.inputTemplate = null
          i.variableToComplete = null
          i.instruction = new Document(i.inputText)
          i.inputText = null
        } else {
          this.state.instructions.undo()
          i = this.getCurrentInstruction()
        }
        this.buildNextInstruction(i)
        this.renderCurrentDocument()
        return
      }
      if (this.state.instructions.canUndo()) {
        this.state.instructions.undo()
        const i = this.getCurrentInstruction()
        this.buildNextInstruction(i)
      } else {
        this.buildNextInstruction()
      }
    }
    this.renderCurrentDocument()
  }
  
  redoEdit = async () => {
    //////////////debugger
    const doc = this.getCurrentDocument()
    if (doc && doc.canRedo()) {
      doc.redo()
    } else {
      let i = this.getCurrentInstruction()
      if (i) {
        if (i.undoOutput) {
          i.output = i.undoOutput
          i.undoOutput = null
          this.state.edits.redo()
          this.buildNextInstruction()
        } else {
          this.state.instructions.redo()
          i = this.getCurrentInstruction()
          this.buildNextInstruction(i)
        }
      } else if (this.state.instructions.canRedo()) {
        this.state.instructions.redo()
        i = this.getCurrentInstruction()
        this.buildNextInstruction(i)
      }
    }
    this.renderCurrentDocument()
  }

  showKeyboard = () => {
    if (true) return
    if (false) {
      const back = () => {
        this.setState({
          subpage: null,
          popup: null
        })
      }
      this.setState({
        popup: () => <div className='chatGPTKeyboard'><Keyboard me={this.props.me} sendKeyboardOutput={this.sendKeyboardOutput} cancelKeyboardOutput={back} isWritingAssistant={true}/></div>
      })
    } else {
      this.setState({
        showKeyboard: true
      })
    }
  }
    
  setTextInputFocus = textInputFocus => {
    this.showKeyboard()
    let instructionFocus = this.state.instructionFocus
    if (textInputFocus) {
      instructionFocus = false
    } else {
      this.onBlur()
    }
    this.setState({
      textInputFocus,
      instructionFocus
    },() => {
      this.updateLang()
      if (this.editor.focused) {
        this.onInput()
      }
    })
    if (!instructionFocus && !textInputFocus) {
      //this.stopVoiceInput()
    }
  }
  renderCurrentDocument = () => {
    const text = this.renderText(this.getCurrentDocument())
    if (this.editor.setText(text)) {
      this.focusedText = undefined
    }
    this.forceUpdate(this.showLastInstruction)
    return text
  }

  copyToClipboard = async text => {
    try {
       navigator.clipboard.writeText(text)
    } catch (err) {
      console.error(err)
      console.log(text)
    }
    await delay(0.5)
  }

  copy = async () => {
    const text = this.editor.getText()
    return this.copyToClipboard(text)
  }

  renderText = (edit) => {
    return (edit && edit.getCurrent()) || ''
  }

  getCurrentDocument = () => {
    return this.state.edits.getCurrent()
  }

  cancel = () => {
    this.props.back()
  }

  goBack = async () => {
    this.setState({
      active: false,
      sendError: null
    })
    this.props.back()
  }

  canUndo = () => {
  }

  canRedo = () => {
  }


  updateLang = () => {
  }

  inputSeq = 0
  onInput = async e => {
    //debugLog("onInput", e)
    const seq = ++this.inputSeq
    if (this.state.completions.length > 0) {
      this.state.completions = []
      this.forceUpdate()
    }
    setTimeout(() => {
      if (this.inputSeq === seq) {
        this.setState({
          questionSearchTerm: this.editor.getText()
        })
      }
    }, 100)
  }

  clearError = () => {
  }

  onBlur = e => {
    this.state.editing = false
    this.state.completions = []
    const text = this.editor.getText()
    if (this.getCurrentText() == text) {
      //debugLog("onBlur no change")
      this.forceUpdate()
      return 
    }
    //debugLog("onBlur text changed")
    this.clearError()
    const doc = this.getCurrentDocument()
    ////////////////debugger
    this.forceUpdate()
  }

  getCurrentText = () => {
    const doc = this.getCurrentDocument()
    return doc ? doc.getCurrent() || "" : ""
  }

  getCurrentTopic = () => {
    let topic
    if (this.state.selectedThread) {
      topic = this.state.selectedThread.id 
    } else {
      let ts = 0
      let lastMessage
      for (const id in this.received) {
        const message = this.received[id]
        if (message.ts > ts) {
          ts = message.ts
          lastMessage = message
        }
      }
      if (lastMessage) {
        topic = lastMessage.topic
      }
    }
    if (!topic) {
      if (this.state.selectedTask && this.state.selectedTask.lastTopic) {
        topic = this.state.selectedTask.lastTopic.id
      }
    }
    return topic
  }

  waitForUploads = () => {
    return new Promise((resolve, reject) => {
      const checkForUploadsDone = () => {
        if (this.state.uploads.length === 0) {
          resolve()
        } else {
          setTimeout(checkForUploadsDone, 500)
        }
      }
      checkForUploadsDone()
    })
  }

  getContent = () => {
    let content  = []
    let result = ''
    const flush = () => {
      if (result) {
        result = result.replace(/\n•[ ]+/g, "\n•  ");
        content.push({
          type: "text",
          text: result
        })
        result = ''
      }
    }
    const apply = node => {
      walkDOM(node, n => {
        if (n instanceof HTMLVideoElement) {
        }
        else if (n instanceof HTMLImageElement) {
          const url = n.src
          flush()
          content.push({
            type: "image_url",
            image_url: {
              url
            }
          })
        } else if (n.nodeName == "WBR") {
        } else if (n.nodeName == "BR") {
          result += "\n";
        } else if (n.nodeType == 3 && !(n.parentElement instanceof HTMLSpanElement)) {
          let textContent = n.textContent.replace(nbsp, " ");
          result += textContent;
          if (n.parentElement !== node && n.parentElement instanceof HTMLDivElement) {
            result += '\n'
          }
        }
      })
    }
    apply(this.editor.getNode())
    return content
  }

  getSelectedModelIds = () => {
    return (this.modelsView && this.modelsView.getSelectedModelIds()) || []
  }

  modelsById = {}

  getModels = () => {
    if (!this.models) {
      this.models = this.props.models(() => true, () => {}).filter(x => {
        return x.getSize
      })
      this.modelsById = {}
      this.models.forEach(model => {
        this.modelsById[model.id] = model
      })
    }
    return this.models
  }

  sendChat = async (previous) => {
    if (this.props.availableCredits === 0) {
      this.setState({
        sendError: "You're out of credits!"
      })
//      return
    }
    if (this.state.judgeChat) {
      let message
      if (this.state.swipeMessage) {
        message = this.state.swipeMessage
      } else {
        message = previous
      }
      return await this.judge(message)
    }
    await this.waitForUploads()
    ////////////debugger
    let html = this.editor.getHTML()
    let text = turndownService.turndown('<pre>' + html + '</pre>')
    ////console.log('text', text)
    const model = this.getSelectedModelIds()
    if (previous || (text && Object.keys(model).length > 0)) {
      const contents = this.getContent()
      this.state.questionSearchTerm = ''
      if (this.state.selectedThread) {
        this.messages2.scrollToBottom()
      } else {
        this.messages1.scrollToBottom()
      }
      let topic = this.getCurrentTopic()
      let isNewTopic = false
      if (topic === 'new-thread') {
        topic = undefined
        isNewTopic = true
      }
      let task = this.state.selectedTask
      if (!task) {
        // this is the user's first task
        task = this.props.me.createNewTask()
        this.selectThread(task)
        isNewTopic = true
      }
      const sent = Date.now()
      const models = this.getSelectedModelIds()
      const attunewise = models.indexOf('attunewise') >= 0
      let msg
      if (previous) {
        msg = previous
        for (const id in this.received) { // hide the previous reply
          const reply = this.received[id]
          if (reply.inReplyTo === msg.id) {
            delete this.received[id]
            delete this.cache[id]
          }
        }
      } else {
        msg = this.props.me.createNewMessage({ text, contents, models, attunewise, task: task.id })
        this.received[msg.id] = msg
        if (this.state.selectedThread) {
          this.state.searchResultsBusy = true
          this.state.searchResults.push(msg)
        }
      }
      this.forceUpdate()
      let spoken = 0
      let completeText = ''
      const getMessage = () => {
        return this.received[msg.id + ".reply"]
      }
      const getReplyMessage = () => {
        let found = getMessage()
        if (!this.state.selectedThread) {
          if (found.inReplyTo) {
            return found
          }
          return this.wasAnswered(found)
        } else {
          let id = found.inReplyTo || found.id
          return this.state.searchResults.find(x => x.inReplyTo === id)
        }
      }
      const flushToSpeaker = async (isFinal) => {
        if (this.recognition && isFinal) {
          const reply = getReplyMessage()
          consoleLog("GOT REPLY", reply)
          this.state.speaking = reply.id
          let lang = this.state.lang.iso
          this.props.me.resetAudioSource()
          this.recognition.stop()
          this.forceUpdate()
          return this.props.me.speak(completeText, lang).then(() => {
            this.state.speaking = null
            this.forceUpdate()
            this.recognition.start()
          })
        }
      }
      this.streamSent = msg.sent
      this.setState({
        sendError: null,
        isStreaming: true,
        sending: true
      })
      this.clearEditor()
      try {
        this.messages1.autoScroll = true
        if (!previous) {
          const MAX_INLINE_LENGTH = 2048
          if (msg.content.length > MAX_INLINE_LENGTH) {
            const ref = await this.props.me.uploadMessageContent(msg.id, msg.content)
            msg.storage = ref.fullPath
            msg.contentLength = msg.content.length
            msg.content = msg.content.substring(0, MAX_INLINE_LENGTH)
            const newLine = msg.content.lastIndexOf('\n')
            if (newLine > msg.content.length / 2) {
              msg.content = msg.content.substring(0, newLine)
            }
          }
        }
        this.xhr = await this.props.streamChat(this, msg, {
          model,
          temperature: this.state.temperature,
          assistantModel: this.state.assistantModel,
          onContent: snip => {
            ////console.log(JSON.stringify(snip, null, ' '))
            if (this.state.sending) {
              this.state.sending = false
              this.forceUpdate()
            }
            const { choices, model } = snip
            const choice = choices[0]
            if (choice) {
              const text = choice.delta.text || choice.delta.content || ''
              const found = getMessage()
              if (found && text) {
                //console.log('updating', model, text)
                let target
                if (model === found.model) {
                  if (!found.content) {
                    found.content =  ''
                  }
                  found.content += text
                  target = found
                } else {
                  let { models } = found
                  if (!models) {
                    found.models = models = []
                  } else {
                    target = models.find(x => x.model === model)
                  }
                  if (!target) {
                    models.push(target = {
                      model,
                      content: text
                    })
                  } else {
                    if (!target.content) {
                      target.content = text
                    } else{ 
                      target.content += text
                    }
                  }
                }
                ////console.log('updated', model, target)
                flushToSpeaker()
                delete this.cache[found.inReplyTo]
                delete this.cache[found.id]
                this.forceUpdateLater()
              } else {
                ////////////debugger
              }
            }
          },
          onDone: () => {
            this.xhr = null
            const found = getMessage()
            delete this.cache[found.id]
            this.state.isStreaming = false
            this.forceUpdate(() => {
              this.messages1.autoScroll = true
            })
          },
          onError: (err, status) => {
            console.error(err)
            this.state.isStreaming = false
            if (status === 400) {
              this.setState({
                sendError: "Oof, sorry that didn't work."
              })
            } else {
              this.setState({
                sendError: 'Server unreachable. Please try again later.'
              })
            }
            this.state.sending = false
            this.state.isStreaming = false
            this.forceUpdate(() => {
              this.messages1.autoScroll = true
            })
          }
        })
      } catch (err) {
        console.error(err)
        this.setState({
          sendError: "Oof, sorry that didn't work."
        })
        this.state.isStreaming = false
        this.forceUpdate()
      }
    }
    if (isMobile()) {
      this.editor.blur()
    }
  }

  forceUpdateLater = () => {
    clearTimeout(this.forceUpdateTimeout)
    this.forceUpdateTimeout = setTimeout(() => {
      this.forceUpdate()
    }, 33)
  }

  pauseChat = async () => {
    const xhr = this.xhr
    const sent = this.streamSent
    consoleLog({xhr, sent})
    this.xhr = null
    this.streamSent = 0
    if (xhr) {
      xhr.abort()
      consoleLog("aborted xhr")
    }
  }

  notEnoughTokens = instruction => {
  }

  getThreads = () => {
    let threads
    if (this.state.searchResults.length > 0) {
      threads = this.state.searchResults
    } else {
      threads = Object.values(this.tasks)
      threads.sort((x, y) => {
        return y.lastUpdated - x.lastUpdated
      })
      let filt
      switch(this.state.calendarView) {
        case 'recent':
          {
            threads = threads.slice(0, 15)
            break
          }
        case 'week':
          {
            const t = startOfWeek(this.state.selectedDay).getTime()
            filt = x => {
              const { lastUpdated } = x
              return startOfWeek(lastUpdated).getTime() === t
            }
          }
          break
        case 'day':
          {
            const t = startOfDay(this.state.selectedDay).getTime()
            filt = x => {
              const { lastUpdated } = x
              return startOfDay(lastUpdated).getTime() === t
            }
          }
          break
        default:
          debugger
      }
      if (filt) {
        const selectedId = this.state.selectedThread && this.state.selectedThread.id
        threads = threads.filter(x => x.id === selectedId || filt(x))
      }
    }
    return threads
  }

  setAutocomplete = ref => {
    this.autocomplete = ref
    if (this.inputRef && ref) {
      ref.setInput(this.inputRef)
    }
  }

  selectThread = threadOrTask => {
    let thread
    let task
    let lastTask = this.state.selectedTask
    task = threadOrTask
    if (task && this.state.selectedTask && this.state.selectedTask.id === task.id) return
    localStorage.setItem('selectedTaskId', task ? task.id : '')
    if (task) {
      let { lastTopic } = task
      if (lastTopic) {
        thread = {
          id: lastTopic.topicId,
          topic: lastTopic.topic,
          lastUpdated: task.lastUpdated
        }
      } else {
        //////////debugger
      }
      //this.searchEditor.clear()
      this.setState({
        threadBusy: false,
        selectedTask: task,
        selectedThread: thread
      },() => setTimeout(this.initSelectedTask, 250))
    } else {
      this.setState({
        threadBusy: false,
        selectedTask: null,
        selectedThread: null
      })
      setTimeout(this.deinitSelectedTask, 550)
      if (lastTask) {
        if (this.tasks[lastTask.id]) {
          this.props.me.updateTaskSummary(lastTask)
        }
      }
    }
  }

  seqNum = 0
  search = async searchTerm => {
    this.state.searchTerm = searchTerm.trim()
    this.forceUpdate()
    this.performSearch()
  }

  parseMessage = message => {
    let text = message.content
    if (text) {
      const code = parseCode(text)
      if (code) {
        message.code = code
      } else {
        const table = parseTable(text)
        if (table) {
          message.table = table
        }
      }
    } else {
      message.text = ''
    }
    if (message.inReplyTo) {
      for (const m of Object.values(this.received)) {
        if (m.id === message.inReplyTo || m.inReplyTo === message.inReplyTo) {
          delete this.cache[m.id]
        }
      }
    }
  }

  performSearch = async () => {
    const seq = ++this.seqNum
    const searchTerm = this.state.searchTerm
    let topic = this.state.selectedThread ? this.state.selectedThread.id : ''
    if (searchTerm || topic) {
      this.state.searching = true
      if (!this.state.searchTerm) {
        this.state.threadBusy = true
      }
      this.forceUpdate()
      let searchResults
      if (this.state.slide > 0.5) {
        let { results, page, out_of }  = await this.props.searchChatMessages(searchTerm)
        results.forEach(message => {
          this.parseMessage(message)
        })
        searchResults = results
      } else {
        let { results, page, out_of }  = await this.props.searchTasks(searchTerm)
        ////console.log("SEARCH", seq, this.seqNum, results)
        searchResults = results
      }
      if (seq === this.seqNum) {
        this.setState({
          searching: false,
          threadBusy: false,
          searchResults
        })
      }
    } else {
      this.setState({
        searching: false,
        searchResults: [],
        threadBusy: false
      })
    }
  }

  onKeyDown = e => {
    const RETURN = "Enter";
    if (isDesktop()) {
      if (e.key === RETURN && !e.shiftKey) {
        e.preventDefault()
        this.sendChat()
      }
    }
  }

  selectModel = (model) => {
    ////console.log('selectModel', model)
    if (this.state.selectedModels[model]) {
      delete this.state.selectedModels[model]
    } else {
      this.state.selectedModels[model] = true
    }
    ////console.log(this.state.selectedModels)
    //localStorage.setItem('selectedModels', JSON.stringify(this.state.selectedModels))
    this.forceUpdate(this.saveOptionsLater)
  }

  isModelSelected = (model) => {
    return this.state.selectedModels[model]
  }

  renderDiscussionSearchField = () => {
    let busy = this.state.searching && this.state.searchTerm
    let searchTerm = ''
    const clear = () => {
      // fixme!!
      this.searchEditor.clear()
      this.search('')
    }
    const onFocus = () => {
    }
    const onBlur = () => {
    }
    const onInput = () => {
      this.search(this.searchEditor.getText())
    }
    const selectThread = thread => {
      this.selectThread(thread)
    }
    let icon
    let label
    let action
    action = async () => {
      const defaultAction = () => {
        if (this.state.selectedTask) {
          const task = this.state.selectedTask
          this.props.onCloseTask(this, task)
          this.selectThread(null)
        } else {
          if (this.props.newTopic) {
            this.props.newTopic(this)
            return
          }
          if (this.props.onNewFile) return
          const task = this.props.createNewTask()
          if (task) {
            if (this.props.onOpenTask) {
              this.props.onOpenTask(this, task)
            }
            this.selectThread(task)
          }
        }
      }
      if (this.props.onBack) {
        this.props.onBack(this, defaultAction)
      } else {
        defaultAction()
      }
    }
    if (this.state.slide < 0.5) {
      label = 'New'
      icon = Hashtag
    } else {
      label = 'Back'
      icon = Left
    }
    const newTopicButton = <KeyboardButton className={'newTopicButton'} icon={icon} action={action} label={label}/>
    const selectedThread = this.state.selectedThread
    ////console.log("slide", this.state.slide)
    let threads = this.getThreads()
    const style = {
      //transform: `translate(calc(${1.0-this.state.slide} * -100%), 0)`,
      //display: this.state.slide === 0 ? 'none': undefined
    }
    let style2 
    const selectedModelIds = this.getSelectedModelIds()
    const selectedModelCount = selectedModelIds.length

    let middleLeft = newTopicButton
    if (this.props.onNewFile && !this.state.selectedTask) {
      const openImage = async (event) => {
        this.newFileChooser.click()
      }
      const handleImage = async (event) => {
        this.handleDataTransfer(event, event.target)
      }
      const fileChooser = 
        <div className='fileChooser' onClick={openImage}>
          <input
            ref={this.setNewFileChooser}
            type={"file"}
            accept={this.props.newFiletypes}
            style={{ display: 'none' }} // Hides the file input
            onChange={handleImage}
          />                      
          {newTopicButton}
        </div>
      middleLeft = fileChooser
    }

    let inputEnabled = true
    if (this.props.enableInput) {
      inputEnabled = this.props.enableInput(this)
    }
    let filler = ''

    const openSettings = async () => {
      this.setState({showSettings: !this.state.showSettings})
    }

    const setTemp = ({value}) => {
      //console.log("setTemp", value)
      localStorage.setItem('temp', value.toString())
      this.setState({temperature:value})
    }
    const getTemp = () => this.state.temperature

    const toggleSize = (size) => {
      if (this.state.sizes[size]) {
        delete this.state.sizes[size]
      } else {
        this.state.sizes[size] = true
      }
      this.forceUpdate()
    }

    const toggleSmall = () => toggleSize('small')
    const toggleMedium = () => toggleSize('medium')
    const toggleLarge = () => toggleSize('large')
    //<div className='chatBack'><KeyboardButton icon={Left} label={'Back'} action={action}/></div>
    return <div key='discussionSearch' className='keyboardInstructionInput discussionSearch'>
             <div className='chatHeader'>
               <div className='chatHeaderFiller'>{filler}</div>
               <div className='inputControlContainer'>
                 <InputControl
                   busy={busy}
                   middleLeft={middleLeft}
                   me={this.props.me}
                   placeholder={'Search'}
                   onSetEditor={this.setSearchEditor}
                   downward={true}
                   onClear={clear}
                   onFocus={onFocus}
                   onBlur={onBlur}
                   onInput={onInput}
                 />
               </div>
             </div>
           </div>
  }

  renderSearchField = (showing) => {
    let busy = this.state.searching && this.state.searchTerm
    let searchTerm = ''
    const clear = () => {
      // fixme!!
      this.searchEditor.clear()
      this.search('')
    }
    const onFocus = () => {
    }
    const onBlur = () => {
    }
    const onInput = () => {
      this.search(this.searchEditor.getText())
    }
    const selectThread = thread => {
      this.selectThread(thread)
    }
    let icon
    let label
    let action
    action = async () => {
      const defaultAction = () => {
        if (this.state.selectedTask) {
          const task = this.state.selectedTask
          this.props.onCloseTask(this, task)
          this.selectThread(null)
        } else {
          if (this.props.newTopic) {
            this.props.newTopic(this)
            return
          }
          if (this.props.onNewFile) return
          const task = this.props.createNewTask()
          if (task) {
            if (this.props.onOpenTask) {
              this.props.onOpenTask(this, task)
            }
            this.selectThread(task)
          }
        }
      }
      if (this.props.onBack) {
        this.props.onBack(this, defaultAction)
      } else {
        defaultAction()
      }
    }
    if (this.state.slide < 0.5) {
      label = 'New'
      icon = Hashtag
    } else {
      label = 'Back'
      icon = Left
    }
    const newTopicButton = <KeyboardButton className={'newTopicButton'} icon={icon} action={action} label={label}/>
    const selectedThread = this.state.selectedThread
    ////console.log("slide", this.state.slide)
    let threads = this.getThreads()
    const style = {
      //transform: `translate(calc(${1.0-this.state.slide} * -100%), 0)`,
      //display: this.state.slide === 0 ? 'none': undefined
    }
    let style2 
    const selectedModelIds = this.getSelectedModelIds()
    const selectedModelCount = selectedModelIds.length

    let middleLeft = newTopicButton
    if (this.props.onNewFile && !this.state.selectedTask) {
      const openImage = async (event) => {
        this.newFileChooser.click()
      }
      const handleImage = async (event) => {
        this.handleDataTransfer(event, event.target)
      }
      const fileChooser = 
        <div className='fileChooser' onClick={openImage}>
          <input
            ref={this.setNewFileChooser}
            type={"file"}
            accept={this.props.newFiletypes}
            style={{ display: 'none' }} // Hides the file input
            onChange={handleImage}
          />                      
          {newTopicButton}
        </div>
      middleLeft = fileChooser
    }

    let inputEnabled = true
    if (this.props.enableInput) {
      inputEnabled = this.props.enableInput(this)
    }

    let filler = ''
    if (showing && this.state.slide > 0) {
      style2 = {
        position: 'static'
      }
    }


    const openSettings = async () => {
      this.setState({showSettings: !this.state.showSettings})
    }

    const setTemp = ({value}) => {
      //console.log("setTemp", value)
      localStorage.setItem('temp', value.toString())
      this.setState({temperature:value})
    }
    const getTemp = () => this.state.temperature

    const toggleSize = (size) => {
      if (this.state.sizes[size]) {
        delete this.state.sizes[size]
      } else {
        this.state.sizes[size] = true
      }
      this.forceUpdate()
    }
    let deleteButton
    const thread = this.state.selectedTask
    let deleteIcon = thread.busy ? Spin : Trash
    if (this.state.confirmDelete === thread.id) {
      const cancelDelete = async () => {
        this.state.confirmDelete = null
        this.forceUpdate()
      }
      deleteButton = <ClickAwayListener onClickAway={cancelDelete}>
                       <div className='threadDeleteButtonConfirm' onClick={trash}>Confirm Delete<div className='keyboardMenuItemIcon'><ReactSVG src={deleteIcon}/></div></div>
                     </ClickAwayListener>
      
    } else {
      deleteButton = trash && <div className='threadDeleteButton'><KeyboardButton1 keepFocus action={trash} icon={Trash}/></div>
    }

    //<div className='chatBack'><KeyboardButton icon={Left} label={'Back'} action={action}/></div>
    return <div key='nextInstruction' className='keyboardInstructionInput'>
             {
              <div className='chatHeader' style={style}>
                <div className='chatHeaderFiller'>{filler}</div>
                {this.state.showSettings && <div className='tempSlider'><div className='tempLabel'>Temp:</div><Slider onChange={setTemp} value={getTemp()}/></div>}
                {inputEnabled && this.state.slide > 0 && <KeyboardButton1 className='gear' icon={Settings} action={openSettings}/>}
                {inputEnabled && <div className='chatHeaderSelectedModels'>{selectedModelCount}</div>}
                {deleteButton}
              </div>}
             <div className='inputControlContainer' style={style2}>
             <InputControl
               busy={busy}
               middleLeft={middleLeft}
               me={this.props.me}
               placeholder={'Search'}
               onSetEditor={this.setSearchEditor}
               downward={true}
               onClear={clear}
               onFocus={onFocus}
               onBlur={onBlur}
               onInput={onInput}
             />
             </div>
             </div>
  }


  setNewFileChooser = ref => { this.newFileChooser = ref }


  deleteTask = async task => {
    await this.props.deleteTask(this, task) 
    delete this.tasks[task.id]
    this.forceUpdate()
  }


  threadsHistBusy = false
  getThreadsHistory = async () => {
    if (!this.tasks) return
    if (this.threadsHistBusy) return
    this.threadsHistBusy = true
    let lastUpdated = Date.now()
    for (const id in this.tasks) {
      const task = this.tasks[id]
      lastUpdated = Math.min(task.lastUpdated, lastUpdated)
    }
    const results = await this.props.me.getThreadsHistory(lastUpdated, 10)
    debugger
    for (const task of results) {
      this.tasks[task.id] = task
    }
    this.threadsHistBusy = false
    this.forceUpdate()
  }

  toggleTextFieldSpeechInput = async () => {
    const textFieldSpeechInputActive = !this.state.textFieldSpeechInputActive
    if (this.state.voiceRecognitionActive) {
      this.toggleVoiceRecognition()
    }
    this.setState({
      textFieldSpeechInputActive,
      instructionSpeechInputActive: false
    }, () => {
      if (textFieldSpeechInputActive) {
        this.toggleVoiceRecognition()
      }
    })
  }
  
  toggleVoiceRecognition = async e => {
    if (e) e.preventDefault()
    ////////////////debugger
    this.state.voiceRecognitionActive = !this.state.voiceRecognitionActive
    if (this.recognition) {
      this.recognition.stop()
      this.recognition = null
    }
    if (!this.state.voiceRecognitionActive) {
      this.setState({
        instruction: null
      })
      if (this.sub3) {
        this.sub3.unsubscribe()
        this.sub3 = null
      }
      if (this.sub4) {
        this.sub4.unsubscribe()
        this.sub4 = null
      }
    } else {
      this.recognition = this.props.me.getVoiceRecognizer()
      this.sub3 = this.recognition.observeIsActive().subscribe(isActive => {
        if (this.state.voiceRecognitionActive !== isActive) {
          ////console.log("isActive", isActive)
          this.state.voiceRecognitionActive = isActive
          this.forceUpdate()
        }
      })
      this.sub4 = this.recognition.observeInstruction().subscribe(instruction => {
        ////console.log("instruction", instruction)
        this.receiveVoiceInput(instruction)
      })
      this.updateLang()
      this.recognition.start()
    }
    this.forceUpdate(this.updateLang)
  }

  selectTextInputLang = lang => {
    localStorage.setItem('keyboard.text.lang', JSON.stringify(lang))
    this.setState({
      lang
    }, this.updateLang)
  }
  
  updateLang = () => {
    if (this.state.instructionSpeechInputActive) {
      if (this.recognition) {
        this.recognition.setLang(this.state.instructionLang.iso)
      }
    }
    else if (this.state.textFieldSpeechInputActive) {
      if (this.recognition) {
        this.recognition.setLang(this.state.lang.iso)
      }
    } else {

    }
  }

  speakText = async (cancel) => {
    if (cancel) {
      this.props.me.cancelSpeak()
    } else {
      const text = this.editor.getText()
      this.props.me.resetAudioSource()
      await this.props.me.speak(text, this.state.lang.iso)
    }
  }

  receiveVoiceInput = async input => {
    consoleLog("voice input", input)
    if (this.state.instructionSpeechInputActive) {
      const { instruction } = this.state.nextInstruction
      const editor = this.instructionEditor
      editor.insertTextAtCaret(input)
      const text = editor.getText()
      instruction.advance(text)
      const { isComplete, corrected } = await this.props.me.autocorrect({input: text, lang: this.state.instructionLang.iso})
      if (corrected && text != corrected) {
        instruction.undo()
        instruction.advance(corrected)
        editor.setText(corrected)
      }
    } else if (this.state.textFieldSpeechInputActive) {
      this.editor.insertTextAtCaret(input)
      const text = this.editor.getText()
      const data  = await this.props.me.autocorrect({input: text, lang: this.state.lang.iso})
      const { isComplete, corrected } = data
      if (corrected && text != corrected) {
        this.editor.setText(corrected)
      }
      if (isComplete && !this.state.isStreaming) {
        this.sendChat()
      }
    } else {
      console.error("voice input fail")
    }
    this.forceUpdate()
  }

  factCheck = async (id) => {
    return await this.props.me.factCheck(id)
  }

  uploadFile = async file => {
    const upload = {
      file: file,
      progress: 0,
      blobUrl: URL.createObjectURL(file)
    }
    const progress = percent => {
      upload.progress = percent;
      this.forceUpdate();
    }
    this.state.uploads.push(upload)
    this.forceUpdate()
    let img
    if (file.type && file.type.startsWith("image/")) {
      const url = URL.createObjectURL(file);
      img = this.editor.insertImage(url)
      if (isDesktop()) {
        this.editor.focus()
      }
    }
    const name = file.name.toLowerCase();
    //////debugger
    if (name.endsWith(".mov")) {
      try {
        file = new File(file, name.replace(".mov", ".mp4"));
      } catch (err) {
        this.props.me.nativeLog(err)
      }
    }
    try {
      const ref = await this.props.uploadFile(file, progress, false)
      if (img) {
        //////debugger
        img.src = await ref.getDownloadURL()
        this.forceUpdate()
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.setState({
        uploads: this.state.uploads.filter(x => x.file != file),
      })
    }
  }

  handleDataTransfer = (event, transfer)=> {
    if (transfer.files.length > 0) {
      event.preventDefault();
      for (const file of transfer.files) {
        this.uploadFile(file);
      }
      return true;
    }
    if (false) {
      let plainText = e.clipboardData.getData('text/plain')
      if (!plainText) {
        const text = e.clipboardData.getData('text/html')
        plainText = makeTextPlain(text)
      }
      if (plainText.length > 100 * 1000) {
        event.preventDefault()
        this.props.me.uploadMessageContent(plainText)
        return true
      }
    }
    return false;
  }
  
  onPaste = e => {
    if (this.handleDataTransfer(e, e.clipboardData)) {
      return
    }
  }
  
  onUpdate = e => {
  }

  onDrop = e => {
    const transfer = e.dataTransfer;
    this.handleDataTransfer(e, transfer);
  }

}

