import React, { Component } from 'react'
import AudioMotionAnalyzer from 'audiomotion-analyzer'

export class SpectrumAnalyzer extends Component {
  
  constructor (props) {
    super(props)
    this.active = false
  }
  
  setRef = ref => {
    this.ref = ref
    if (ref) {
      this.createAudioMotion(ref)
    }  else {
      this.setActive(false)
    }
  }
  
  render() {
    return <div className='micSpectrumAnalyzerContainer'>
             <div ref={this.setRef} className='micSpectrumAnalyzer'>
             </div>
           </div>
  }
  
  componentDidMount() {
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }
  
  componentWillUnmount() {
    this.setActive(false)
  }
  
  createAudioMotion = (ref) => {
    this.audioMotion = new AudioMotionAnalyzer(ref, {
      gradient: 'classic',
      height: 35,
      showScaleY: false,
      showScaleX: false,
      showBgColor: false,
      overlay: true,
      mode: 1,
    })
  }

  setActive = active => {
    if (this.active != active) {
      this.active = active
      if (active) {
        this.connectToMic()
      } else {
        this.disconnect()
      }
    }
  }
  
  disconnect = () => {
    this.setStream(null)
  }
  
  setStream = stream => {
    console.log("SET STREAM", stream)
    if (this.stream) {
      this.disconnectAudioMotion()
      this.stream.getTracks().forEach(track => track.stop())
      this.stream = null
    }
    this.stream = stream
    if (stream) {
      this.connectAudioMotion()
    }
  }

  disconnectAudioMotion = () => {
    if (this.audioMotion) {
      this.audioMotion.disconnectInput()
    }
  }
  
  connectAudioMotion = () => {
    const audioMotion = this.audioMotion
    // create stream using audioMotion audio context
    const micStream = audioMotion.audioCtx.createMediaStreamSource( this.stream )
    // connect microphone stream to analyzer
    audioMotion.connectInput( micStream )
    // mute output to prevent feedback loops from the speakers
    audioMotion.volume = 0
  }
  
  connectToMic = async () => {
    const stream =
          await navigator.mediaDevices.getUserMedia( { audio: true, video: false } )
    if (this.active) {
      this.setStream(stream)
    } else {
      stream.getTracks().forEach(track => track.stop())
    }
  }
}

