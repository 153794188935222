import React, { Component } from 'react';
import './Slider.css'

export class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false
    };
    this.sliderRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateSliderPosition()
    }
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
    document.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    document.addEventListener('touchend', this.handleTouchEnd);
    this.updateSliderPosition();
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('touchend', this.handleTouchEnd);
  }

  handleTouchStart = (event) => {
    this.setState({ dragging: true });
    event.preventDefault(); // Prevent default scroll behavior and zooming
  };

  handleTouchEnd = () => {
    if (this.state.dragging) {
      this.setState({ dragging: false });
    }
  };

  handleTouchMove = (event) => {
    if (this.state.dragging) {
      // Prevent the default touch action (scroll/zoom).
      event.preventDefault();
      const touch = event.touches[0];
      this.processSlide(touch.clientX);
    }
  };

  processSlide = (clientX) => {
    const slider = this.sliderRef.current;
    const rect = slider.getBoundingClientRect();
    const newPercentage = (clientX - rect.left) / rect.width;
    console.log("slide", newPercentage)
    this.props.onChange({value: Math.min(Math.max(newPercentage, 0), 1)})
  };

  handleMouseDown = (event) => {
    this.setState({ dragging: true });
    event.preventDefault(); // Prevent default drag behavior

  };

  handleMouseUp = () => {
    if (this.state.dragging) {
      this.setState({ dragging: false });
    }
  };

  handleMouseMove = (event) => {
    console.log("mouse move")
    if (this.state.dragging) {
      const slider = this.sliderRef.current;
      const rect = slider.getBoundingClientRect();
      const newPercentage = (event.clientX - rect.left) / rect.width;
      this.props.onChange({ value: Math.min(Math.max(newPercentage, 0), 1) })
    }
  };

  setKnob = ref => {
    this.knob = ref
  }

  updateSliderPosition = () => {
    const slider = this.sliderRef.current;
    const knob = this.knob
    const percentage = this.props.value * 100;
    console.log("updateSlider", this.props.value, percentage)
    knob.style.left = `calc(${percentage}% - 10px)`; // Adjust 10px for the knob's half-width
  }

  handleSliderClick = (event) => {
    const slider = this.sliderRef.current;
    const newPercentage = (event.clientX - slider.offsetLeft) / slider.offsetWidth;
    this.props.onChange({ value: newPercentage })
  };

  render() {
    let className = 'sliderButtonContainer'
    if (this.props.value >= 0.5) {
      className += ' sliderButtonContainerHigh'
    }
    return (
      <div className='slider'
           ref={this.sliderRef}
           onTouchStart={this.handleTouchStart}
           onMouseDown={this.handleMouseDown}
      >
        <div className={className}>
          <div className='sliderButton' ref={this.setKnob}/>
          <div className='sliderValue'>{Math.round(this.props.value*100)}</div>
        </div>
      </div>
    );
  }
}


