import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import remarkGFM from 'remark-gfm'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

function convertBracketsToDollars(text) {
  return text.replaceAll('\\[', '$$$$').replaceAll('\\]', '$$$$')
}

export const containsLatex = (text) => {
  text = preprocessLaTeX(text)
  const latexPatterns = [/\$\\triangle/,/\$\$[^]+\$\$/, /$[^0-9]/, /\$[^$]*\S\$/, /[$][\\]/,/\\begin{.*}.*\\end{.*}/, /\\(frac|log_|text|times|textit|textbf){/, /\\times/, /\\(to|boxed)/, /\\cup/, /\\(le|ge)/, /\\\(|\\\)/, /[\$[^ ]+[{$]/];
  let result = latexPatterns.find((pattern, i) => {
    const matched = pattern.test(text)
    if (matched && i === 3 ) {
      const segments = classifySegments(text)
      //console.log('segments', JSON.stringify(segments))
      for (const segment of segments) {
        const { token, type } = segment
        if (token.indexOf('$') >= 0) {
          if (type !== 'latex') {
            return false
          }
        }
      }
      //console.log('matched', pattern, matched, text)
    }
    return matched
  }) ? text : undefined
  if (result) {
    //console.log('containsLatext', result, text)
  }
  return result
}

// Function to classify segments of text
function classifySegments(text) {
    const monetaryPattern = /\$\d+/g;
    const latexPattern = /\$[^\$]+\$/g;

    // Find all matches for monetary amounts and LaTeX expressions
    const monetaryMatches = text.match(monetaryPattern) || [];
    const latexMatches = text.match(latexPattern) || [];

    // Classify text based on matches
    const classifiedText = text.split(' ').map(token => {
        if (monetaryMatches.includes(token)) {
            return { token, type: 'monetary' };
        } else if (latexMatches.some(expr => expr.includes(token))) {
            return { token, type: 'latex' };
        } else {
            return { token, type: 'text' };
        }
    });

    return classifiedText;
}

export const containsMarkdown = (text) => {
  const markdownPatterns = [/^#+\s/, /^[*-]\s/, /\[.*\]\(.*\)/, /[*][*]/, /##/, /[*][^*]+[*]/, /```/];
  return markdownPatterns.some(pattern => {
    const result = pattern.test(text)
    if (result) {
      console.log("matched", pattern, text)
    }
    return result
  });
}

const preprocessLaTeX = (content) => {
  // Replace block-level LaTeX delimiters \[ \] with $$ $$
  const blockProcessedContent = content.replace(
    /\\\[(.*?)\\\]/gs,
    (_, equation) => `$$${equation}$$`,
  );
  // Replace inline LaTeX delimiters \( \) with $ $
  const inlineProcessedContent = blockProcessedContent.replace(
    /\\\((.*?)\\\)/gs,
    (_, equation) => `$${equation}$`,
  );
  if (content !== inlineProcessedContent) {
    //console.log("CONVERTED", inlineProcessedContent)
    return inlineProcessedContent
  }
  return content
}

export class Markdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    let text = ""+this.props.children
    let katex
    let latex
    latex = containsLatex(text)
    let plugins = [remarkGFM]
    if (latex) {
      text = latex
      katex = [rehypeKatex]
      plugins = plugins.concat([[remarkMath, { singleDollarTextMath: true}]])
    } else {
      if (text.indexOf("$") >= 0) {
        //console.log("!containsLatex", text)
      }
    }
    if (!katex && !containsMarkdown(text)) {
      //return text
    }
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return text
    }
    //console.log("TEXT", text)
    // Example heuristic function to detect LaTeX
    
    //text = text.replace(/\n\n/g, '\n  \n')
    //text = text.replace(/\n\n/g, '  \n  \n')

    console.log("MARKDOWN", text)
    return <div className='markdownContainer'>
             <ReactMarkdown
               components={this.props.components}
               remarkPlugins={plugins}
               rehypePlugins={katex}>
               {text}
             </ReactMarkdown>
             </div>
    return this.props.children; 
  }
}



 
