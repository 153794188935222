import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import Popup from 'reactjs-popup';
import { BnSubpage } from '../Page'
import Image from '../../assets/Icons/Image.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import CheckMark from '../../assets/Icons/Tick.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import MenuDown from '../../assets/Icons/MenuDown.svg'
import { KeyboardButton, KeyboardButton1 } from '../Keyboard'
import Alert from '../../assets/Icons/Alert.svg'
import { Checkbox, SimpleButton, SimpleIcon } from './index.js'
import './ModelsMenu.css'

export const formatPrice = (price, N = 2) => {
  // Convert the price to a string and split at the decimal point
  let priceStr = price.toString();
  let [wholePart, decimalPart] = priceStr.split('.');
  
  if (!decimalPart) {
    return wholePart 
  }

  // Count leading zeros in the decimal part
  let leadingZeros = decimalPart.match(/^0*/)[0].length;
  
  // Calculate the total number of decimal places to keep
  let totalDecimals = leadingZeros + N;
  
  // Format the price with the calculated number of decimals
  let formattedPrice = price.toFixed(totalDecimals);
  
  // Remove trailing zeros
  formattedPrice = parseFloat(formattedPrice).toString();
  
  return formattedPrice;
}

export class Model extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }
  }
  render() {
    const { model, single } = this.props
    let { id, title, label, select, isSelected, getIcon, vendor, getModelIcon, vision, legacy } = model
    label = title
    const { busy } = this.state
    let iconStyle
    let icon = getModelIcon ? getModelIcon() : null
    if (single) {
      icon = icon || model.getIcon()
    } else {
      iconStyle = getModelIcon && getModelIcon() ? null : { visibility: 'hidden', maxWidth: 15 }
    }
    let className= 'model'
    if (isSelected()) className += '  modelRadioButtonSelected'
    if (!single) {
      className += ' modelSubmenu'
    }
    const format = this.props.formatPrice || formatPrice
    let price = ''
    const { input, output } = this.props.getPrice(model)
    price = "$" + format(input) + "/" + format(output)
    if (legacy) {
      className += ' modelLegacy'
    }
    return <div className={className} onClick={select}>
             <div className='modelLeft'>
               <div className='modelIcon' style={iconStyle}>
                 <ReactSVG src={icon}/>
               </div>
               {single && <div className='modelLabel modelVendorLabel'>{vendor}</div>}
               <div className='modelLabel'>
                 {label}
               </div>
             </div>
             <div className='modelPrice'>
               {price}
             </div>
             <div className='modelVision'>
               {vision && <ReactSVG src={Image}/>}
             </div>
             <div className='modelRight'>
               {isSelected() && <div className='modelRadioButtonRight'><ReactSVG src={CheckMark}/></div>}
             </div>
           </div>
  }
}

export class FinetunableModel extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }
  
  finetunes = {}

  componentDidMount() {
    let base = this.props.id
    this.sub = this.props.me.observeFinetuneds(base).subscribe(change => {
      const { type, model } = change
      if (type == 'removed') {
        delete this.finetunes[model.id]
      } else {
        this.finetunes[model.id] = model
      }
      this.updateModelsLater()
    })
  }

  updateModelsLater = () => {
    clearTimeout(this.updateModelsTimeout)
    this.updateModelsTimeout = setTimeout(this.updateModels, 200)
  }

  updateModels = () => {
    const models = Object.values(this.finetunes)
    this.setState({
      models
    })
  }

  render() {
    const { id, title, label, isSelected, getIcon, vendor, getModelIcon, vision } = this.props

    return <div className='finetunableModel'>
             <Model
               id={id}
               title={title}
               label={label}
               isSelected={isSelected}
               getIcon={getIcon}
               vendor={vendor}
               getModelIcon={getModelIcon}
               vision={vision}/>
             <div className='fineTunes'>
               <ModelVendor
                 vender={
                   {
                     getIcon,
                     name: "Fine-tunes"
                   }
                 }
                 me={this.props.me}
                 models={this.state.models}
                 aggregatePrice={finetunePrice}
                 getPrice={getFinetunePrice}
                 formatPrice={formatPrice}
                 />
               </div>
           </div>
  }
}

export class ModelVendor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: this.props.open
    }
  }
  renderModels() {
    return this.props.models.map(model => {
      return <Model key={model.id} me={this.props.me} model={model} formatPrice={this.props.formatPrice} getPrice={this.props.getPrice}/>
    })
  }
  toggleMenu = () => {
    this.setState({
      open: !this.state.open
    })
  }
  render() {
    const open = this.state.open || this.props.open
    const { models } = this.props
    //console.log({models})
    const format = this.props.formatPrice || formatPrice
    let selectionCount = models.reduce((a, x) => a + (x.isSelected() ? 1 : 0), 0)
    let isSelected = selectionCount > 0
    if (models.length === 1) {
      return <Model key={models[0].id} me={this.props.me} model={models[0]} single={true} getPrice={this.props.getPrice} formatPrice={this.props.formatPrice}/>
    }
    let style
    if (models.length === 0) {
      style = {display: 'none'}
    }
    let price
    if (this.props.aggregatePrice) {
      const { input, output } = this.props.aggregatePrice
      price = "$" + format(input) + "/" + format(output)
    }
    return <div className={'modelCategory' + (open ? ' modelCategoryOpen' : '')} style={style}>
             <div className={'modelCategoryMenu' + (isSelected ? ' modelRadioButtonSelected' : '')} onClick={this.toggleMenu}>
               <div className='modelCategoryLeft'>
                 <div className='modelIcon'>
                   <ReactSVG src={this.props.vendor.getIcon()}/>
                 </div>
                 <div className='modelLabel modelVendorLabel'>
                   {this.props.vendor.name}
                 </div>
               </div>
               <div className='modelCategoryRight'>
                 {price && <div className='modelPrice'>{price}</div>}
                 {selectionCount > 0 && <div className='modelVendorSelectionCountContainer'><div className='modelVendorSelectionCount'>{selectionCount}</div><div className='modelVendorCheck'><ReactSVG src={CheckMark}/></div></div>}
                 <div className='modelIcon'>
                   <ReactSVG src={open ? Left : Right}/>
                 </div>
               </div>
             </div>
             {open && <div className='modelCategoryButtons'>
                         {this.renderModels()}
                       </div>}
           </div>
  }
}


export class ModelsMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuActive: true
    }
  }


  setScrollRef = ref => {
    this.scrollRef = ref
  }
    
  renderMenu = (close) => {
    let style
    let ref = document.getElementById('modelMenuTrigger')
    if (ref) {
      const rect = ref.getBoundingClientRect()
      const h = window.innerHeight - rect.bottom
      style = {
        maxHeight: h
      }
    }
    let selectedModel
    const selectModel = (model) => {
      model.select()
      close()
    }
    const closeMenu = () => this.setState({menuActive: false})
    const isLarge = () => {
      return this.props.sizes['large']
    }
    const isMedium = () => {
      return this.props.sizes['medium']
    }
    const isSmall = () => {
      return this.props.sizes['small']
    }
    const selectedModels = this.props.modelsForSelection.filter(model => {
      return this.props.selectedModels[model.id]
    })
    
    const vendors = {}
    let vendorList = this.props.vendors
    for (const model of this.props.modelsForSelection) {
      let arr = vendors[model.vendor]
      if (!arr) {
        vendors[model.vendor] = arr = []
      }
      arr.push(model)
    }
    let vendorItems = []
    const selectedVendors = Object.keys(vendors)
    for (const vendor of vendorList) {
      const models = vendors[vendor.name] || []
      vendorItems.push(<ModelVendor
                         open={selectedVendors.length === 1}
                         key={vendor.name}
                         vendor={vendor}
                         me={this.props.me}
                         models={models}
                         formatPrice={this.props.formatPrice}
                         getPrice={this.props.getPrice}/>)
    }
    const toggleLarge = () => {
      this.props.toggleLarge()
    }
    const toggleMedium = () => {
      this.props.toggleMedium()
    }
    const toggleSmall = () => {
      this.props.toggleSmall()
    }
    const isVision = () => {
      return this.props.isVision
    }
    const toggleVision = () => {
      this.props.toggleVision()
    }
    const isClosed = () => {
      return this.props.isClosed
    }
    const toggleClosed = () => {
      this.props.toggleClosed()
    }
    const isBase = () => {
      return this.props.isBase
    }
    const isLegacy = () => {
      return this.props.isLegacy
    }
    const toggleBase = () => {
      this.props.toggleBase()
    }
    const toggleLegacy = () => {
      this.props.toggleLegacy()
    }
    const isInstruct = () => {
      return this.props.isInstruct
    }
    const toggleInstruct = () => {
      this.props.toggleInstruct()
    }
    const supportsContext = context => {
      return context === this.props.inputContext
    }
    const toggleContext = context => {
      this.props.setInputContext(context)
    }
    const supportsOutputContext = context => {
      return context ===this.props.outputContext
    }
    const toggleOutputContext = context => {
      this.props.setOutputContext(context)
    }
    //console.log('modelsMenu', {selectedModels, propsSelectedModels: this.props.selectedModels})
    return <div className='chatGptModelsMenu' style={style}>
             <div className='chatGptThreadsMenuOptionsContainer'>
               {selectedModels.length === 0 && <div key='title' className='chatGPTModelsMenuTitle'>
                                           <SimpleIcon src={Alert}/>Please select one or more models
                </div>}
               <div key='vendors' className='chatGptThreadsVendorOptions'>
                 {
                   this.props.vendors.map(vendor => {
                     //console.log("VENDOR", vendor)
                     const toggle = async () => {
                       this.props.toggleVendor(vendor)
                     }
                     const selected = this.props.isVendorSelected(vendor)
                     let className = 'modelsMenuVendor'
                     if (selected) {
                       className += ' modelsMenuVendorSelected'
                     }
                     return <div key={vendor.id} className={className}>
                              <KeyboardButton1 icon={vendor.getIcon()} action={toggle} />
                            </div>
                   })
                 }
               </div>
             </div>
             <div className='chatGptThreadsMenuOptionsContainer'>
               <div className='chatGptThreadsMenuOptions'>
                 <Checkbox selected={isSmall()} toggle={toggleSmall} label='Small'/>
                 <Checkbox selected={isMedium()} toggle={toggleMedium} label={'Medium'}/>
                 <Checkbox selected={isLarge()} toggle={toggleLarge} label='Large'/>
                 <Checkbox icon={Image} selected={isVision()} toggle={toggleVision} label='Vision'/>
                 <Checkbox selected={isBase()} toggle={toggleBase} label='Base'/>
                 <Checkbox className='legacy' selected={isLegacy()} toggle={toggleLegacy} label='Legacy'/>
               </div>
               <div className='chatGptThreadsMenuOptions'>
                 <div className='optionLabel'>Input</div>
                 <Checkbox selected={supportsContext(4)} toggle={() => toggleContext(4)} label={'4K'}/>
                 <Checkbox selected={supportsContext(8)} toggle={() => toggleContext(8)} label={'8K'}/>
                 <Checkbox selected={supportsContext(16)} toggle={() => toggleContext(16)} label='16K'/>
                 <Checkbox selected={supportsContext(128)} toggle={() => toggleContext(128)} label='128K'/>
                 <Checkbox selected={supportsContext(256)} toggle={() => toggleContext(256)} label='200K'/>
                 <Checkbox selected={supportsContext(1000)} toggle={() => toggleContext(1000)} label='1M'/>
                 <Checkbox selected={supportsContext(2000)} toggle={() => toggleContext(2000)} label='2M'/>
               </div>
               <div className='chatGptThreadsMenuOptions'>
                 <div className='optionLabel'>Output</div>
                 <Checkbox selected={supportsOutputContext(4)} toggle={() => toggleOutputContext(4)} label={'4K'}/>
                 <Checkbox selected={supportsOutputContext(8)} toggle={() => toggleOutputContext(8)} label={'8K'}/>
                 <Checkbox selected={supportsOutputContext(16)} toggle={() => toggleOutputContext(16)} label='16K'/>
               </div>
             </div>
             {vendorItems}
      </div>
  }


  setPopupRef = ref => {
    this.popupRef = ref
  }

  openMenu = () => {
  }

  openIfNecessary = () => {
  }

  componentDidMount() {
    this.state.closeOnClick = !this.props.menuActive
    this.forceUpdate(() => {
      if (this.popuRef && this.props.menuActive) {
        debugger
        this.popupRef.open()
      }
    })
  }

  componentDidUpdate() {
    if (this.popupRef && this.props.menuActive) {
      setTimeout(this.popupRef.open, 500)
    } else {
    }
  }

  render() {
    if (this.props.inline) {
      return <div className='modelsMenuInline'>
               {this.renderMenu()}
             </div>
    }
    const trigger = <div id={'modelMenuTrigger'} className='keyboardAddButton' ref={this.setRef}>
                      <KeyboardButton1 icon={this.props.menuActive || this.state.menuActive ? MenuDown : MenuUp} action={async () => {}  }/>
                    </div>
    return <Popup ref={this.setPopupRef} closeOnDocumentClick={true} trigger={trigger} position={'bottom left'} onOpen={() => {
                  }}>
             {close => this.renderMenu(close)}
           </Popup>
  }

  renderFail() {
    return <Popup1 popup={this.renderMenu}>
           <KeyboardButton1 icon={this.props.menuActive ? MenuUp : MenuDown} action={async () => {}}/>
           </Popup1>
  }
}


export class ModelsView extends BnSubpage {

  constructor (props) {
    super(props)
    this.state = {
      options: {
      }
    }
    if (!this.props.category) {
      throw new Error("category is required")
    }
    this.state.options[this.props.category] = {
      temperature: 1.0,
      top_p: 1.0,
      baseModels: false,
      visionModels: false,
      legacyModels: false,
      selectedModels: {},
      selectedVendors: {},
      sizes: { },
      inputContext: 4,
      outputContext: 4,
    }
    //console.log('state options', this.state.options)
  }
  
  saveOptionsLater = () =>  {
    this.props.onOptionsChanged()
    clearTimeout(this.saveOptionsTimeout)
    this.saveOptionsTimeout = setTimeout(this.saveOptions, 400)
  }

  getOptions = () => {
    if (!this.state.options[this.props.category]) {
      this.state.options[this.props.category] = {
        temperature: 1.0,
        top_p: 1.0,
        baseModels: false,
        visionModels: false,
        legacyModels: false,
        selectedModels: {},
        selectedVendors: {},
        sizes: { },
        inputContext: 4,
        outputContext: 4,
      }
    }
    const result = this.state.options[this.props.category]
    //console.log("get options", this.props.category, this.state.options, result)
    return result
  }
  
  saveOptions = async () => {
    await this.props.saveOptions(this.state.options)
  }

  componentDidMount() {
    this.observeOptions()
    if (this.props.onCreate) this.props.onCreate(this)
  }

  componentWillUnmount() {
    if (this.optionsSub) {
      this.optionsSub.unsubscribe()
    }
  }

  observeOptions = () => {
    this.optionsSub = this.props.observeOptions().subscribe(data => {
      let { options } = data
      //console.log("models menu observe options", this.props.category, options)
      if (options) {
        this.setState({options})
      }
    })
  }
  
  selectModel = (model) => {
    this.toggleOption2('selectedModels', model)
  }

  isModelSelected = (model) => {
    const options = this.getOptions()
    return options.selectedModels[model]
  }

  getModelPrice = model => {
    const options = this.getOptions()
    const { inputContext, outputContext } = options
    if (!model.contexts) {
      return inputContext  === 4 && outputContext === 4 ? model.price: null
    }
    for (let i = 0; i < model.contexts.length; i++) {
      if (model.contexts[i].input >= inputContext &&
          model.contexts[i].output >= outputContext) {
        return model.contexts[i].price
      }
    }
  }

  getSelectedModelIds = () => {
    const options = this.getOptions()
    const selected = this.getSelectedModelsList().filter(x => options.selectedModels[x.id])
    return selected.map(x => x.id)
  }

  getModelsForSelection = () => {
    const selected = this.getSelectedModelsList()
    const result = {}
    for (const model of selected) {
      result[model.id] = true
    }
    return result
  }

  getSelectedModels = () => {
    const ids = this.getSelectedModelIds()
    const result = {}
    for (const id of ids) {
      result[id] = true
    }
    return result
  }

  getSelectedModelsListFiltered = () => {
    const options = this.getOptions()
    return this.getSelectedModelsList().filter(x => options.selectedModels[x.id])
  }
  
  getSelectedModelsList = () => {
    const options = this.getOptions()
    //console.log("options", options)
    if (!options) return []
    const models = this.getModels()
    const allVendors =  Object.values(options.selectedVendors).length === 0
    const allSizes =  Object.values(options.sizes).length === 0
    const selected = models.filter(model => {
      const vendor = this.props.vendors.find(x => x.name === model.vendor)
      if (vendor) {
        let sizeOk = true
        if (!allSizes) {
          sizeOk = options.sizes[model.getSize()]
        }
        const vendorOk = allVendors || options.selectedVendors[vendor.id]
        //console.log({model, sizeOk, vendorOk})
        let visionOk = true
        let baseOk = true
        let legacyOk = false
        let contextOk = this.getModelPrice(model)
        if (options.visionModels) {
          visionOk = model.vision
        }
        if (options.baseModels) {
          baseOk = true
        } else {
          baseOk = !model.isBase
        }
        if (options.legacyModels) {
          legacyOk = true
        } else {
          legacyOk = !model.legacy
        }
        //console.log({model})
        return contextOk && sizeOk && vendorOk && visionOk && baseOk && legacyOk
      } 
      return false
    })
    return selected
  }

  isVision = () => {
    return this.getOption('visionModels')
  }

  toggleOption = option => {
    const options = this.getOptions()
    options[option] = !options[option]
    this.forceUpdate(this.saveOptionsLater)
  }

  getOption = option => {
    const options = this.getOptions()
    return options[option]
  }

  setOption = (updates) => {
    const options = this.getOptions()
    for (const key in updates) {
      options[key] = updates[key]
    }
    this.forceUpdate(this.saveOptionsLater)
  }

  toggleOption2 = (option, key) => {
    let options = this.getOptions()
    if (!options[option]) {
      options[option] = {}
    }
    if (options[option][key]) {
      delete options[option][key]
    } else {
      options[option][key] = true
    }
    this.forceUpdate(this.saveOptionsLater)
  }

  getOption2 = (option, key) => {
    let options = this.getOptions()[option]
    return (options && options[key]) || undefined
  }

  toggleVision = () => {
    this.toggleOption('visionModels')
  }

  toggleOpenSource = () => {
    this.toggleOption('openSource')
  }

  isLegacy = () => {
    return this.getOption('legacyModels')
  }

  toggleLegacy = () => {
    this.toggleOption('legacyModels')
  }

  isBase = () => {
    return this.getOption('base')
  }

  toggleBase = () => {
    return this.toggleOption('base')
  }

  isVendorSelected = vendor => {
    return this.getOption2('selectedVendors', vendor.id)
  }

  toggleVendor = vendor => {
    this.toggleOption2('selectedVendors', vendor.id)
  }

  setOutputContext = outputContext => {
    this.setOption({outputContext})
  }
  
  setInputContext = inputContext => {
    this.setOption({inputContext})
  }

  getInputContext = () => {
    return this.getOption('inputContext')
  }

  getOutputContext = () => {
    return this.getOption('outputContext')
  }

  getModels = () => {
    if (!this.models) {
      this.models = this.props.models(this.isModelSelected, this.selectModel).filter(x => {
        return x.getSize
      })
      this.modelsById = {}
      this.models.forEach(model => {
        this.modelsById[model.id] = model
      })
    }
    return this.models
  }

  renderContent() {
    const toggleSize = (size) => {
      this.toggleOption2('sizes', size)
    }
    const getSizes = () => this.getOption('sizes')
    const toggleSmall = () => toggleSize('small')
    const toggleMedium = () => toggleSize('medium')
    const toggleLarge = () => toggleSize('large')
    let modelsMenu = (menuActive) => <ModelsMenu
                                       collection={this.props.collection || 'models'}
                                       inline={menuActive}
                                       formatPrice={price => formatPrice(price, 5)}
                                       menuActive={menuActive}
                                       getPrice={this.getModelPrice}
                                       isVendorSelected={this.isVendorSelected}
                                       toggleVendor={this.toggleVendor}
                                       vendors={this.props.vendors}
                                       models={this.getModels()}
                                       selectedModels={this.getSelectedModels()}
                                       modelsForSelection={this.getSelectedModelsList()}
                                       visible={true}
                                       sizes={getSizes()}
                                       toggleSmall={toggleSmall}                 
                                       toggleMedium={toggleMedium}                 
                                       toggleLarge={toggleLarge}
                                       toggleVision={this.toggleVision}
                                       isVision={this.isVision()}
                                       toggleBase={this.toggleBase}
                                       toggleLegacy={this.toggleLegacy}
                                       inputContext={this.getInputContext()}
                                       outputContext={this.getOutputContext()}
                                       setInputContext={this.setInputContext}
                                       setOutputContext={this.setOutputContext}
                                       isBase={this.isBase()}
                                       isLegacy={this.isLegacy()}
                                     />
    return modelsMenu(this.props.menuActive)
  }
}
