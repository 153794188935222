import React, { Component } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import './index.css'

export const renderTemplate = (template, selectedVariable, setVariable, nested) => {
  return <div className='template'>
           {
             template.fragments.map(x => {
               let text
               if (x.type == 'variable') {
                 if (true || x.options) {
                   if (true || x.options.length > 1) {
                     const value = template.vars[x.variable]
                     const onClick = () => {
                       setVariable(x, value)
                     }
                     const selected = selectedVariable === x
                     return <TemplateVariable selected={selected} variable={x} value={value} setVariable={setVariable} nested={nested} onClick={onClick}/>
                   } else {
                     text = x.options[0]
                   }
                 } else {
                   text = x.variable
                 }
               } else {
                 text = x.text
               }
               return text
             })
           }
         </div>
  
}

export class TemplateVariable extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  setVariable = (x, y) => {
    this.ref.innerText = y
    this.props.setVariable(x, y)
  }

  onClick = (e) => {
    if (this.props.onClick) {
      return this.props.onClick()
    }
    this.toggleSelectionList()
  }
  
  toggleSelectionList = () => {
    this.setState({
      show: !this.state.show
    })
  }

  setRef = ref => {
    this.ref = ref
  }
  
  render() {
    const x = this.props.variable
    const value = this.props.value
    const selected = this.props.selected
    console.log('render', x)
    if (true || x.options) {
      let className = 'templateVariableContainer'
      if (selected) {
        className += ' templateVariableSelected'
      }
      if (this.props.nested) {
        className += ' templateVariableContainerNested'
      }
      let valueSelected = false
      let setVariable = (variable, value) => {
      }
      const renderedValue = x.template ? renderTemplate(x.template, valueSelected, setVariable, true) : (value || x.display)
      return <div className={className} onClick={this.onClick}>
               <div ref={this.setRef} className='templateVariable' contentEditable={false}>{renderedValue}</div>
               {this.state.show &&
               <ClickAwayListener onClickAway={this.toggleSelectionList}>
                <div className='templateVariableSelectionList'>
                                       {
                                         x.options.map(y => {
                                           const onClick = () => {
                                             this.setVariable(x, y)
                                           }
                                           return <div className='templateVariableSelectListOption' onClick={onClick}>
                                                    {y}
                                                  </div>
                                         })
                                       }
                                     </div>
               </ClickAwayListener>}
             </div>
    }
    return <div ref={this.setRef} className='templateVariable' contentEditable={true}>{x.display}</div>
  }
}
