import React, { Component } from 'react'
import './index.css'

export class Dots extends React.Component {    

  componentDidUpdate(prevProps) {
    console.log('props', prevProps, "=>", this.props)
  }

  updatePosition = (position) => {
    if (this.props.clickable) {
      if (this.props.positionChangeListener) {
        this.props.positionChangeListener(position)
      }
    }
  }
  
  generateDotHolders() {
    let dotHolders = []
    for (var i = 0; i < this.props.length; i++) {
      dotHolders.push(<DotHolder key={i} position={i} margin={this.props.margin} length={this.props.length} clickable={this.props.clickable} size={this.props.size} updatePosition={this.updatePosition} />)
    }
    return dotHolders
  }
  
  render() {
    if (!this.props.length || this.props.length < 2) return null
    const dotHolders = this.generateDotHolders()
    console.log("position => ", this.props.position)
    const sliderStyle = 'slider'
    const style = {
      width: this.props.length * (this.props.size + this.props.margin) - this.props.margin
    }
    return (
      <div style={style} className={sliderStyle}>
        <div style={style} className="dot-holders">
          { dotHolders }
        </div>
        <Dot position={this.props.position} size={this.props.size} margin={this.props.margin} />
      </div>
    )
  }
}

class DotHolder extends React.Component {
    render() {
      const dotHolderStyle = this.props.clickable ? 'dot-holder dot-holder-clickable' : 'dot-holder'
      const { position, size, margin } = this.props
      const style = {
        left: position * (size + margin),
        width: size,
        height: size
      }
        return (
            <div style={style} className={dotHolderStyle} onClick={(e) => this.props.updatePosition(this.props.position, e)}></div>
        )
    }
}

class Dot extends React.Component {
    render() {
      const positionClass = 'dot'
      const offset = 1 * this.props.size / 10;
      const style = {
        left: offset + this.props.position * (this.props.size + this.props.margin),
        top: offset,
        width: this.props.size-(offset * 2),
        height: this.props.size-(offset * 2)
      }
      return(
          <div style={style} className={ positionClass }></div>
      )
    }
}

Dots.defaultProps = {
  position: 0,
  length: 3,
  size: 10,
  margin: 3,
  clickable: true,
  positionChangeListener: null
}


