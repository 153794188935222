const Dialects = {}
const Countries = {}
const Langs = {}

const getDialects = lang => {
  const dialects = Dialects[lang]
  if (!dialects) {
    console.error("lang not found", lang)
    return null
  }
  return dialects
}

export const resolveDialect = iso => {
  let [lang, dialect] = iso.split('-')
  lang = lang.toLowerCase()
  if (!dialect) {
    const dialects = getDialects(lang)
    if (!dialects) {
      return null
    }
    dialect = dialects[0]
    if (dialect) {
      iso = `${lang}-${dialect}`
    }
  }
  const country = Countries[iso]
  const name = Langs[lang]
  return {
    name,
    dialect: country,
    iso
  }
}

export const langs = [
  ['English',  ['en-US', 'United States'],
   ['en-GB', 'United Kingdom'],
   ['en-CA', 'Canada'],
   ['en-AU', 'Australia'],
   ['en-NZ', 'New Zealand'],
   ['en-ZA', 'South Africa'],
   ['en-BZ', 'Belize'],
   ['en-JM', 'Jamaica'],
   ['en-TT', 'Trinidad']],
['Español',         ['es-AR', 'Argentina'],
 ['es-BO', 'Bolivia'],
 ['es-CL', 'Chile'],
 ['es-CO', 'Colombia'],
 ['es-CR', 'Costa Rica'],
 ['es-EC', 'Ecuador'],
 ['es-SV', 'El Salvador'],
 ['es-ES', 'España'],
 ['es-US', 'Estados Unidos'],
 ['es-GT', 'Guatemala'],
 ['es-HN', 'Honduras'],
 ['es-MX', 'México'],
 ['es-NI', 'Nicaragua'],
 ['es-PA', 'Panamá'],
 ['es-PY', 'Paraguay'],
 ['es-PE', 'Perú'],
 ['es-PR', 'Puerto Rico'],
 ['es-DO', 'República Dominicana'],
 ['es-UY', 'Uruguay'],
 ['es-VE', 'Venezuela']],
['Euskara',         ['eu-ES']],
['Filipino',        ['fil-PH']],
['Français',        ['fr-FR']],
['Basa Jawa',       ['jv-ID']],
['Galego',          ['gl-ES']],
['ગુજરાતી',           ['gu-IN']],
['Hrvatski',        ['hr-HR']],
['IsiZulu',         ['zu-ZA']],
['Íslenska',        ['is-IS']],
['Italiano',        ['it-IT', 'Italia'],
 ['it-CH', 'Svizzera']],
['ಕನ್ನಡ',             ['kn-IN']],
['ភាសាខ្មែរ',          ['km-KH']],
['Latviešu',        ['lv-LV']],
['Lietuvių',        ['lt-LT']],
['മലയാളം',          ['ml-IN']],
['मराठी',             ['mr-IN']],
['Magyar',          ['hu-HU']],
['ລາວ',              ['lo-LA']],
['Nederlands',      ['nl-NL']],
['नेपाली भाषा',        ['ne-NP']],
['Norsk bokmål',    ['nb-NO']],
['Polski',          ['pl-PL']],
['Português',       ['pt-BR', 'Brasil'],
 ['pt-PT', 'Portugal']],
['Română',          ['ro-RO']],
['සිංහල',          ['si-LK']],
['Slovenščina',     ['sl-SI']],
['Basa Sunda',      ['su-ID']],
['Slovenčina',      ['sk-SK']],
['Suomi',           ['fi-FI']],
['Svenska',         ['sv-SE']],
['Kiswahili',       ['sw-TZ', 'Tanzania'],
 ['sw-KE', 'Kenya']],
['ქართული',       ['ka-GE']],
['Հայերեն',          ['hy-AM']],
['தமிழ்',            ['ta-IN', 'இந்தியா'],
 ['ta-SG', 'சிங்கப்பூர்'],
 ['ta-LK', 'இலங்கை'],
 ['ta-MY', 'மலேசியா']],
['తెలుగు',           ['te-IN']],
['Tiếng Việt',      ['vi-VN']],
['Türkçe',          ['tr-TR']],
['اُردُو',            ['ur-PK', 'پاکستان'],
 ['ur-IN', 'بھارت']],
['Ελληνικά',         ['el-GR']],
['български',         ['bg-BG']],
['Pусский',          ['ru-RU']],
['Српски',           ['sr-RS']],
['Українська',        ['uk-UA']],
['한국어',            ['ko-KR']],
['中文',             ['zh-CN', '普通话 (中国大陆)'],
 ['zh-TW', '中文 (台灣)']],
['日本語',           ['ja-JP']],
['हिन्दी',             ['hi-IN']],
['ภาษาไทย',         ['th-TH']]];



langs.forEach(lang => {
  const name = lang[0]
  const dialects = lang.slice(1)
  dialects.forEach(x => {
    const [lang, dialect] = x[0].split('-')
    if (!Langs[lang]) {
      Langs[lang] = name
    }
    Countries[x[0]] = x[1]
    let array = Dialects[lang]
    if (!array) {
      array = [dialect]
      Dialects[lang] = array
    } else {
      array.push(dialect)
    }
  })
})


