import { docData, doc, collectionChanges } from "rxfire/firestore"
import { bindCallback, of, concat, from, Subject, merge as mergeN, combineLatest } from 'rxjs'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import axios from 'axios'
import { startOfDay, startOfMonth, endOfMonth, startOfWeek, endOfWeek, randomElement, clone, capitalize, mergeFields, formatAddress } from './Util.js'
import phone from 'phone'
import { isIOS, isAndroid, isDesktop } from './Platform.js'
import { resolveDialect } from './Lang.js'
import { saveAs } from 'file-saver'
import { streamReply } from './Stream.js'
import MarkdownIt  from 'markdown-it'

const md = new MarkdownIt();

function convertJsonToMarkdown(jsonData) {
  let markdownOutput = ""
  jsonData.forEach(item => {
    if (item.type === 'text') {
      markdownOutput += item.text + "\n\n";
    } else if (item.type === 'image_url' && item.image_url && item.image_url.url) {
      markdownOutput += `![Image](${item.image_url.url})\n\n`;
    }
  })
  return markdownOutput
}

const consoleLog = (...args) => {
  //console.log(...args)
}

const debugLog = (...args) => {
  consoleLog(...args)
}

let server
if (false) {
  server = 'http://192.168.1.106:8080'
} else {
          //https://functions-sp45acvogq-uc.a.run.app
  server = 'https://functions-sp45acvogq-uc.a.run.app'
}

try {
  speechSynthesis.getVoices()
} catch (ignored) {
  
}
let LANG
const MAX_TOKENS = 30
const SOURCE = new URLSearchParams(window.location.search).get('src')
const WHISPER = new URLSearchParams(window.location.search).get('whisper')
const Token = new URLSearchParams(window.location.search).get('token')
const InviteCode = new URLSearchParams(window.location.search).get('referralCode')

const RedirectURI = new URLSearchParams(window.location.search).get('redirectURI')
const State = new URLSearchParams(window.location.search).get('state')

const delay = seconds => new Promise(resolve => setTimeout(resolve, seconds*1000))

const xmlEscape = xml => {
  if (!xml) return ''
  return xml.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
}

const xml2json = xml => {
  try {
    var obj = {};
    if (xml.children.length > 0) {
      for (var i = 0; i < xml.children.length; i++) {
        var item = xml.children.item(i);
        var nodeName = item.nodeName;

        if (typeof (obj[nodeName]) == "undefined") {
          obj[nodeName] = xml2json(item);
        } else {
          if (typeof (obj[nodeName].push) == "undefined") {
            var old = obj[nodeName];

            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(xml2json(item));
        }
      }
    } else {
      obj = xml.textContent;
    }
    return obj;
  } catch (e) {
      consoleLog(e.message);
  }
}

class WhisperVoiceRecognizer {
  instructionSubject = new Subject()
  isActiveSubject = new Subject()
  isActive = false

  observeInstruction = () => {
    return this.instructionSubject
  }

  setLang = lang => {
    this.lang = lang
    ////debugger
  }

  observeIsActive = () => {
    return concat(this.isActive, this.isActiveSubject)
  }

  start = async () => {
    if (!this.vad) {
      this.vad = await window.vad.MicVAD.new({
        onSpeechEnd: async audio => {
          consoleLog("speech end", this.lang)
          const blob = encodeMP3([audio])
          //saveAs(blob, "audio.mp3")
          //return
          const file = new File([blob], 'audio.mp3', {
            type: 'audio/mp3'
          })
          const response = await axios.post('http://localhost:8080/transcribe?lang='+(this.lang || ''),
                                            file)
          const { text } = response.data
          this.instructionSubject.next(text)
        }
      })
    }
    this.vad.start()
    this.isActiveSubject.next(this.isActive = true)
  }

  stop = () => {
    this.isActive = false
    this.isActiveSubject.next(false)
    this.vad.pause()
  }
}


class SystemVoiceRecognizer {

  constructor (autocorrect) {
    this.autocorrect = autocorrect
  }

  instructionSubject = new Subject()

  observeInstruction = () => {
    return this.instructionSubject
  }

  onResult = async event => {
    let prompt = ''
    consoleLog("onResult", event)
    for (var i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        prompt += event.results[i][0].transcript
        break
      }
    }
    this.instructionSubject.next(prompt)
  }

  stop = () => {
    this.active = false
    consoleLog("STOP RECOGNITION")
    if (this.recognition) {
      this.recognition.abort()
      this.recognition.stop()
    }
    this.onActiveSubject.next(false)
  }

  start = () => {
    if (this.active) return
    this.active = true
    if (!this.recognition) {
      const recognition = new window.webkitSpeechRecognition()
      if (LANG) {
        recognition.lang = LANG
      }
      consoleLog("LANG", LANG)
      recognition.continuous = true
      recognition.interimResults = false
      recognition.onstart = this.onStart
      recognition.onresult =  this.onResult
      recognition.onerror = this.onError
      recognition.onend = this.onEnd
      this.recognition = recognition
    }
    this.recognition.start()
    consoleLog("START RECOGNITION")
    this.onActiveSubject.next(true)
  }

  onError = event =>{
    consoleLog('error:', event)
  }

  onActiveSubject = new Subject()

  observeIsActive = () => {
    return concat(of(this.active), this.onActiveSubject)
  }

  onEnd = event => {
    consoleLog('end:', event)
    if (this.active) {
      this.active = false
      this.start()
    }
  }

  setLang = lang => {
    if (LANG != lang) {
      const wasActive = this.isActive
      ////debugger
      if (wasActive) {
        this.stop()
      }
      LANG = lang
      this.recognition = null
      if (wasActive) {
        this.start()
      }
    }
  }
}

class VoiceRecognizer {
  setLang = lang => {
    this.lang = lang
    if (this.impl) {
    }
  }

  canUseWhisper = () => {
    return false
  }

  start = () => {
    if (!this.impl) {
      if (this.canUseWhisper()) {
        this.impl = new WhisperVoiceRecognizer()
      } else {
        this.impl = new SystemVoiceRecognizer(this.autocorrect)
      }
      this.sub1 = this.impl.observeInstruction().subscribe(instruction => {
        this.instructionSubject.next(instruction)
      })
      this.sub2 = this.impl.observeIsActive().subscribe(isActive => {
        this.isActiveSubject.next(isActive)
      })
    }
  }
  stop = () => {
    this.impl.stop()
    this.impl = null
    this.sub1.unsubscribe()
    this.sub2.unsubscribe()
    this.sub1 = null
    this.sub2 = null
  }

  instructionSubject = new Subject()
  isActiveSubject = new Subject()
  
  observeInstruction = () => {
    return this.instructionSubject
  }

  observeIsActive = () => {
    const impl = this.impl
    if (impl) {
      return concat(impl.isActive, this.isActiveSubject)
    }
    return this.isActiveSubject
  }
}

export class Me {


  isKeyboardExtension = () => {
    return !!Token 
  }

  isContainingApp = () => {
    return !Token 
  }

  nativeLog = msg => {
    if (this.isNative()) {
      this.sendNativeMessage({
        type: 'log',
        message: msg
      })
    } else {
      consoleLog(msg)
    }
  }

  nativeInit () {
    if (!this.isNative()) {
      return
    }
    if (!this.nativeInitDone) {
      this.nativeInitDone = true
      if (this.isNative()) {
        this.nativeLog("native init done: "+window.postMessage);
        this.sendNativeMessage({
          type: 'config',
          config: this.config
        })
        try {
          const errorOverlay = require('react-native/Libraries/Core/Devtools/parseErrorStack');
          errorOverlay.reportError = (error) => {
            console.warn(error);
          }
        } catch (err) {
          console.error(err)
        }
      }
    }    
  }

  sendNativeMessage = msg => {
    if (this.isNative()) {
      window.ReactNativeWebView.postMessage(JSON.stringify(msg))
    }
  }
  
  isNative = () => {
    return typeof window !== 'undefined' && (window.ReactNativeWebView)
  }

  customToken = null
  customTokenTimestamp = 0
  getIdToken = async () => {
    return await this.self.getIdToken(true)
  }
  
  getCustomToken = async () => {
    const now = Date.now()
    if(now - this.customTokenTimestamp > 30 * 60 * 1000) {
      this.customTokenTimestamp = now
      this.customToken = null
    }
    if (this.customToken) return this.customToken
    const func = this.firebase.functions().httpsCallable('getCustomToken')
    const result = await func({})
    const { customToken } = result.data
    this.customToken = customToken
    return this.customToken
  }

  constructor (firebase, config) {
    this.firebase = firebase
    const db = firebase.firestore()
    db.settings({ ignoreUndefinedProperties: true })
    this.config = config
    const auth = this.firebase.auth();
    auth.onAuthStateChanged(this.onAuthStateChanged);
    //this.onAuthStateChanged(auth.currentUser);
    this.config = config
    window.postMessage = this.onNativeMessage
    window.observeContactOnline = this.observeContactOnline
    this.nativeInit()
    window.blockInput = () => {
    }
    window.unblockInput = () => {
    }
    if (Token) {
      this.firebase.auth().signInWithCustomToken(Token)
    }
    window.addEventListener('resize', this.applyOrientation)
    this.applyOrientation()
    this.connectivitySub = this.observeServerConnectivity().subscribe(isReachable => {
      consoleLog("server is reachable", isReachable)
    })
    consoleLog("signing in anonymously")

  }

  applyOrientation = () => {
    if (window.innerHeight > window.innerWidth) {
      this.orient = 'portrait'
    } else {
      this.orient = 'landscape'
    }
    this.orientSubject.next(this.orient)
  }

  orientSubject = new Subject()
  observeOrientation() {
    if (this.orient) {
      return concat(of(this.orient), this.orientSubject)
    }
    return this.orientSubject
  }

  reqs = []
  callId = 0
  nativeCall = (type, data) => {
    const id = ++this.callId
    return new Promise(resolve => {
      this.reqs[id] = resolve
      const call = {
        type,
      }
      call[type] = data
      this.sendNativeMessage({
        type: 'call',
        reqId: id,
        call
      })
    })
  }

  getCurrentLocation = () => {
    if (typeof window !== 'undefined' && window.ReactNativeWebView) {
      return this.nativeCall({
        type: 'location'
      }).then(response => {
        return response.coords
      })
    }
    return getCurrentPosition()
  }

  saveAs = async (blob, filename) => {
    if (window.ReactNativeWebView) {
      const storage = this.firebase.storage()
      const ref = storage.ref(`Uploads/${this.self.uid}/${filename}`)
      await ref.put(blob)
      const url = await ref.getDownloadURL()
      await this.nativeCall('downloadFile', {
        url: url,
        name: filename,
        mimeType: blob.type
      })
      await ref.delete()
    } else {
      saveAs(blob, filename)
    }
  }

  validatePurchase = async arg => {
    const { success, failure } = arg
    this.nativeLog("validatePurchase!!!!")
    if (success) {
      let { transactionId, verificationResultIOS, transactionReceipt } = success
      this.nativeLog("validatePurchase: " + JSON.stringify({transactionId}))
      try {
        let func
        let receiptData
        if (isAndroid()) {
          func = this.getFunc("verifyGoogleReceipt")
          transactionReceipt = atob(transactionReceipt)
          receiptData = transactionReceipt
        } else if (isIOS()) {
          func = this.getFunc("verifyAppleReceipt")
          receiptData = verificationResultIOS
        } else {
          throw new Error("not a supported platform")
        }
        const response = await func({transactionId, packageName: "com.badnano.attunewise", receiptData})
        this.nativeLog("GOT RESPONSE: " + JSON.stringify(response.data, null, ' '))
        return response.data
      } catch (err) {
        this.nativeLog("GOT ERROR: " + err.message)
        return { failure: err.message}
      }
    } else {
      return { failure }
    }
  }

  purchaseSubject = new Subject()

  observePurchaseOutcome = () => {
    return this.purchaseSubject
  }

  purchaseProduct = async productId => {
    return new Promise(async (resolve, reject) => {
      this.pendingPurchase = {resolve, reject}
      if (this.isNative()) {
        const outcome = await this.nativeCall('purchase', productId)
        this.nativeLog("purchase outcome: " + JSON.stringify(outcome))
        if (!outcome) {
          resolve({ failure: 'Transaction verification in progress.' })
        } else {
          resolve(outcome)
        }
      } else {
        // simulate purchase
        setTimeout(() => {
          this.validatePurchase({
            transactionId: 'test',
            transactionReceipt: 'test'
          })
        }, 1000)
      }
    })
  }
  
  onNativeMessage = json => {
    //this.nativeLog('onNativeMessage: ' + json)
    //if (json.source) return
    consoleLog('onNativeMessage', json)
    let msg
    try {
      msg = JSON.parse(json)
    } catch (err) {
      this.nativeLog('JSON.parse failed: ' + err.message + ": " + json)
      return
    }
    if (msg.type === 'call') {
      const {call} = msg
      const { reqId, op } = call
      const arg = call[op]
      switch (op) {
        case 'validatePurchase':
          const apply = async () => {
            let resolved
            let rejected
            try {
              resolved = await this.validatePurchase(arg)
              //this.nativeLog("resolved: " + JSON.stringify(resolved, null, ' '))
            } catch (err) {
              console.error(err)
              rejected = err.message
            }
            const msg = {
              type: 'return'
            }
            msg['return'] = {
              reqId,
              resolved,
              rejected
            }
            this.sendNativeMessage(msg)
          }
          apply()
          break
        default:
          this.sendNativeMessage({
            type: 'return',
            'return': {
              reqId,
              rejected: "No such operation: " + op
            }
          })
      }
    } else if (msg.type === 'token') {
      try {
        this.saveToken(msg.token)
      } catch (err) {
        console.error(err)
        this.nativeLog('saveToken ' +err.message)
      }
    } else if (msg.type === 'purchase') {
      const { productId, outcome } = msg
      this.purchaseSubject.next({productId, outcome})
    } else if (msg.type === 'notification') {
      //this.nativeLog("received not: " + msg.notification.data.type)
      this.notificationSubject.next(msg.notification)
    } else if (msg.type === 'safeArea') {
      window.safeAreaInsets = msg.safeArea

      this.nativeLog("window.safeAreaInsets: "+JSON.stringify(window.safeAreaInsets));
    } else if (msg.type === 'url') {
      //alert("initial url: " + msg.url)
      this.url = msg.url
      this.urlSubject.next(this.url)
    } else if (msg.type === 'creds') {
      this.creds = msg
      this.credsSubject.next(this.creds)
    } else if (msg.type === 'response') {
      this.nativeLog('response ' + JSON.stringify(msg))
      const resolve = this.reqs[msg.reqId]
      if (resolve) {
        delete this.reqs[msg.reqId]
        resolve(msg.response)
      }
    }
  }

  urlSubject = new Subject()

  utcOffset = -(new Date().getTimezoneOffset()*60*1000)

  setStatusBarColor = color => {
    consoleLog('set status bar color:', color)
    this.sendNativeMessage({
      type: 'statusBarColor',
      color: color 
    })
  }

  selfSubject = new Subject()

  observeSelf = () => {
    const existing = this.self ? [this.self] : []
    return concat(existing, this.selfSubject)
  }


  onAuthStateChanged = user => {
    if (user && this.user && user.uid == this.user.uid) {
      this.self = user
      return
    }
    this.referralCode = undefined
    this.apiKey = undefined
    this.self = user
    if (user) {
      console.log('self', user.uid)
    }
    this.selfSubject.next(user)
    if (this.self) {
      this.firebase.firestore().collection('Admin').where('uid', '==', this.self.uid).get().then( ({ docs }) => {
        this.isAdmin = docs.length > 0
      }).catch(ignored => {
      })
      this.firebase.firestore().collection('Review').where('uid', '==', this.self.uid).get().then( ({ docs }) => {
        this.isReview = docs.length > 0
      }).catch(ignored => {
      })
      this.getAzureVoices()
    } else {
      if (this.profileSub) {
        this.profileSub.unsubscribe()
        this.profileSub = null
      }
      this.signInAnonymously()
    }
    if (this.isSignedInAnonymously()) {
      this.checkForComplimentaryCredits()
    }
    this.firebase.auth()
      .getRedirectResult()
      .then((result) => {
        const { credential, user, accessToken } = result
        if (!credential && !user) return
        console.log("REDIRECT", result)
        if (this.redirectSubject) {
          this.redirectSubject.next({ result })
        } else {
          this.redirect = {  result }
        }
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        if (this.redirectSubject) {
          this.redirectSubject.next({error})
        } else {
          this.redirect = { error }
        }
      });
  }

  observeRedirect = () => {
    if (!this.redirectSubject) {
      this.redirectSubject = new Subject()
    }
    if (this.redirect) {
      const v = [this.redirect]
      this.redirect = null
      return from(v)
    } else {
      return this.redirectSubject
    }
  }

  checkForComplimentaryCredits = async () => {
    if (localStorage.getItem('consumedFreeCredits')) {
      return
    }
    const func = this.getFunc("consumeFreeCredits")
    await func({})
    localStorage.setItem('consumedFreeCredits', 'yes')
  }

  getAzureVoices = async () => {
    ////debugger
    const result = await this.getVoices()
    ////debugger
    this.azureVoices = result
  }


  emailExists = async email => {
    email = email.trim().toLowerCase()
    const func = this.getFunc('emailExists')
    const result = await func({email})
    return result.data
  }

  phoneNumberExists = async phoneNumber => {
    const func = this.getFunc('phoneNumberExists')
    const result = await func({phoneNumber})
    return result.data
  }

  
  signIn = async (email, password) => {
    email = email.trim()
    password = password.trim()
    consoleLog("signin in")
    const result = await this.firebase.auth().signInWithEmailAndPassword(email, password)
    this.onAuthStateChanged(result.user)
    const creds = {
      type: 'login',
      email: email,
      password: password,
      phoneNumber: result.user.phoneNumber
    }
    //alert('login ' + JSON.stringify(creds))
    this.sendNativeMessage(creds)
  }

  isSignedInAnonymously = () => {
    const user = this.firebase.auth().currentUser
    const result =  user && user.isAnonymous
    consoleLog("isAnonymous", result)
    return result
  }

  signInAnonymously = async () => {
    return await this.firebase.auth().signInAnonymously()
  }

  signInWithPhoneNumber = async (phoneNumber, recaptcha) => {
    const { exists }  = await this.phoneNumberExists(phoneNumber)
    if (!exists && await this.isSignedInAnonymously()) {
      return await this.firebase.auth().currentUser.linkWithPhoneNumber(phoneNumber, recaptcha)
    }
    return await this.firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha)
  }

  updatePassword = async newPassword => {
    await this.firebase.auth().currentUser.updatePassword(newPassword)
  }

  resetPassword = async email => {
    await this.firebase.auth().sendPasswordResetEmail(email);
  }

  signOut = async () => {
    this.signUpDisplayName = null;
    this.creds = null
    this.selfSubject.next(null)
    this.sendNativeMessage({
      type: 'signOut'
    })
    if (window.webkit && window.webkit.messageHandlers) {
      const msg = {
        type: 'signOut',
      }
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
    }
    await this.firebase.auth().signOut()
  }

  observeModelOptions = (category) => {
    return this.observeSelf().pipe(flatMap(self => {
      const ref = this.firebase.firestore().collection("UserOptions").doc(self.uid)
      return docData(ref).pipe(map(data => {
        console.log('observe options', data)
        return {options: data.modelSelectionOptions}
      }), catchError(err => {
        debugger
      }))
    }))
  }

  saveModelOptions = async (modelSelectionOptions) => {
    const { uid } = this.self
    console.log('save model options', modelSelectionOptions)
    const ref = this.firebase.firestore().collection("UserOptions").doc(uid)
    return await ref.set({uid, modelSelectionOptions}, { merge: true})
  }

  uploadMessageContent = async (id, content, progress) => {
    try {
      const targetRef = this.firebase.storage().ref('Messages').child(this.self.uid).child(id)
      const uploadTask = targetRef.putString(content)
      if (progress) {
        uploadTask.on('state_changed', snap => {
          const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
          debugLog("progress:", percent)
          if (progress) {
            progress(percent);
          }
        })
      }
      await uploadTask
      return targetRef
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  uploadFileImpl = async (type, channel, file, progress) => {
    const filename =  (Math.random().toString(36).substring(2, 15) + Math.random().toString(23).substring(2, 5)) +'-'+ file.name
    consoleLog("upload file impl", filename)
    const targetRef = this.firebase.storage().ref(type).child(channel).child(filename)
    if (!file.type.startsWith('video/')) {
      // just upload directly
      const uploadTask = targetRef.put(file)
      if (progress) {
        uploadTask.on('state_changed', snap => {
          const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
          debugLog("progress:", percent)
          if (progress) {
            progress(percent);
          }
        })
      }
      await uploadTask
      return targetRef
    }
    const videoDimensions = await this.getVideoDimensions(file)
    // invoke support for transcoding video
    const func = this.firebase.functions().httpsCallable('createUpload')
    const result = await func({
      contentType: file.type,
      type: type,
      channel: channel,
      filename: filename,
      videoDimensions
    })
    const { uploadId, path } = result.data
    const ref = this.firebase.storage().ref(path)
    const uploadTask = ref.put(file)
    if (progress) {
      uploadTask.on('state_changed', snap => {
        const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
        debugLog("progress:", percent)
        if (progress) {
          progress(percent);
        }
      })
    }
    await uploadTask
    const p = new Promise((resolve, reject) => {
      const unsubscribe = this.firebase.firestore().collection('Uploads').doc(uploadId).onSnapshot(snap => {
        const data = snap.data()
        if (data) {
          switch (data.status) {
            case 'completed':
              unsubscribe()
              if (progress) progress(100)
              resolve()
              break
            case 'failed':
              unsubscribe()
              if (progress) progress(100)
              reject(new Error(data.failureReason))
              break
          }
        }
      })
    })
    await p
    return targetRef
  }

  getVideoDimensions = async file => {
    const url = URL.createObjectURL(file)
    const video = document.createElement('video')
    video.src = url
    const videoDimensions = await new Promise(resolve => {
      video.onloadedmetadata = e => {
        resolve({
          height: video.height,
          width: video.width
        })
      }
      video.load()
    })
    return await videoDimensions
  }

  uploadDataset = async (file, progress) => {
    const fileRef = await this.uploadFileImpl('Datasets', this.self.uid, file, progress)
    const func = this.getFunc('uploadDataset')
    const filePath = fileRef.getPath()
    return await func({label: file.name, filePath})
  }

  uploadFileToChannel = async (channel, file, progress) => {
    return await this.uploadFileImpl('Channels', channel, file, progress)
  }

  uploadMesageContent = content => {
    return this.uploadFileImpl('Files', this.self.uid, file, progress)
  }
  
  uploadFile = (file, progress) => {
    consoleLog("upload file")
    return this.uploadFileImpl('Files', this.self.uid, file, progress)
  }

  uploadProfileImage = (file, progress) => {
    const ref = this.firebase.storage().ref("ProfileImages").child(this.self.uid);
    return new Promise((resolve, reject) => {
      const uploadTask = ref.put(file)
      if (progress) {
        progress(0)
      }
      uploadTask.on("state_changed", snap => {
        //debugLog("state_changed", snap);
        const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        if (progress) {
          progress(percent)
        }
      }, reject, () => {
        return resolve(ref.getDownloadURL());
      })
    })
  }

  lastViewed = 0
  lastViewedSubject = new Subject()

  getLastViewed = () => this.lastViewed

  observeLastViewed = () => {
    return concat(of(this.lastViewed), this.lastViewedSubject)
  }

  markViewed = async move => {
    if (move.posted > this.lastViewed) {
      await updateProfile({
        lastViewed: move.posted
      })
    }
  }

  saveProfile = async updates => {
    consoleLog('saveProfile', updates)
    ////debugger
    const profileUpdates = {}
    for (const field of  ['bio', 'username', 'displayName', 'profileImage']) {
      profileUpdates[field] = updates[field]
    }
    const userUpdates = {}
    for (const field of ['email', 'phoneNumber', 'password']) {
      profileUpdates[field] = updates[field] || undefined
    }
    if (profileUpdates.phoneNumber) {
      const converted = phone(profileUpdates.phoneNumber);
      profileUpdates.phoneNumber = converted[0]
    }
    const func = this.firebase.functions().httpsCallable('saveProfile')
    const result = await func(profileUpdates)
    return result.data
  }

  //@TODO remove this
  updateProfile = async updates => {
    const ref = this.firebase.firestore().collection('Users').doc(this.self.uid)
    await ref.set(updates, { merge: true })
  }

  openWindow = (url, arg) => {
    if(window.electronAPI) {
      const msg = {
        type: 'openURL',
        openURL: url
      }
      return this.electron('openURL', url)
    }
    if (this.isNative()) {
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
      return
    }
    consoleLog("window open", url)
    return window.open(url, arg)
  }

  getLang = async input => {
    let server = "https://autocomplete-o4pon4eraa-uc.a.run.app"
    //server = "http://192.168.1.106:8080"
    const url = `${server}/lang?input=${encodeURIComponent(input)}`
    const response = await axios.get(url)
    let { lang } = response.data
    return  resolveDialect(lang)
  }

  cancelSpeak = () => {
    if (this.currentSource) {
      this.currentSource.stop()
      this.currentSource = null
      return
    }
    speechSynthesis.cancel()
  }

  getVoices = async () => {
    const token = await this.getToken()
    //const response = await axios.post(`${server}/listVoices`, {})
    //return response.data
    return {}
  }

  getVoiceLangs = () => {
    const Langs = {}
    if (true) return Langs
    if (this.googleVoices) {
      this.googleVoices.forEach(voice => {
        voice.languageCodes.forEach(lang => {
          Langs[lang] = voice
        })
      })
    } else if (this.azureVoices) {
      this.azureVoices.forEach(voice => {
        const lang = voice.Locale
        Langs[lang] = voice
      })
    } else {
      const voices = speechSynthesis.getVoices()
      voices.forEach(voice => {
        let arr = Langs[voice]
        if (!arr) {
          arr = [voice]
          Langs[voice.lang] = arr
        } else {
          arr.push(voice)
        }
      })
    }
    return Langs
  }

  azureSpeak = async (text, lang) => {
    consoleLog("speak", lang)
    const self = this
    const token = await this.getToken()
    const voices = this.azureVoices.filter(x => {
      return x.Locale === lang
    })
    const voice = randomElement(voices)
    const response = await axios.post(`${server}/speak`, { text, lang, voice: voice.ShortName }, {
      responseType: "arraybuffer",
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    const audioBlob = response.data
    consoleLog("audio length", audioBlob.byteLength)
    ////debugger
    // Create a new AudioContext
    // Set the buffer property of the AudioBufferSourceNode to the decoded data
    return new Promise((resolve, reject) => {
      // Decode the audio data from the blob
      if (self.currentSource) {
        self.currentSource.onended = resolve
        self.currentAudioContext.decodeAudioData(audioBlob, function(decodedData) {
          if (self.currentSource) {
            const source = self.currentSource
            source.buffer = decodedData;
            source.start(0)
          } else {
            resolve()
          }
        })
      } else {
        resolve()
      }
    })
  }
  
  speak = (text, lang) => {
    if (true) {
      return this.azureSpeak(text, lang)
    }
    speechSynthesis.cancel()
    return new Promise(resolve => {
      const utterThis = new SpeechSynthesisUtterance(text)
      consoleLog("setting utterance lang", lang)
      const dialect = resolveDialect(lang)
      if (dialect) {
        utterThis.lang = dialect.iso
      }
      const voices = speechSynthesis.getVoices().filter(voice => voice.lang === utterThis.lang)
      consoleLog(voices)
      ////debugger
      let voice
      if (lang == 'en-US') {
        voice = voices.find(voice => voice.name === "Samantha")
      } else {
        voice = voices[0]
      }
      if (!voice) {
        const lang = utterThis.lang.split('-')[0] + '-'
        voice = speechSynthesis.getVoices().find(voice => voice.lang.startsWith(lang))
      }
      utterThis.voice = voice
      this.utterance = utterThis
      utterThis.onend = resolve
      speechSynthesis.speak(utterThis)
    })
  }

  shouldUseWhisper = () => !!WHISPER || window.electronAPI 

  getVoiceRecognizer = () => {
    //return new VoiceRecognizer()
    if (!this.voiceRecognizer) {
      this.voiceRecognizer = this.shouldUseWhisper() ?
        new WhisperVoiceRecognizer() : 
        new SystemVoiceRecognizer(this.autocorrect)
    }
    return this.voiceRecognizer
  }

  getSafeAreaInsetTop = () => {
    const result =
          getComputedStyle(document.documentElement).getPropertyValue("--sat")
    return parseFloat(result.substring(0,
                                       result.length-2))
  }

  getSafeAreaInsetBottom = () => {
    const result =
          getComputedStyle(document.documentElement).getPropertyValue("--sab")
    return parseFloat(result.substring(0,
                                       result.length-2))
  }

  getSafeAreaInsetLeft = () => {
    const result =
          getComputedStyle(document.documentElement).getPropertyValue("--sal")
    return parseFloat(result.substring(0,
                                       result.length-2))
  }


  deleteAccount = async () => {
    const func = this.getFunc('deleteAccount')
    await func()
    this.signOut()
  }

  updatePhoneNumber = async (phoneNumber) => {
    await delay(5.0)
  }

  openAIStatusSubject = new Subject()

  checkOpenAIStatus = async () => {
    const url = 'https://status.openai.com/api/v2/status.json'
    const response = await axios.get(url)
    const { status } = response.data
    consoleLog("openAI status", status)
    if (status) {
      const { indicator, description } = status
      if (!this.openAIStatus ||
          this.openAIStatus.status != status ||
          this.openAIStatus.description != description) {
        this.openAIStatus = status
        this.openAIStatusSubject.next(status)
      }
    }
  }
  
  observeOpenAIStatus = () => {
    if (!this.statusInterval) {
      this.statusInterval = setInterval(this.checkOpenAIStatus, 30000)
    }
    this.checkOpenAIStatus()
    return this.openAIStatusSubject
  }

  checkForServerConnectivity = async () => {
    let result = false
    consoleLog("origin", window.location.origin)
    if (window.location.host.startsWith("localhost")) {
      result = Math.random () > 0.5 ? true : false
    } else {
      const file = window.location.origin + '/index.html'
      const  randomNum = Math.round(Math.random() * 10000)
      try {
        const response = await axios.head(file + "?rand=" + randomNum)
        consoleLog("head result", response.status)
        result = true
        const now = Date.now()
        if (this.lastServerReachableTime > 0 &&
            now - this.lastServerReachableTime > 60000) {
          //window.location.reload()
        }          
        this.lastServerReachableTime = now
      } catch (err) {
        consoleLog(err.message)
      }
    }
    this.serverReachable = result
    this.serverConnectivitySubject.next(result)
  }

  doCheckForServerConnectivity = async () => {
    this.serverReachable = result
    this.serverConnectivitySubject.next(result)
  }
  lastServerReachableTime = 0
  serverConnectivitySubject = new Subject()
  observeServerConnectivity = () => {
    if (true) {
      return of(true)
    }
    if (!this.serverConnectivityTimer) {
      let interval = 15000
      if (window.location.host.startsWith("localhost")) {
        interval = 3000
      }
      this.serverConnectivityTimer = setInterval(this.checkForServerConnectivity, interval)
      this.checkForServerConnectivity()
    }
    if (this.serverReachable !== undefined) {
      return concat(of(this.serverReachable), this.serverConnectivitySubject)
    }
    return this.serverConnectivitySubject
  }
  getFineTunedModelsRef = () => this.firebase.firestore().collection('FineTunedModels')
  getFineTuningJobsRef = () => this.firebase.firestore().collection('FineTuningJobs')
  getDataRef = () => this.firebase.firestore().collection('Data')
  getDatasetsRef = () => this.firebase.firestore().collection('Datasets')
  getMessagesRef = () => this.firebase.firestore().collection('UserChannel').doc(this.self.uid).collection('Messages')
  getThreadsRef = () => this.firebase.firestore().collection('UserChannel').doc(this.self.uid).collection('Threads')
  getTasksRef = () => this.firebase.firestore().collection('UserChannel').doc(this.self.uid).collection('Tasks')

  searchChatMessages = async (q, topic, model, task) => {
    if (!q && !topic || topic === 'new-thread') {
      return {
        results: [],
        page: 0,
        out_of: 0
      }
    }
    const func = await this.getFunc("searchChatMessages")
    const response = await func({q, topic, model, task})
    const { results } = response.data
    results.forEach(result => {
      if (Array.isArray(result.reaction)) {
        result.reaction = result.reaction.join('')
      }
    })
    return response.data
  }

  searchTasks = async q => {
    const func = await this.getFunc("searchTasks")
    const response = await func({q})
    return response.data
  }

  observeHallucinations = () => {
    return from([])
  }

  observeChatMessages = (model) => {
    return this.observeChatMessagesImpl({model, limit: 15})
  }

  projectsRef = () => this.firebase.firestore.collection("Projects")
  teamsRef = () => this.firebase.firestore.collection("Teams")
  teamMembersRef = () => this.firebase.firestore.collection("TeamMembership")


  createNewTeam = async (name) => {
    const c = this.teamsRef()
    const ref = c.doc()
    const updates = {
      name,
      owner: this.self.uid,
      lastUpdated: Date.now(),
    }
    await ref.set(updates)
  }

  modifyTeam = async (updates) => {
  }

  deleteTeam = async () => {
  }

  addTeamMember = async (team, member) => {
  }

  removeTeamMember = async (member) => {
  }

  modifyTeamMember = async (member) => {
  }

  observeTeamMembership = () => {
    const q = this.teamMembersRef().where("member", '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      console.log(changes.length)
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return { type, member: data }
      })
    }))
  }

  observeTeam = team => {
    return collectionData(this.teamsRef().doc(team))
  }

  observeTaskMessages = (opts) => {
    return this.observeChatMessagesImpl(opts)
  }

  observeChatMessagesImpl = (opts) => {
    const { task, limit } = opts
    let q = this.getMessagesRef()
    if (task) q = q.where('task', '==', task.id)
    q = q.orderBy('ts', 'desc')
    if (limit) {
      q = q.limit(limit)
    }
    let ob = collectionChanges(q).pipe(flatMap(changes => {
      //debugger
      console.log(changes.length)
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        console.log('message', data)
        if (Array.isArray(data.content)) {
          data.content = convertJsonToMarkdown(data.content)
        }
        return {
          type,
          message: data
        }
      })
    }))
    return ob
  }

  getThreadsHistory = async (earliest, limit) => {
    let q = this.getTasksRef()
    q = q.orderBy('lastUpdated', 'desc').where("lastUpdated", '<', earliest).limit(limit)
    const { docs } = await q.get()
    return docs.map(doc  => {
      const data = doc.data()
      data.id = doc.id
      return data
    })
  }
  
  observeChatThreads = (opts) => {
    const { model, task } = opts
    let q = this.getThreadsRef()
    if (task) {
      q = q.where('task', '==', task)
    }
    if (model) {
      q = q.where('model', '==', model)
    }
    q = q.orderBy('lastUpdated', 'desc').limit(50)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          thread: data
        }
      })
    }))
  }

  observeFunctions = () => {
    
  }

  observeRecentTasks = () => {
    let q = this.getTasksRef()
    q = q.orderBy('lastUpdated', 'desc').limit(15)
    return this.observeTasksImpl(q)
  }
  
  observeTasks = (ts) => {
    let q = this.getTasksRef()
    const start = startOfWeek(ts).getTime()
    const end = endOfWeek(ts).getTime()
    console.log("OBSERVE TASKS", {ts, start, end})
    q = q.where('lastUpdated', '>=', start).where('lastUpdated', '<=', end).orderBy('lastUpdated', 'desc')
    return this.observeTasksImpl(q)
  }

  observeTasksImpl = q => {
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          task: data,
        }
      })
    }))
  }
  
  
  observeSubthreads = (topicId) => {
    const q = this.getThreadsRef().where('subtopic', '==', topicId).orderBy('lastUpdated', 'desc').limit(50)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          thread: data
        }
      })
    }))
  }
  

  getHistory = async (task, earliest, limit) => {
    const taskId = task.id
    ////debugger
    const { ts } = earliest
    let q = this.getMessagesRef()
    q = q.where('task', '==', taskId)
    q = q.orderBy("ts", "desc").where("ts", "<", ts).limit(limit*2)
    const { docs } = await q.get()
    return docs.map(snap => {
      const data = snap.data()
      data.id = snap.id
      return data
    })
  }
  
  getToken = () => this.self ? this.self.getIdToken(false) : null

  shouldTraceChatGPT = () => {
    return window.location.hostname.startsWith('localhost')
  }

  getFunc = name => {
    return async (data) => {
      const token = await this.getToken()
      const url = `${server}/${name}`
      consoleLog('name', data)
      const response = await axios.post(url, data, {
        headers: {
          Authorization: 'Bearer '  + token
        }
      })
      consoleLog("result", name, response.data)
      return response
    }
  }

  deleteChatMessage = async id => {
    const func = this.getFunc('deleteChatMessage')
    const response = await func({id})
    return response.data
  }

  createNewMessage = ({text, contents, models, task, continueTaskInReplyTo}) => {
    const c = this.getMessagesRef()
    const ref = c.doc()
    if (!contents.find(x => x.type === 'image_url')) {
      contents = undefined
    }
    return {
      role: 'user',
      id: ref.id,
      content: text,
      contents,
      models,
      task,
      ts: Date.now(),
      utcOffset: this.utcOffset,
      continueTaskInReplyTo
    }
  }

  createNewTask = () => {
    const ref = this.getTasksRef().doc()
    const data = {
      messages: 0,
      description: "New Discussion",
      lastUpdated: Date.now()
    }
    ref.set(data)
    data.id = ref.id
    return data
  }

  judge = async (judges, req, reply) => {
    const func = this.getFunc('judge')
    await func({id: req.id, judges})
  }

  streamChat = async (msg, opts) => {
    const token = await this.getToken()
    const url = `${server}/streamChat`
    const dup = {}
    for (const field in msg) {
      dup[field] = msg[field]
    }
    dup.utcOffset = this.utcOffset
    dup.models = opts.model
    dup.temperature = opts.temperature
    return streamReply(url, dup, token, opts)
  }

  sendChat = async (msg) => {
    const func = this.getFunc('sendChat')
    const dup = clone(msg)
    dup.thread = dup.topic
    delete dup.topic
    dup.utcOffset = this.utcOffset
    const response = await func(dup)
    return response.data || {}
  }

  cancelStreamEdit = async id => {
    const db = this.firebase.firestore()
    const ref = db.collection('Edits').doc(id)
    const updates = {
      uid: this.self.uid,
      stopped: true,
      done: true
    }
    consoleLog("CANCEL STREAM EDIT", id, updates)
    await ref.set(updates, { merge: true })
  }

  streamEdit = async (instruction, input, buttonId, temperature, max_tokens, opts) => {
    const url = `${server}/streamEdit`
    const token = await this.getToken()
    return streamReply(url, {instruction, input, buttonId, temperature, max_tokens}, token, opts)
  }

  observeEdit = (instruction, input) => {
    if (!instruction) {
      throw new Error("instruction is required")
    }
    const db = this.firebase.firestore()
    const c = db.collection('Edits')
    const ref = c.doc()
    const updates = {
      ts: Date.now(),
      uid: this.self.uid,
      instruction,
      input: input || ''
    }
    return {
      cancel: () => this.cancelStreamEdit(ref.id),
      subscribe: observer => {
        let sub
        let output = ''
        const c1 = ref.collection('output').orderBy('ts', 'asc')
        const refs = []
        const deleteOutput = async () => {
          const batch = db.batch()
          refs.forEach(ref => batch.delete(ref))
          batch.delete(ref)
          await batch.commit()
        }
        ref.set(updates).then(async () => {
          consoleLog("STREAM EDIT", ref.id)
          const q = c1.where('id', '==', ref.id)
          sub = collectionChanges(q).pipe(catchError(err => {
            ////debugger
            throw "fun"
          })).subscribe(changes => {
            changes.map(change => {
              if (change.type == 'added') {
                refs.push(change.doc.ref)
                const data = change.doc.data()
                consoleLog("newText", data.newText)
                output += data.newText
                observer({
                  id: ref.id,
                  instruction,
                  input,
                  output,
                  done: data.isFinal
                })
              }
            })
          })
          await this.streamEdit(ref.id)
        })
        return {
          unsubscribe: () => {
            sub.unsubscribe()
            deleteOutput()
          }
        }
      }
    }
  }

  observeHallucinationQuestions = () => {
    if (true) return from([])
    const db = this.firebase.firestore()
    const q =  db.collection('HallucinationQuestions')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          question: data,
          type,
          isAdmin: data.isAdmin,
          non: data.non
        }
      })
    }),filter(data => {
      consoleLog("hallucination", data)
      if ((data.non || data.isAdmin) && !this.isAdmin) {
        return false
      }
      return true
    }))
  }

  deleteTask = async task => {
    const func = this.getFunc('deleteTask')
    await func({ taskId: task.id })
  }

  deleteDataset = async task => {
    const func = this.getFunc('deleteDataset')
    await func({ datasetId: task.id })
  }

  deleteFinetuneJob = async task => {
    const func = this.getFunc('deleteFineTuneJob')
    await func({ jobId: task.id })
  }

  continueTask = async (task, inReplyTo) => {
    const func = this.getFunc('continueTask')
    await func({ taskId: task.id, utcOffset: this.utcOffset, inReplyTo })
  }

  updateTaskSummary = async task => {
    const func = this.getFunc('updateTaskSummary')
    await func({ taskId: task.id, utcOffset: this.utcOffset })
  }

  loadData = async (dataset, ts, limit) => {
  }

  getDatasetHistory = async (task, earliest, limit) => {
    const dataset = task.id
    let q = this.getDataRef()
    debugger
    q = q.where('uid', '==', this.self.uid).where('dataset', '==', dataset).orderBy('lineNumber', 'desc').startAt(earliest.lineNumber).limit(limit)
    const { docs} = await q.get()
    return docs.flatMap(doc => this.convertData(doc))
  }

  convertData = doc => {
    const data = doc.data()
    const { messages, lineNumber } = data
    let inReplyTo
    let ts = lineNumber * 10000
    const result =  messages.flatMap((message, i)=> {
      message.id = doc.id + '-'+i
      message.ts = ts + i
      message.model = "Train"
      if (message.role === 'assistant') {
        message.inReplyTo = inReplyTo
      }
      inReplyTo = message.id
      message.datum = doc.id
      message.lineNumber = data.lineNumber
      return message
    })
    return result
  }

  observeData = (opts) => {
    const dataset = opts.task.id
    const limit = opts.limit
    let q = this.getDataRef()
    q = q.where('uid', '==', this.self.uid).where('dataset', '==', dataset).orderBy('lineNumber', 'desc').limit(limit)
    let ts = 0
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.flatMap(change => {
        const { type, doc } = change
        return this.convertData(doc).map(message => {
          return { message, type }
        })
      })
    }))
  }

  observeDatasets = () => {
    let q = this.getDatasetsRef()
    q = q.where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          dataset: data,
        }
      })
    }))
  }

  deleteDatasetMessage = async () => {
  }

  searchDatasets = async () => {
  }
  
  searchDatasetMessages = async () => {
  }

  streamDatasetChat = async () => {
  }

  createNewDataset = async () => {
  }


  observeFineTuneModels = () => {
    let q = this.getFineTunedModelsRef()
    q = q.where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          model: data,
        }
      })
    }))
  }

  observeFineTuningJobMessages = (opts) => {
    debugger
    const { task, limit } = opts
    const { job } = task
    const { datasets } = job
    return this.observeData({ task: { id: datasets[0]}, limit})
  }

  observeFineTuningJobs = () => {
    let q = this.getFineTuningJobsRef()
    q = q.where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          job: data,
        }
      })
    }))
  }

  deleteFineTuningJobMessage = async () => {
  }

  searchFineTuningJobs = async () => {
  }
  
  searchFineTuningJobMessages = async () => {
  }

  streamFineTuningJobChat = async () => {
  }

  createNewFineTuningJob = async () => {
  }

  streamFineTuningJobChat = async () => {
  }

  getStepMetrics = async () => {
    return { stepMetrics: [] }
  }

  observeUsage = ({year, month}) => {
    const { uid } = this.self
    const db = this.firebase.firestore()
    const c = db.collection('Usage')
    console.log("observeUsage", uid, year, month)
    let q = c.where('uid', '==', uid).where('year', '==', String(year)).where('month', '==', String(month))
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        console.log('usage', data)
        return {
          type,
          usage: data
        }
      })
    }), catchError(err => {
      console.error(err)
      debugger
    }))
  }

  observeModels = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Models')
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          model: data,
          type
        }
      })
    }))
  }

  observeVendors = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Vendors')
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          vendor: data,
          type
        }
      })
    }))
  }

  observePrices = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('CurrentPrices')
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          price: data,
          type
        }
      })
    }))
  }

  observeCredits = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('AccountDollars').doc(this.self.uid)
    return docData(ref).pipe(map(x => {
      return x || { purchased: 0, used: 0 }
    }, catchError (err => {
      debugger
      return { purchased: 0, used: 0 }
    })))
  }

  observePurchases = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Purchases')
    const q = ref.where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          purchase: data,
          type
        }
      })
    }))
  }
  
  
  observeWordPacks = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Products')
    const filt = change => {
      const { wordPack } = change
      if (wordPack.isDraft) {
        return this.isAdmin || this.isReview
      }
      return true
    }
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          wordPack: data,
          type
        }
      })
    }), filter(filt))
  }
  // stripe related

  createPaymentIntent = async productId => {
    const func = this.getFunc('createPaymentIntent')
    const result = await func({productId})
    return result.data
  }

  cancelPaymentIntent = async (id) => {
    const func = this.getFunc('cancelPaymentIntent')
    const result = await func({id})
    return result.data
  }

  signInWithGoogle = async noLink => {
    if (isDesktop()) {
      return this.signInWithGooglePopup(noLink)
    } else {
      return this.signInWithGoogleRedirect(noLink)
    }
  }

  signInWithGoogleRedirect = async (noLink) => {
    const firebase = this.firebase
    const  provider = new firebase.auth.GoogleAuthProvider()
    try {
      if (!noLink) {
        return  await this.self.linkWithRedirect(provider)
      } else {
        return await firebase.auth().signInWithRedirect(provider)
      }
    } catch (err) {
      if (err.code === 'auth/popup-closed-by-user') {
        return
      }
      if (!noLink && err.code === 'auth/credential-already-in-use') {
        return this.signInWithGoogleRedirect(true)
      }
      return { error: err }
    }
    return {}

  }

  signInWithGooglePopup = async (noLink) => {
    const firebase = this.firebase
    const  provider = new firebase.auth.GoogleAuthProvider()
    try {
      if (!noLink) {
        return  await this.self.linkWithPopup(provider)
      } else {
        return await firebase.auth().signInWithPopup(provider)
      }
    } catch (err) {
      if (err.code === 'auth/popup-closed-by-user') {
        return
      }
      if (!noLink && err.code === 'auth/credential-already-in-use') {
        return this.signInWithGoogle(true)
      }
      return { error: err }
    }
    return {}
  }

  observeOpenAIFineTunes = base => {
  }

  observeGeminFineTunes = base => {
    return from([])
  }

  observeFinetunes = base => {
    const { id, vendor } = base
    switch (vendor) {
      case "Google":
        return this.observeOpenGeminiFinetunes(base)
        break
      case "OpenAI":
        return this.observeOpenAIFinetunes(base)
        break
    }
  }
  
}



