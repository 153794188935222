import React, { Component } from 'react';
import {UIIcon} from "../Icon"
import {isMobile} from '../../Platform.js'
import './index.css'

export class UIToggleButton extends Component {
  constructor(props) {
    super(props);
  }
  onChange = e => {
    e.preventDefault();
    this.props.onChange();
  }
  render() {
    let className = this.props.className ? this.props.className + " uiToggleButton" : "uiToggleButton";
    if (this.props.selected) {
      className += " "+className+"Selected";
    }
    return <div className={className} onClick={this.onChange}>
      <div className={'uiToggleButtonLabel'}>{this.props.label}</div>
      <UIIcon icon={this.props.icon}/>
      </div>;
  }
}

export class UIButton extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let className = 'uiButton';
    let iconClassName = 'uiButtonIcon';
    let labelClassName = 'uiButtonLabel';
    if (this.props.className) {
      if (this.props.extend) {
        className += ' ' + this.props.className;
      } else {
        className = this.props.className;
        iconClassName = this.props.className + "Icon";
        labelClassName = this.props.className + "Label";
      }
    }
    if (this.props.selected) {
      className += " " + className+"Selected";
    }
    const  action = this.props.action
    let onClick
    let onMouseUp
    let onMouseDown
    if (isMobile()) {
       onClick = e => {
         const editing = this.props.editing ? this.props.editing() : !!document.activeElement 
         console.log("button on click", editing)
         if (!editing) {
           if (action) action(e)
         }
       }
      onMouseDown = e => {
        const editing = this.props.editing ? this.props.editing() : !!document.activeElement 
        console.log("button mouse down", editing)
        if (editing) {
          e.preventDefault()
          if (action) action(e)
        }
      }
    } else {
      onClick = e => {
        if (action) action(e)
      }
    }
    const button = <div className={className}
    onClick={onClick} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
          <UIIcon className={iconClassName} icon={this.props.icon}/>
          <div className={labelClassName}>{this.props.label}</div>
          </div>;
    return button;
  }
}
