import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { BnSubpage } from '../Page'
import { Keyboard } from '../Keyboard'
import { BnLabel1, BnLabel2 } from '../Label'
import { BnButton, BnBackButton } from '../Button'
import IOSSettings from '../../assets/Icons/iOSSettings.png'
import IOSKeyboards from '../../assets/Icons/iOSKeyboards.png'
import IOSGeneralSettings from '../../assets/Icons/iOSGeneralSettings.png'
import IntelliKeyLogo2D from '../../assets/Icons/AppIcon.png'

import Enable from '../../assets/Icons/Enable.png'
import Done from '../../assets/Icons/Done.svg'
import './index.css'
import { GetStarted } from '../KeyboardLogin'

const privacyBlurb = <p>*About Full Access<p/> It is important to note that legitimate third party keyboards require Full Access in order to access their servers.<p/> Keep in mind that apps that handle sensitive data, such as a banking app, do not use third party keyboards. <p/>Additionally, password fields are not accessible to third party keyboards.</p>



const KeyboardStep = props => {
  return <div className='keyboardSettingsStep'>
           <div className='keyboardSettingsStepNumber'>
             {props.stepNumber}
           </div>
           <div className={'keyboardSettingsStepIcon' + (props.className ? ' ' + props.className : '')}>
             { props.icon && <ReactSVG src={props.icon}/>}
             { props.image && <img src={props.image}/>}
           </div>
           <div className='keyboardSettingsStepText'>
             {props.text}
           </div>
           {props.subtext &&
           <div className='keyboardSettingsStepSubtext'>
             {props.subtext}
           </div>}
         </div>
}

const steps = [
  {
    image:IOSSettings,
    text: <span className='settingStepText'>Go to&nbsp;<span className='settingsStepBold'>Settings</span></span>
  },
  {
    image:IntelliKeyLogo2D,
    className: 'appIconImage',
    text: <span className='settingStepText'>Go to&nbsp;<span className='settingsStepBold'>IntelliKey</span></span>
  },
  {
    image:IOSKeyboards,
    text: <span className='settingStepText'>Open&nbsp;<span className='settingsStepBold'>Keyboards</span></span>
  },
  {
    image: Enable,
    text: <span className='settingStepText'>Enable&nbsp;<span className='settingsStepBold'>Keyboard</span></span>
  },
  {
    image: Enable,
    text: <span className='settingStepText'>Enable&nbsp;<span className='settingsStepBold'>Allow Full Accesss</span></span>,
  }
  ]

export class KeyboardSettings extends BnSubpage {

  takeMeThere = () => {
    this.props.me.openKeyboardSettings()    
  }
  renderContent() {
    console.log(this.props)
    const done = this.props.done
    if (done) {
      return <GetStarted className='signInCreateAccount' me={this.props.me} text='Enable IntelliKey Keyboard' icon={Done} label={'Done'} action={this.props.doneClicked}/>
    }
    let title = this.props.done ? "Keyboard is Enabled" : "Enable IntelliKey Keyboard"
    const filteredSteps = steps.filter(x => {
      return this.props.isInstalled ? x.type !== 'installation' : true
    })
    return <div className={'keyboardSettingsContent'  + (done ? ' keyboardSettingsDone' : '')}>
             <div className={'keyboardSettingsTitle'}>
               <BnLabel1 text={title}/>
             </div>
             <div className='keyboardSettingStepsContainer'>
               <div className='keyboardSettingSteps'>
                 {
                   filteredSteps.map((step, i) => {
                     let { text, subtext, className } = step
                     if (i == 1) {
                       text = <div className='keyboardSettingStep2'>
                                {text} <div className='keyboardSettingStep2TakeMeThere' onClick={this.takeMeThere}>Take me there</div>
                              </div>
                     }
                     return <KeyboardStep
                              stepNumber={i + 1}
                              icon={step.icon}
                              image={step.image}
                              text={text}
                              subtext={subtext}
                              className={className}
                            />
                     
                   })
                 }
               </div>
             </div>
             <div className='keyboardSettingsButtonSpacer'/>
             {<BnButton label={'Done'} action={this.props.doneClicked}/>}
             {this.props.isEnabled && !this.props.hasFullAccess &&
              <div className='privacyBlurb' onClick>
                {(true ||this.state.privacyBlurbExpanded) ? privacyBlurb : "About Third-Party Keyboards & Privacy..."}
              </div>}
           </div>
  }
}

