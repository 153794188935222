export const parseTable = (text) => {
  const lines = text.split('\n')
  const elements = []
  let rows = []
  let sawTable = false
  let block = []
  for (const line of lines ) {
    if (!line.startsWith('|') || !line.endsWith('|')) {
      if (sawTable) {
        elements.push({type: 'table', table: rows})
        rows = []
      }
      sawTable = false
      block.push(line)
    } else {
      if (!sawTable) {
        if (block.length) {
          elements.push({type: 'block', block })
          block = []
        }
      }
      sawTable = true
      const columns = line.substring(1, line.length-1).split('|')
      rows.push(columns)
    }
  }
  if (sawTable) {
    elements.push({type: 'table', table: rows})
  }
  if (elements.length === 0) {
    return null
  }
  return {
    elements
  }
}

export const renderTableMarkdown = table => {
  return table.elements.map(element => {
    if (element.type == 'table') {
      const rows = element.table
      return rows.map((column, i) => '|' + column + '|').join('\n')
    } else {
      const block = element.block
      return block.join('\n')
    }
  })
}

export const renderTable = (table, onTableChanged, onCommit) => {
  const renderCell = (cell, onChange) => {
    if (!onTableChanged) {
      return <div className ='tableColumnFunCell'>{cell}</div>
    }
    return <input value={cell} onBlur={onCommit} onChange={onChange}/>
  }
  return table.elements.map(element => {
    if (element.type == 'table') {
      const rows = element.table
      const header = rows[0]
      return <div className='tableFunContainer'>
        <div className='tableFun'>
        <div className='tableHeaderFun'>
        {
          header.map((cell, i) => {
            const onChange = e => {
              header[i] = e.target.value
              onTableChanged()
            } 
            return <div className='tableColumnFun'>{renderCell(cell, onChange)}</div>
          })
        }
      </div>
        {
          rows.slice(2).map(row => {
            return <div className='tableRowFun'>
              {
                row.map((column, i) => {
                  const onChange = e => {
                    row[i] = e.target.value
                    onTableChanged()
                  }
                  return <div className='tableColumnFun'>{renderCell(column, onChange)}</div>
                })
              }
            </div>
          })
        }
      </div>
        </div>
    } else {
      const block = element.block
      return block.map(line => {
        return <div className='line'>{line}</div>
      })
    }
  })
}


       
